import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-car',
  templateUrl: './profile-car.component.html',
  styleUrls: ['./profile-car.component.scss']
})
export class ProfileCarComponent implements OnInit {
  @Input() form: FormGroup;
  public submitted = false;
  @Output() updateEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.updateEmitter.emit();
  }
}
