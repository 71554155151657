import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-calendar',
  templateUrl: './dialog-calendar.component.html',
  styleUrls: ['./dialog-calendar.component.scss']
})
export class DialogCalendarComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() isReturnTrip: boolean;
  @Output() submitEmitter = new EventEmitter<Date[]>();
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() closeDialogEmitter = new EventEmitter<undefined>();

  public show = false;
  public subscription: Subscription;

  public chosenDates: Date[] = [];
  public today = new Date();
  public month: Date[] = [];
  public selectedDate: Date;
  public lastMonthDays: Date[] = [];
  public showTimePicker = false;
  public isEditDate = false;
  public maxAmountOfDate = 60;
  public invalidDate = false;

  constructor() {}

  ngOnInit(): void {
    if(this.isReturnTrip && this.form.controls.return_customized_trips.value){
      for(let date of this.form.controls.return_customized_trips.value){
        let d = new Date(date.getTime());
        d.setHours(date.getHours());
        d.setMinutes(date.getMinutes());
        this.chosenDates.push(d);
      }
    } else if(!this.isReturnTrip && this.form.controls.customized_trips.value) {
      for(let date of this.form.controls.customized_trips.value){
        let d = new Date(date.getTime());
        d.setHours(date.getHours());
        d.setMinutes(date.getMinutes());
        this.chosenDates.push(d);
      }
    }

    // if(!this.isReturnTrip && this.form.controls.customized_trips.value) this.chosenDates = this.form.controls.customized_trips.value;
    // else if(this.isReturnTrip && this.form.controls.return_customized_trips.value) this.chosenDates = this.form.controls.return_customized_trips.value;
    this.month = this.getMonthDays(this.isReturnTrip ? new Date(this.form.controls.return_time.value.getTime()) : new Date(this.form.controls.time.value.getTime()));
  }

  ngOnDestroy(): void {
    if(this.subscription) this.subscription.unsubscribe();
  }

  getMonthDays(day: Date) : Date[] {
    let days: Date[] = [];
    let year = day.getFullYear();
    let month = day.getMonth();
    let lastDayOfMonth = new Date(year, month + 1, 0);
    let i = 1;
    while(i <= lastDayOfMonth.getDate()) {
      days.push(new Date(year, month, i));
      i++;
    }
    let lastMonthDays: Date[] = [];
    let emptyBoxes = days[0].getDay() == 0 ? 7 : days[0].getDay();
    for(let i = 1; i < emptyBoxes; i++){
      let dateOffset = (24*60*60*1000) * i;
      let day = new Date().setTime(days[0].getTime() - dateOffset);
      lastMonthDays.push(new Date(day));
    }
    this.lastMonthDays = lastMonthDays;
    return days;
  }


  // getMonthDays(day: Date) : Date[] {
  //   let days: Date[] = [];
  //   let year = day.getFullYear();
  //   let month = day.getMonth();
  //   let lastDayOfMonth = new Date(year, month + 1, 0);
  //   let i = 1;
  //   while(i <= lastDayOfMonth.getDate()) {
  //     days.push(new Date(year, month, i));
  //     i++;
  //   }
  //   return days;
  // }

  isInThePast(day: Date): boolean {
    let check = this.isReturnTrip ? new Date(this.form.controls.return_time.value.getTime()) : new Date(this.form.controls.time.value.getTime());
    check.setHours(0,0,0,0);
    day.setHours(0,0,0,0);
    if(day < check) return true;
    else return false;
  }

  isDayToday(day: Date): boolean {
    if(this.isEqualDate(day, this.today)) return true;
    else return false;
  }

  nextMonth(day: Date) {
    this.month = this.getMonthDays(new Date(day.getFullYear(), day.getMonth() + 1));
  }

  previousMonth(day: Date) {
    this.month = this.getMonthDays(new Date(day.getFullYear(), day.getMonth() - 1));
  }

  selectDateAction(day: Date) {
    if(this.chosenDates.length != 0){
      for(let item of this.chosenDates){
        if(this.isEqualDate(day, item)){
          this.selectedDate = item;
          this.isEditDate = true;
          break;
        }
      }
    }
    if(!this.selectedDate){
      this.selectedDate = day;
      this.isEditDate = false;
    }
    this.showTimePicker = true;
  }

  setTime(time: string) {
    if(this.selectedDate){
      if(this.isEditDate){
        for(let i = 0; i < this.chosenDates.length; i++){
          if(this.isEqualDate(this.selectedDate, this.chosenDates[i])){
            let temp: Date;
            temp = new Date(this.chosenDates[i].getTime());
            temp.setHours(Number(time.split(':')[0]));
            temp.setMinutes(Number(time.split(':')[1]));
            this.chosenDates[i] = temp;
            break;
          }
        }
      } else {
        let temp: Date;
        temp = new Date(this.selectedDate.getTime());
        temp.setHours(Number(time.split(':')[0]));
        temp.setMinutes(Number(time.split(':')[1]));
        this.chosenDates.push(temp);
      }
    }
    this.showTimePicker = false;
    this.selectedDate = null;
  }

  isSelectedDay(day: Date):boolean {
    if(this.chosenDates.length != 0){
      for(let item of this.chosenDates){
        if(this.isEqualDate(day, item)) return true;
      }
    }
    return false;
  }

  getTime(day): Date {
    if(this.chosenDates.length != 0){
      for(let item of this.chosenDates){
        if(this.isEqualDate(day, item)){
          return item;
        }
      }
    }
    return null;
  }

  removeDate() {
    for(let i = 0; i < this.chosenDates.length; i++){
      if(this.isEqualDate(this.selectedDate, this.chosenDates[i])){
        this.chosenDates.splice(i,1);
      }
    }
    this.isEditDate = false;
    this.showTimePicker = false;
  }

  isEqualDate(date_1: Date, date_2: Date): boolean {
    if(date_1.getDate() == date_2.getDate() &&
      date_1.getMonth() == date_2.getMonth() &&
      date_1.getFullYear() == date_2.getFullYear()){
      return true;
    } else {
      false;
    }
  }

  closeTimeDialog() {
    this.selectedDate = null;
    this.showTimePicker = false;
  }

  isNotTodaysMonth(day: Date): boolean {
    if(day.getFullYear() == this.today.getFullYear() && day.getMonth() == this.today.getMonth()){
      return false;
    }
    return true;
  }

  isValidTime(): boolean {
    if(this.chosenDates.length != 0){
      for(let date of this.chosenDates){
        if(this.isEqualDate(date, this.today) || this.isEqualDate(date, this.form.controls.time.value)){
          if((date.getMinutes() < this.form.controls.time.value.getMinutes() &&
              date.getHours() <= this.form.controls.time.value.getHours()) ||
              date.getHours() < this.form.controls.time.value.getHours())
          {
            return false;
          } else {
            return true;
          }
        }
      }
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    let isValid = false;
    if(this.chosenDates.length != 0){
      for(let date of this.chosenDates){
        if(this.isEqualDate(date, this.today)){
          if((date.getMinutes() < this.form.controls.time.value.getMinutes() &&
              date.getHours() <= this.form.controls.time.value.getHours()) ||
              date.getHours() < this.form.controls.time.value.getHours())
          {
            this.invalidDate = true;
            isValid = false;
            break;
          } else {
            this.invalidDate = false;
            isValid = true;
          }
        } else {
          this.invalidDate = false;
          isValid = true;
        }
      }
      if(isValid) this.submitEmitter.emit(this.chosenDates);
    }
  }
}
