<div class="view_body">
  <label class="lbl_medium_24 lbl_active">Wie weit bist du bereit, von deiner Strecke abzuweichen?</label>
  <form [formGroup]="form" class="form_profile">
    <img class="icon" src="../../../../assets/umwege.svg">
    <label class="lbl_medium_18 lbl_active lbl_info">Umweg in km</label>
    <div class="inputfield_container" [ngClass]="submitted && form.controls.allowed_detour.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="number" min="0" step="1" placeholder="Umweg in km"
        formControlName="allowed_detour">
      <div class="input_valid_indicator" [ngClass]="form.controls.allowed_detour.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <button class="btn_submit" [ngClass]="form.controls.allowed_detour.invalid? 'btn_submit_inactive' : ''" [disabled]="form.controls.allowed_detour.invalid" (click)="save()">Speichern</button>
  </form>
</div>
