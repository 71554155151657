import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private notification = new Subject<any>();
  notification$ = this.notification.asObservable();

  private openDialogQuery = new Subject<any>();
  openDialogQuery$ = this.openDialogQuery.asObservable();
  private closeDialogQuery = new Subject<any>();
  closeDialogQuery$ = this.closeDialogQuery.asObservable();

  private openDialogSuccess = new Subject<any>();
  openDialogSuccess$ = this.openDialogSuccess.asObservable();
  private closeDialogSuccess = new Subject<any>();
  closeDialogSuccess$ = this.closeDialogSuccess.asObservable();

  private openDialogInfo = new Subject<any>();
  openDialogInfo$ = this.openDialogInfo.asObservable();
  private closeDialogInfo = new Subject<any>();
  closeDialogInfo$ = this.closeDialogInfo.asObservable();

  private openDialogCalendar = new Subject<any>();
  openDialogCalendar$ = this.openDialogCalendar.asObservable();
  private closeDialogCalender = new Subject<any>();
  closeDialogCalender$ = this.closeDialogCalender.asObservable();

  private openDialogInputCalendar = new Subject<any>();
  openDialogInputCalendar$ = this.openDialogInputCalendar.asObservable();
  private closeDialogInputCalender = new Subject<any>();
  closeDialogInputCalender$ = this.closeDialogInputCalender.asObservable();


  constructor() { }

  showNotification(data: any) {
    this.notification.next(data);
  }

  openQuery(data: any) {
    this.openDialogQuery.next(data);
  }
  closeQuery(data: any) {
    this.closeDialogQuery.next(data);
  }

  openSuccess(data: any) {
    this.openDialogSuccess.next(data);
  }
  closeSuccess(data: any) {
    this.closeDialogSuccess.next(data);
  }

  openInformation(data: any) {
    this.openDialogInfo.next(data);
  }
  closeInformation(data: any) {
    this.closeDialogInfo.next(data);
  }

  openInputCalendar(data: any) {
    this.openDialogInputCalendar.next(data);
  }
  closeInputCalendar(data: any) {
    this.closeDialogInputCalender.next(data);
  }

  openCalendar(data: any) {
    this.openDialogCalendar.next(data);
  }
  closeCalendar(data: any) {
    this.closeDialogCalender.next(data);
  }
}
