<!-- <div class="view_body">
  <div class="chat_header">
    <Label class="lbl_regular_22">{{user.first_name}}</Label>
    <Label class="lbl_reular_18 lbl_active">{{user.allow_phonenumber ? user.phonenumber : ''}}</Label>
  </div>
  <div class="chat_wrapper">
    <div id="messages_list">
      <div class="message_wrapper" [ngClass]="message.user_id != currentUser?.user_id ? 'message_wrapper_recieve' : 'message_wrapper_send'" *ngFor="let message of conversation?.messages" [attr.id]="'message_'+message.message_id">
        <Label class="lbl_regular_12 lbl_light">{{message.timestamp | date: 'HH:mm'}}</Label>
        <Label class="lbl_regular_14 lbl_white message" [ngClass]="message.user_id != currentUser?.user_id ? 'message_recieve' : 'message_send'">{{message.content}}</Label>
      </div>
    </div>
    <form [formGroup]="form" class="answer_container">
      <div class="inputfield_container">
        <input class="inputfield" type="text" placeholder="Schreibe deine Nachricht..." formControlName="message" (focus)="submitted = false">
      </div>
      <div class="input_icon_container" (click)="sendMessageEmitter.emit()">
        <img class="input_icon" src="../../../../../assets/contactgrey.svg">
      </div>
    </form>
  </div>
</div> -->


<div class="chat_wrapper">
  <div id="messages_list">
    <div class="message_wrapper" [ngClass]="message.user_id != currentUser?.user_id ? 'message_wrapper_recieve' : 'message_wrapper_send'" *ngFor="let message of conversation?.messages" [attr.id]="'message_'+message.message_id">
      <Label class="lbl_regular_12 lbl_light">{{message.timestamp | date: 'HH:mm'}}</Label>
      <Label class="lbl_regular_14 lbl_white message" [ngClass]="message.user_id != currentUser?.user_id ? 'message_recieve' : 'message_send'">{{message.content}}</Label>
    </div>
  </div>
  <form [formGroup]="form" class="answer_container">
    <div class="inputfield_container">
      <input class="inputfield" type="text" placeholder="Schreibe deine Nachricht..." formControlName="message" (focus)="submitted = false">
    </div>
    <div class="input_icon_container" (click)="sendMessageEmitter.emit()">
      <img class="input_icon" src="../../../../../assets/contactgrey.svg">
    </div>
  </form>
</div>
