<form class="page_body" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <label class="lbl_medium_22 lbl_active">Wähle deine Strecke aus</label>
  <div class="grid_map">
    <div class="column">
      <div class="map_wrapper">
        <div id="map"></div>
      </div>
    </div>
    <div class="column_2" *ngIf="routesResult">
      <div class="checkbox_container" *ngFor="let route of routesResult.routes; let i = index">
        <div class="checkbox_lbl_container">
          <label class="lbl_medium_18 lbl_active">{{getTime(route)}}</label>
          <label class="lbl_regular_14">{{getDistance(route)}} km</label>
        </div>
        <div class="checkbox" [ngClass]="selectedRouteIndex == i ? 'checkbox_checked' : ''" (click)="selectRoute(i)">
          <img *ngIf="selectedRouteIndex == i" [atrr]="i" src="../../../../assets/successhue.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="btn_container">
    <button class="btn_submit" type="button" [ngClass]="!checkValid() ? 'btn_submit_inactive' : ''" [disabled]="!checkValid()" (click)="nexPage()">Weiter</button>
  </div>
</form>
