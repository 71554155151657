import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Conversation, Message } from 'src/app/buisness-object/conversation/Conversation';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Input() user: User;
  @Input() trip: Trip;
  @Input() showChatPhone: boolean;
  @Output() openChatEmitter = new EventEmitter<undefined>();
  public conversation: Conversation;
  public currentUser: User;

  public form: FormGroup;
  public submitted = false;
  public isTablet = GlobalVariables.is_tablet_device;
  public isPhone = GlobalVariables.is_phone_device;
  public showChat = false;

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    this.sendMessage();
  }

  constructor(
    private uService: UserService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
    this.form = this.formBuilder.group({
      message: [null,[Validators.required]]
    })
  }

  getDataServerside() {
    forkJoin([
      this.uService.getAvatar(this.user.user_id),
      this.uService.getUser(Number(localStorage.getItem('id')))
    ]).subscribe(([avatar, user]:[any, any]) => {
      if(avatar && user){
        this.user.avatar = avatar.data;
        this.currentUser = user.user;
        this.getConversation(user.conversations);
      }
    })
  }

  getConversation(conversations: Conversation[]) {
    for(let con of conversations){
      if(con.trip_id == this.trip.trip_id){
        this.uService.getConversation(con.conversation_id).subscribe((result) => {
          if(result){
            this.conversation = result;
            setTimeout(() => {
              this.scrollToNewMessage();
            }, 80);
          }
        });
        break;
      }
    }
  }

  getArray(i: number): any {
    return new Array(i);
  }

  getRankingLabel(): string {
    if(this.user.company_id == 1) return 'Magna';
    else return 'App';
  }

  sendMessage() {
    this.submitted = true;
    if(this.form.invalid) return;
    if(!this.conversation)this.createConversation();
    else this.createMessage();

  }

  createConversation() {
    let message = new Message(
      null,
      null,
      this.currentUser.user_id,
      this.form.controls.message.value,
      new Date().getTime()
    );
    let conversation = new Conversation(
      null,
      this.trip.trip_id,
      this.currentUser.user_id,
      this.user.user_id,
      [message]
    );
    this.uService.createConversation(conversation).subscribe((result) => {
      if(result){
        this.submitted = false;
        this.conversation = result;
        this.form.controls.message.setValue(null);
      }
    })
  }

  createMessage() {
    let message = new Message(
      null,
      this.conversation.conversation_id,
      this.currentUser.user_id,
      this.form.controls.message.value,
      new Date().getTime()
    );
    this.uService.createMessage(message).subscribe((result) => {
      if(result){
        this.submitted = false;
        this.conversation.messages.push(result);
        this.form.controls.message.setValue(null);
        setTimeout(() => {
          this.scrollToNewMessage()
        }, 80);
      }
    })
  }

  scrollToNewMessage() {
    const list = document.getElementById('messages_list');
    if(list) list.scrollTop = list.scrollHeight;
  }

  openChat() {
    this.openChatEmitter.emit();
  }

  openCall(phonenummber: string) {
    window.open('tel:'+phonenummber+'');
  }
}
