import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import drives from '../../../mock-generated-trips.json';
import { TripFactory } from 'src/app/buisness-object/trip/factory/TripFactory';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { CreateTrip } from 'src/app/buisness-object/trip/CreateTrip';
import { CreateTripFactory } from 'src/app/buisness-object/trip/factory/CreateTripFactory';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  createTrip(trip: CreateTrip): Observable<Trip[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let subTrips: any[] = [];
    trip.sub_trips.forEach(item => {
      subTrips.push({
        "sid" : item.sid,
        "polyline" : item.polyline,
        "distance" : item.distance,
        "duration": item.duration,
        "start_description" : item.start_description,
        "start_latitude": item.start_latitude,
        "start_longitude": item.start_longitude,
        "target_description" : item.target_description,
        "target_latitude": item.target_latitude,
        "target_longitude": item.target_longitude,
      })
    });
    let trips: any[] = [];
    trip.trips.forEach(item => {
      trips.push({
        "sid": item.sid,
        "timestamps": item.timestamps
      })
    })
    const observable = this.http.post(environment.api + '/app/trip',
    {
      "trips": {
          "user_id": trip.user_id,
          "passenger_capacity": trip.passenger_capacity,
          "accept_detours": trip.accept_detours,
          "passenger_fee": trip.passenger_fee,
          "sub_trips": subTrips,
          "trips": trips
      }
    }, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        const data = TripFactory.jsonFactory(rawResult.trips);
        return data;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  updateTrip(trip: Trip): Observable<Trip> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/app/trip/update',
    {
        "trip": {
          "trip_id": trip.trip_id,
          "user_id": Number(localStorage.getItem('id')),
          "polyline": trip.polyline,
          "timestamp": trip.timestamp,
          "start_description": trip.start_description,
          "start_latitude": trip.start_latitude,
          "start_longitude": trip.start_longitude,
          "target_description": trip.target_description,
          "target_latitude": trip.target_latitude,
          "target_longitude": trip.target_longitude,
          "distance": trip.distance,
          "duration": trip.duration,
          "accept_detours": trip.accept_detours,
          "passenger_capacity": trip.passenger_capacity,
          "passenger_fee": trip.passenger_fee
        }
    }, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        const data = TripFactory.jsonFactoryOne(rawResult.trip);
        return data;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  joinTrip(trip_id: number, start?: string, target?: string): Observable<Trip> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/app/trip/' + trip_id + '/join',
    {
      start_description: start,
      target_description: target,
    }, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        let data = TripFactory.jsonFactoryOne(rawResult.trip)
        return data;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        } else {
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public searchTrips(
    start_lat: number,
    start_lng: number,
    target_lat: number,
    target_lng: number,
    timestamp: number,
    start_description: number,
    target_description: number
  ): Observable<Trip[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/app/trip/search?'+
    'start_latitude='+start_lat+
    '&start_longitude='+start_lng+
    '&target_latitude='+target_lat+
    '&target_longitude='+target_lng+
    '&timestamp='+timestamp+
    '&start_description='+start_description+
    '&target_description='+target_description
    , { headers });
    return observable.pipe(
      map((rawResult: any) => {
        let data = TripFactory.jsonFactory(rawResult.trips)
        return data;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  deleteTrip(trip_id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/app/trip/' + trip_id, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        return true;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }


  cancelTripAsPassenger(trip_id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/app/trip/' + trip_id + '/cancel', {}, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        return true;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
}
