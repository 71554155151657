<div id="sidebar">
  <img class="logo_menu" src="../../../assets/logo-small.svg">
  <div class="item_container">
    <div class="item" [ngClass]="selectedMenu === 1 ? 'item_active' : ''" (click)="reload('/dashboard')">
      <img class="item_icon" [src]="getIcon(1)">
      <label class="lb_regular_menu" [ngClass]="selectedMenu === 1 ? 'lbl_active' : 'lbl_light'">Übersicht</label>
    </div>
    <div class="item" [ngClass]="selectedMenu === 2 ? 'item_active' : ''" (click)="reload('/create-drive')">
      <img class="item_icon" [src]="getIcon(2)">
      <label class="lb_regular_menu" [ngClass]="selectedMenu === 2 ? 'lbl_active' : 'lbl_light'">Fahrt erstellen</label>
    </div>
    <div class="item" [ngClass]="selectedMenu === 3 ? 'item_active' : ''" (click)="reload('/my-drives')">
      <img class="item_icon" [src]="getIcon(3)">
      <label class="lb_regular_menu" [ngClass]="selectedMenu === 3 ? 'lbl_active' : 'lbl_light'">Meine Fahrten</label>
      <div *ngIf="getNotify() != 0" id="menu_notify">{{getNotify()}}</div>
    </div>
    <div class="item" [ngClass]="selectedMenu === 4 ? 'item_active' : ''" (click)="reload('/profile')">
      <img class="item_icon" [src]="getIcon(4)">
      <label class="lb_regular_menu" [ngClass]="selectedMenu === 4 ? 'lbl_active' : 'lbl_light'">Profil</label>
    </div>
  </div>
</div>
