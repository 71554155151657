import { User } from "../user/User";

export class Trip {
  public requestPending: boolean;
  public detour_request_start: string;
  public detour_request_target: string;
  constructor(
    public trip_id: number,
    public polyline: string,
    public timestamp: number,
    public start_description: string,
    public start_latitude: number,
    public start_longitude: number,
    public target_description: string,
    public target_latitude: number,
    public target_longitude: number,
    public distance: number,
    public duration: number,
    public accept_detours: boolean,
    public passenger_capacity: number,
    public passenger_fee: number,
    public passengers: User[],
    public driver?: User,
    public co2_footprint?: number,
    public detour_start_description?: string,
    public detour_target_description?: string,
    public status?: boolean
  ){}

  getCo2InKg(): number {
    return this.co2_footprint / 1000;
  }

  getVehicle(): string {
    if(this.driver.vehicle.vehicle_brand_and_model && this.driver.vehicle.vehicle_color){
      return this.driver.vehicle.vehicle_brand_and_model + ', ' + this.driver.vehicle.vehicle_color;
    } else if(this.driver.vehicle.vehicle_brand_and_model && !this.driver.vehicle.vehicle_color){
      return this.driver.vehicle.vehicle_brand_and_model;
    } else if(!this.driver.vehicle.vehicle_brand_and_model && this.driver.vehicle.vehicle_color){
      return this.driver.vehicle.vehicle_color;
    } else {
      return 'Keine Angaben';
    }
  }
}


