import { VehicleFactory } from "../../vehicle/factory/VehicleFactory";
import { User } from "../User";

export class UserFactory {
  static jsonFactory(rawData: any): User[] {
    let datas: User[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): User {
      return new User(
          rawData.user_id,
          rawData.first_name,
          rawData.last_name,
          rawData.birthdate,
          rawData.email,
          rawData.is_company,
          rawData.dataprivacy_accepted,
          rawData.allow_phonenumber,
          rawData.allowed_detour,
          rawData.company_id,
          rawData.eco_points,
          rawData.email_enabled,
          rawData.footprint,
          rawData.phonenumber,
          rawData.push_enabled,
          rawData.ranking,
          rawData.rating,
          rawData.rating_count,
          rawData.registered_since,
          VehicleFactory.jsonFactoryOne(rawData.vehicle),
          null,
          null,
          null,
          rawData.amount_of_trips,
          rawData.conversation_id,
          rawData.total_value
      );
  }

  static jsonFactoryWithStatus(rawData: any): User[] {
    let datas: User[] = [];
    for(let data of rawData){
      datas.push(this.jsonFactoryOneWithStatus(data));
    }
    return datas;
  }

  static jsonFactoryOneWithStatus(rawData: any): User {
      return new User(
          rawData.user.user_id,
          rawData.user.first_name,
          rawData.user.last_name,
          rawData.user.birthdate,
          rawData.user.email,
          rawData.user.is_company,
          rawData.user.dataprivacy_accepted,
          rawData.user.allow_phonenumber,
          rawData.user.allowed_detour,
          rawData.user.company_id,
          rawData.user.eco_points,
          rawData.user.email_enabled,
          rawData.user.footprint,
          rawData.user.phonenumber,
          rawData.user.push_enabled,
          rawData.user.ranking,
          rawData.user.rating,
          rawData.user.rating_count,
          rawData.user.registered_since,
          VehicleFactory.jsonFactoryOne(rawData.user.vehicle),
          rawData.status,
          rawData.start_description,
          rawData.target_description,
          rawData.amount_of_trips
      );
  }
}
