import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Conversation, Message } from 'src/app/buisness-object/conversation/Conversation';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  @Input() user: User;
  @Input() trip: Trip;
  @Input() form: FormGroup;
  @Input() conversation: Conversation;
  @Input() currentUser: User;
  @Output() sendMessageEmitter = new EventEmitter<undefined>();
  public submitted = false;

  constructor() { }

  ngOnInit(): void {
  }
}
