<div id="dialog_window" *ngIf="show">
  <div class="close_icon" *ngIf="showClose == true" (click)="show = false">&#10005;</div>
  <div id="dialog_container">
    <label class="lbl_medium_22 lbl_active">{{title}}</label>
    <label class="lbl_regular_14">{{message}}</label>
    <div class="btn_wrapper">
      <button class="btn_gray lbl_black_16" (click)="isChoise ? onSubmitChoise() : show=false">{{btnCancelTxt}}</button>
      <button class="btn_submit lbl_black_16" (click)="onSubmit()">{{btnSubmitTxt}}</button>
    </div>
  </div>
</div>
