<div class="view_body">
  <form [formGroup]="form" class="form_profile">
    <img class="icon" src="../../../../assets/editmycar.svg">
    <div class="inputfield_container">
      <input class="inputfield" type="text" placeholder="Marke, Model"
        formControlName="vehicle_brand_and_model">
      <div class="input_valid_indicator" [ngClass]="form.controls.vehicle_brand_and_model.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <div class="inputfield_container">
      <input class="inputfield" type="text" placeholder="Farbe"
        formControlName="vehicle_color">
      <div class="input_valid_indicator" [ngClass]="form.controls.vehicle_color.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <button class="btn_submit" (click)="save()">Speichern</button>
  </form>
</div>
