<div id="dialog_window">
  <div class="close_icon" (click)="closeDialog()">&#10005;</div>
  <form id="dialog_container">
    <label class="lbl_medium_22 lbl_active title">Mehrmals anbieten</label>
    <div class="body">
      <div class="checkbox_container" style="width: 100%;">
        <label class="lbl_regular_16">Einmalig</label>
        <div class="checkbox"
          [ngClass]="selectedTyp == 1 ? 'checkbox_checked' : ''"
          (click)="selectedTyp = 1">
          <img *ngIf="selectedTyp == 1" src="../../../../assets/successhue.svg">
        </div>
      </div>
      <div class="checkbox_container" style="width: 100%;">
        <label class="lbl_regular_16">Täglich</label>
        <div class="checkbox"
          [ngClass]="selectedTyp == 2 ? 'checkbox_checked' : ''"
          (click)="selectedTyp = 2">
          <img *ngIf="selectedTyp == 2" src="../../../../assets/successhue.svg">
        </div>
      </div>
      <div class="checkbox_container" style="width: 100%;">
        <label class="lbl_regular_16">Werktags</label>
        <div class="checkbox"
          [ngClass]="selectedTyp == 3 ? 'checkbox_checked' : ''"
          (click)="selectedTyp = 3">
          <img *ngIf="selectedTyp == 3" src="../../../../assets/successhue.svg">
        </div>
      </div>
      <div class="checkbox_container" style="width: 100%;">
        <label class="lbl_regular_16">Benutzerdefinierte Tage</label>
        <div class="checkbox"
          [ngClass]="selectedTyp == 4 ? 'checkbox_checked' : ''"
          (click)="selectedTyp = 4">
          <img *ngIf="selectedTyp == 4" src="../../../../assets/successhue.svg">
        </div>
      </div>
    </div>
    <div class="btn_container">
      <button class="btn_submit lbl_black_16" type="button" (click)="onSubmit()">Weiter</button>
    </div>
  </form>
</div>
