<div class="view_body" [ngStyle]="{'background-image':  foundTrips.length == 0 ? 'url(../../../../assets/emptyscreen.svg)' : ''}">
  <app-search-result-element *ngFor="let trip of foundTrips"
    [trip]="trip"
    (bookTripEmitter)="bookTripEmitter.emit($event)"
  ></app-search-result-element>
  <div *ngIf="foundTrips?.length == 0" class="no_trips">
    <Label class="lbl_regular_24 no_trips_lbl">Kein Fahrten</Label>
    <Label class="lbl_regular_14 no_trips_lbl">Wir speichern deine Fahrt und melden uns sobald eine Fahrt mit deinen Suchkriterien erstellt wurde</Label>
  </div>
</div>
