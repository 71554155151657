import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public selectedMenu = 1;
  public dialogQuerySubsription: Subscription;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((value) => {
      if(value instanceof NavigationStart){
        this.setMenue(value.url);
      }
    });
    this.uService.getUser(Number(localStorage.getItem('id')), true);
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_create_trip'){
          GlobalVariables.is_create_process = false;
          this.loadPage(value.submit_value);
        }
      }
    });
  }

  reload(page: string) {
    if(this.router.url == '/create-drive' && GlobalVariables.is_create_process){
      this.dialogService.openQuery(
        {
          title: 'Fahrt verwerfen?',
          message: 'Wenn Sie fortsetzen gehen alle Änderungen verloren.',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Verwefen',
          typ: 'cancel_create_trip',
          submit_value: page,
          showClose: true,
        }
      );
    } else {
      this.loadPage(page);
    }
  }

  loadPage(page: string) {
    if(this.router.url == page){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        if(page == '/dashboard'){
          window.location.reload();
        } else {
          this.router.navigate([page])
        }
      });
    } else {
      this.router.navigate([page]);
    }
  }

  setMenue(url: string) {
    if(url.includes('/dashboard')){
      this.selectedMenu = 1;
    }
    if(url.includes('/create-drive')){
      this.selectedMenu = 2;
    }
    if(url.includes('/my-drives')){
      this.selectedMenu = 3;
    }
    if(url.includes('/profile')){
      this.selectedMenu = 4;
    }
  }

  getIcon(menu: number): string {
    switch (menu) {
      case 1: if(menu == this.selectedMenu){ return '../../../assets/homeactive.svg'; } return '../../../assets/home.svg'; break;
      case 2: if(menu == this.selectedMenu){ return '../../../assets/newrideactive.svg'; } return '../../../assets/newride.svg'; break;
      case 3: if(menu == this.selectedMenu){ return '../../../assets/ridesactive.svg'; } return '../../../assets/rides.svg'; break;
      case 4: if(menu == this.selectedMenu){ return '../../../assets/useractive.svg'; } return '../../../assets/user.svg'; break;
      default: break;
    }
  }

  getNotify(): number {
    return this.uService.notify_request$.getValue();
  }
}
