import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent implements OnInit {
  @Input() openView: number;
  @Output() backEmitter = new EventEmitter<undefined>();
  public screenWidth;

  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.screen.width;
  }

  getTitle(): string {
    switch (this.openView) {
      case 1: return 'Profil'; break;
      case 2: return 'Profil'; break;
      case 3: return 'Profil'; break;
      case 4: return 'Umwege'; break;
      case 5: return 'Benachrichtigungen'; break;
      case 6: return 'Passwort ändern'; break;
      case 7: return 'Support'; break;
      case 8: return 'Support'; break;
      case 9: return 'Kontaktiere uns'; break;
      case 10: return 'Support'; break;
      case 11: return 'Support'; break;
      default: break;
    }
  }
}
