import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dialog-several-times',
  templateUrl: './dialog-several-times.component.html',
  styleUrls: ['./dialog-several-times.component.scss']
})
export class DialogSeveralTimesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() isReturnTrip: boolean;
  @Output() submitEmitter = new EventEmitter<undefined>();
  @Output() closeDialogEmitter = new EventEmitter<undefined>();

  public selectedTyp: number;

  constructor() { }

  ngOnInit(): void {
    this.selectedTyp = this.isReturnTrip ? this.form.controls.return_several_time_typ.value : this.form.controls.several_time_typ.value;
  }

  onSubmit() {
    if(this.isReturnTrip) this.form.controls.return_several_time_typ.setValue(this.selectedTyp);
    else this.form.controls.several_time_typ.setValue(this.selectedTyp);
    this.submitEmitter.emit();
  }

  closeDialog() {
    // if(this.isReturnTrip) this.form.controls.return_several_time_typ.setValue(this.selectedTyp);
    // else this.form.controls.several_time_typ.setValue(this.selectedTyp);
    // console.log(this.form.controls.several_time_typ.value)
    this.closeDialogEmitter.emit()
  }
}
