<div id="dialog_window" *ngIf="show">
  <div id="dialog_container">
    <div class="body">
      <div class="year_wrapper">
        <img *ngIf="isNotTodaysMonth(month[0])" class="icon_arrow_year_left" src="../../../../assets/navarrow.svg" (click)="previousMonth(month[0])">
        <Label class="lbl_medium_16">{{month[0] | date: 'MMMM YYYY'}}</Label>
        <img class="icon_arrow_year_right" style="transform: rotate(180deg);" src="../../../../assets/navarrow.svg" (click)="nextMonth(month[0])">
      </div>
      <div class="week_day_wrappper">
        <Label class="week_day lbl_regular_12 lbl_active">MO</Label>
        <Label class="week_day lbl_regular_12 lbl_active">DI</Label>
        <Label class="week_day lbl_regular_12 lbl_active">MI</Label>
        <Label class="week_day lbl_regular_12 lbl_active">DO</Label>
        <Label class="week_day lbl_regular_12 lbl_active">FR</Label>
        <Label class="week_day lbl_regular_12 lbl_active">SA</Label>
        <Label class="week_day lbl_regular_12 lbl_active">SO</Label>
      </div>
      <div class="days_container">
        <div class="day_wrapper">
          <div *ngFor="let lastMonthDay of lastMonthDays"></div>
          <div *ngFor="let day of month" (click)="dayIsDisabled(day) ? null : selectDateAction(day)"
            class="day"
            [ngClass]="isSelectedDay(day) ? 'day_enable day_selected' : (dayIsDisabled(day) ? 'day_disabled' : 'day_enable')">
            <Label class="day_date lbl_regular_14" [ngClass]="isSelectedDay(day) ? 'day_enable lbl_active' : (dayIsDisabled(day) ? 'lbl_light' : 'day_enable')">
              {{day | date: 'dd'}}
            </Label>
            <Label *ngIf="time && isSelectedDay(day)" class="day_time day_enable lbl_regular_8">
              {{getTime(day) | date: 'HH:mm'}}
            </Label>
          </div>
        </div>
      </div>
      <div class="data_container">
        <div class="inputfield_container" [ngClass]="(submitted && !time) ? 'inputfield_container_invalid' : ''">
          <input class="inputfield" type="time" [(ngModel)]="time" (change)="setTime(time)">
          <div class="input_valid_indicator" [ngClass]="!time ? 'input_valid_indicator_invalid' : ''"></div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <button class="btn_gray lbl_black_16" (click)="closeCalendar()">Abbrechen</button>
      <button class="btn_submit lbl_black_16" [ngClass]="!isValidTime() ? 'btn_submit_inactive' : ''" [disabled]="!isValidTime()" (click)="onSubmit()">Ok</button>
    </div>
  </div>
</div>
