<div class="view_container">
  <app-my-drives-header
    [trip]="selectedTrip"
    [selectedPassenger]="selectedPassenger"
    [showView]="showView"
    (backEmitter)="closeDetails()"
    (openEditEmitter)="openEditAction()"
    (stornoTripEmitter)="stornoTripAction()"
  ></app-my-drives-header>
  <div class="view_body" *ngIf="showView == 1" [ngStyle]="{'background-image': (tab == 1 && createdTrips?.length == 0) || (tab == 2 && joinedTrips?.length == 0) ? 'url(../../../assets/emptyscreen.svg)' : ''}">
    <div class="tab_wrapper">
      <div class="tab_slider" [ngStyle]="{'left': tab == 1 ? '0%' : '50%'}"></div>
      <label class="tab_value lbl_regular_14" (click)="tab = 1">Als Fahrer</label>
      <label class="tab_value lbl_regular_14" (click)="tab = 2">Ich fahre mit</label>
    </div>
    <div class="list_wrapper" *ngIf="tab == 1">
      <app-drive-list-element *ngFor="let trip of createdTrips"
        [trip]="trip"
        [isCreatedTrips]="true"
        (openDetailsEmitter)="openDetails($event)"
      ></app-drive-list-element>
      <div *ngIf="(tab == 1 && trip?.length == 0) || (tab == 1 && createdTrips?.length == 0)" class="no_trips">
        <Label class="lbl_regular_24 no_trips_lbl">Kein Fahrten</Label>
      </div>
    </div>
    <div class="list_wrapper" *ngIf="tab == 2">
      <app-drive-list-element *ngFor="let trip of joinedTrips"
        [trip]="trip"
        [isCreatedTrips]="false"
        (openDetailsEmitter)="openDetails($event)"
      ></app-drive-list-element>
      <div *ngIf="(tab == 1 && trip?.length == 0) || (tab == 2 && joinedTrips?.length == 0)" class="no_trips">
        <Label class="lbl_regular_24 no_trips_lbl">Kein Fahrten</Label>
      </div>
    </div>
  </div>
  <app-my-drives-details *ngIf="showView == 2"
    [trip]="selectedTrip"
    [user]="user"
    [tab]="tabDetails"
    (openProfileEmitter)="openProfile($event)"
    (openChatEmitter)="openPhoneChatDirekt($event)"
    (acceptOrRefuseEmitter)="acceptOrRefusePassenger($event)"
    (openEditEmitter)="openEditAction()"
  ></app-my-drives-details>
  <app-user-profile *ngIf="showView == 3"
    [user]="selectedPassenger"
    [trip]="selectedTrip"
    [showChatPhone]="showChatPhone"
    (openChatEmitter)="openPhoneChat()"
  ></app-user-profile>
  <app-edit-drive *ngIf="showView == 5"
    [trip]="selectedTrip"
    [user]="user"
    [editPage]="editPage"
    (closeEditEmitter)="closeEdit($event)"
    (openEditTripDetailsEmitter)="editPage = $event"
  ></app-edit-drive>
</div>
