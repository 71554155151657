<div class="view_body">
  <form [formGroup]="form" class="form_profile">
    <img class="icon" src="../../../../assets/editphonenumber.svg">
    <div class="inputfield_container" [ngClass]="submitted && form.controls.phonenumber.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="text" placeholder="Telefonnummer"
        formControlName="phonenumber">
      <div class="input_valid_indicator" [ngClass]="form.controls.phonenumber.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <div class="checkbox_container">
      <label class="lbl_regular_16">Telefonnummer für Mitarbeiter sichtbar machen</label>
      <div class="checkbox" [ngClass]="form.controls.allow_phonenumber.value ? 'checkbox_checked' : ''" (click)="form.controls.allow_phonenumber.setValue(!form.controls.allow_phonenumber.value)">
        <img *ngIf="form.controls.allow_phonenumber.value == true" src="../../../../assets/successhue.svg">
      </div>
    </div>
    <button class="btn_submit" (click)="save()">Speichern</button>
  </form>
</div>
