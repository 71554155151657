import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-success',
  templateUrl: './dialog-success.component.html',
  styleUrls: ['./dialog-success.component.scss']
})
export class DialogSuccessComponent implements OnInit {
  public show = false;
  public title: string;
  public message: string;
  public btnSubmitTxt: string;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogSuccess$.subscribe((data: any) => {
      this.title = data.title;
      this.message = data.message;
      this.btnSubmitTxt = data.btn_submit_txt;
      this.show = true;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscription) this.subscription.unsubscribe();
  }

  onSubmit(){
    this.title = '';
    this.message = '';
    this.btnSubmitTxt = '';
    this.show = false;
  }
}
