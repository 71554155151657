<div class="view_body">
  <label class="lbl_medium_24 lbl_active">Verwalte, wie du benachrichtigt werden möchtest</label>
  <form [formGroup]="form" class="form_profile">
    <img class="icon" src="../../../../assets/mailbig.svg">
    <div class="checkbox_container">
      <label class="lbl_regular_16">Push-Benachrichtigungen</label>
      <div class="checkbox" [ngClass]="form.controls.push_enabled.value ? 'checkbox_checked' : ''" (click)="form.controls.push_enabled.setValue(!form.controls.push_enabled.value)">
        <img *ngIf="form.controls.push_enabled.value == true" src="../../../../assets/successhue.svg">
      </div>
    </div>
    <div class="checkbox_container">
      <label class="lbl_regular_16">E-Mail-Benachrichtigungen</label>
      <div class="checkbox" [ngClass]="form.controls.email_enabled.value ? 'checkbox_checked' : ''" (click)="form.controls.email_enabled.setValue(!form.controls.email_enabled.value)">
        <img *ngIf="form.controls.email_enabled.value == true" src="../../../../assets/successhue.svg">
      </div>
    </div>
    <button class="btn_submit" [ngClass]="form.invalid ? 'btn_submit_inactive' : ''" [disabled]="form.invalid" (click)="save()">Speichern</button>
  </form>
</div>
