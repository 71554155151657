import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/buisness-object/user/User';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-highscore',
  templateUrl: './highscore.component.html',
  styleUrls: ['./highscore.component.scss']
})
export class HighscoreComponent implements OnInit {
  @Input() user: User;
  public ranking: User[] = [];
  public showUserPlace = false;
  public screenWidth = window.screen.width;

  constructor(
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
  }

  getDataServerside() {
    this.uService.getAvatar(this.user.user_id).subscribe((response) => {
      if(response) {
        this.user.avatar = response.data;
        this.uService.getHighscore().subscribe((result) => {
          if(result){
            for(let user of result) {
              this.uService.getAvatar(user.user_id).subscribe((avatar) => {
                if(avatar){
                  user.avatar = avatar.data;
                }
              });
            }
            this.ranking = result;
            this.ranking.sort((a,b) => {
              if(a.ranking > b.ranking) return 1;
              if(a.ranking < b.ranking) return -1;
              return 0;
            })
          }
        })
      }
    })
  }

  getLogo() : string {
    if(this.user?.company_id == 1) return '../../../../assets/magna.svg';
    else return '';
  }

  showUser() {
    if(this.screenWidth < GlobalVariables.breakpoint_tablet){
      const list = document.getElementById('view_body_list');
      const element = document.getElementById('user_'+this.user.user_id);
      if(list && element){
        list.scrollTop = element.offsetTop - list.offsetHeight + 65;
        element.style.backgroundColor = 'rgb(190 190 190 / 19%)';
        element.style.padding = '30px 20px';
        element.style.margin = '5px';
        element.style.boxShadow = '2px 2px 10px #0000002b';
        element.style.borderRadius = '10px';
        element.style.width = 'calc(100% - 50px)';
      }
    } else {
      const list = document.getElementById('heighscore_list');
      const element = document.getElementById('user_'+this.user.user_id);
      if(list && element){
        list.scrollTop = element.offsetTop - list.offsetHeight + 65;
        element.style.backgroundColor = 'rgb(190 190 190 / 19%)';
        element.style.padding = '30px 20px';
        element.style.margin = '5px';
        element.style.boxShadow = '2px 2px 10px #0000002b';
        element.style.borderRadius = '10px';
        element.style.width = 'calc(100% - 80px)';
      }
    }
  }
}
