import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/buisness-object/location/Location';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LocationService } from 'src/app/service/location/location.service';
import { MapService } from 'src/app/service/map/map.service';
import { TripService } from 'src/app/service/trip/trip.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-edit-drive',
  templateUrl: './edit-drive.component.html',
  styleUrls: ['./edit-drive.component.scss']
})
export class EditDriveComponent implements OnInit, OnDestroy, OnChanges {
  @Input() user: User;
  @Input() trip: Trip;
  @Input() isTemplate: boolean;
  @Input() editPage: number;
  @Output() closeEditEmitter = new EventEmitter<Trip>();
  @Output() createDriveEmitter = new EventEmitter<Trip>();
  @Output() openEditTripDetailsEmitter = new EventEmitter<number>();

  @ViewChild('startAddress') startAddress: ElementRef;
  @ViewChild('targetAddress') targetAddress: ElementRef;
  public dialogCalendarSubscription: Subscription;
  public dialogInfoSubscription: Subscription;
  public form: FormGroup;
  public importantLocations: Location[] = [];
  public routes: google.maps.DirectionsRoute[] = [];
  public routesResult: google.maps.DirectionsResult;
  public isPhone = window.screen.width <= 1180;
  public grenzWertFee;

  constructor(
    private formBuilder: FormBuilder,
    private dService: DialogService,
    private mapService: MapService,
    private tService: TripService,
    private uService: UserService
  ) { }

  ngOnInit(): void {

    // console.log(this.trip)
    // this.grenzWertFee = Math.ceil((0.05 * (this.trip.distance / 1000) / 0.5)) * 0.5;
    // if(this.grenzWertFee < 0.5) this.grenzWertFee = 0.5;
    // let start = new google.maps.LatLng(this.trip.start_latitude,this.trip.start_longitude);
    // let target = new google.maps.LatLng(this.trip.target_latitude,this.trip.target_longitude);
    // this.mapService.initializeMap(start,target,null).then((result) => {
    //   console.log(result)
    //   this.grenzWertFee = Math.ceil((0.05 * (this.form.controls.route.value.legs[0].distance.value / 1000) / 0.5)) * 0.5;
    //   if(this.grenzWertFee < 0.5) this.grenzWertFee = 0.5;
    // });




    this.uService.getAvatar(Number(localStorage.getItem('id'))).subscribe((result) => {
      if(result) this.user.avatar = result.data;
    })

    this.fillForm();

    this.setDialogSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['editPage']){
      if(this.editPage == 1 && changes['editPage'].previousValue == 2){
        this.fillForm();
      }
    }
  }

  fillForm() {
    this.form = this.formBuilder.group({
      time: [null, Validators.required],
      start: [null, Validators.required],
      start_longitude: [null, Validators.required],
      start_latitude: [null, Validators.required],
      target: [null, Validators.required],
      target_longitude: [null, Validators.required],
      target_latitude: [null, Validators.required],
      route: [null],
      accept_detours: [null, Validators.required],
      passenger_capacity: [null, Validators.required],
      passenger_fee: [null, Validators.required],
    })
    if(this.trip){
      this.form.controls.time.setValue(this.trip.timestamp ? new Date(this.trip.timestamp) : new Date());
      this.form.controls.start.setValue(this.trip.start_description);
      this.form.controls.target.setValue(this.trip.target_description);
      this.form.controls.accept_detours.setValue(this.trip.accept_detours);
      this.form.controls.passenger_capacity.setValue(this.trip.passenger_capacity);
      this.form.controls.passenger_fee.setValue(this.trip.passenger_fee / 100);
      this.form.controls.start_longitude.setValue(this.trip.start_longitude);
      this.form.controls.start_latitude.setValue(this.trip.start_latitude);
      this.form.controls.target_longitude.setValue(this.trip.target_longitude);
      this.form.controls.target_latitude.setValue(this.trip.target_latitude);
      if(this.isTemplate) this.form.controls.time.setValue(new Date());
    }
  }

  ngAfterViewInit(): void {
    //this.getRoutes();
    // if(this.form.controls.start.value) this.startAddress.nativeElement.value = this.form.controls.start.value;
    // if(this.form.controls.target.value) this.targetAddress.nativeElement.value = this.form.controls.target.value;
  }

  ngOnDestroy(): void {
    if(this.dialogCalendarSubscription) this.dialogCalendarSubscription.unsubscribe();
    if(this.dialogInfoSubscription) this.dialogInfoSubscription.unsubscribe();
  }

  getRoutes() {
    let start = new google.maps.LatLng(this.form.controls.start.value.geometry.location.lat(),this.form.controls.start.value.geometry.location.lng());
    let target = new google.maps.LatLng(this.form.controls.target.value.geometry.location.lat(),this.form.controls.target.value.geometry.location.lng());
    this.mapService.initializeMap(start,target,'map').then((result) => {
      this.routesResult = result;
      this.form.controls.route.setValue(this.routesResult.routes[0]);
    });
  }

  setDialogSubscription() {
    this.dialogInfoSubscription = this.dService.closeDialogInfo$.subscribe((value) => {
      if(value){
        this.udpateTrip();
      }
    });
  }

  getArray(i: number): any {
    return new Array(i);
  }

  isValidForm() {
    return this.form.valid && this.form.controls.passenger_capacity.value < 10;
  }

  nextPage() {
    if(this.editPage == 3){
      this.form.controls.passenger_fee.setValue(this.calculateMaxFee());
      this.openEditTripDetailsEmitter.emit(1);
    } else {
      this.openEditTripDetailsEmitter.emit(3);
      this.editPage = 3;
    }
  }

  calculateMaxFee(): number {
    this.trip.distance = this.form.controls.route.value?.legs ? this.form.controls.route.value.legs[0].distance.value : this.trip.distance;
    let grenzWertFee = Math.floor((0.05 * (this.trip.distance / 1000) / 0.5)) * 0.5;
    if(grenzWertFee < 0.5) grenzWertFee = 0.5;
    return grenzWertFee;
  }

  updateTripAction() {
    let grenzWertFee = this.calculateMaxFee();
    if(this.form.controls.passenger_fee.value > grenzWertFee){
      this.dService.openInformation(
        {
          title: 'Für diesen Betrag benötigst du ein Gewerbe',
          message: 'Bitte beachte, dass du bei einem Betrag von € ' + this.form.controls.passenger_fee.value + ' ein eingetragenes Gewerbe vorweisen musst, ansonsten darf der Beitrag je Mitfahrer_in den gesetzlichen Betrag von € 0.05 je Kilometer nicht übersteigen',
          //message: 'Bitte beachte, dass du bei einem Betrag über € '+grenzWertFee+' ein eingetragenes Gewerbe vorweisen musst.',
          btn_submit_txt: 'Verstanden',
          typ: 'info',
          submit_value: null,
        }
      );
    } else {
      this.udpateTrip();
    }
  }

  udpateTrip() {
    this.trip.timestamp = this.form.controls.time.value.getTime();
    this.trip.start_description = this.form.controls.start.value;
    this.trip.target_description = this.form.controls.target.value;
    this.trip.start_latitude = this.form.controls.start_latitude.value;
    this.trip.start_longitude = this.form.controls.start_longitude.value;
    this.trip.target_latitude = this.form.controls.target_latitude.value;
    this.trip.target_longitude = this.form.controls.target_longitude.value;
    this.trip.distance = this.form.controls.route.value?.legs ? this.form.controls.route.value.legs[0].distance.value : this.trip.distance;
    this.trip.duration = this.form.controls.route.value?.legs ? this.form.controls.route.value.legs[0].duration.value : this.trip.duration;
    this.trip.polyline = this.form.controls.route.value?.overview_polyline ? this.form.controls.route.value.overview_polyline : this.trip.polyline;
    this.trip.accept_detours = this.form.controls.accept_detours.value;
    this.trip.passenger_capacity = this.form.controls.passenger_capacity.value;
    this.trip.passenger_fee = this.form.controls.passenger_fee.value * 100;
    if(!this.isTemplate){
      this.tService.updateTrip(this.trip).subscribe((result) => {
        if(result){
          this.closeEditEmitter.emit(result);
        }
      })
    } else {
      this.createDriveEmitter.emit(this.trip);
    }
  }

  openPageChange() {
    this.editPage = 2;
    this.openEditTripDetailsEmitter.emit(2);
  }
}
