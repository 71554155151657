import { Subscription } from 'rxjs';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Location } from 'src/app/buisness-object/location/Location';
import { PredictionPlace } from 'src/app/buisness-object/location/PredictionPlace';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LocationService } from 'src/app/service/location/location.service';
import { MapService } from 'src/app/service/map/map.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-request-detour',
  templateUrl: './request-detour.component.html',
  styleUrls: ['./request-detour.component.scss']
})
export class RequestDetourComponent implements OnInit, AfterViewInit {
  @Input() trip: Trip;
  @Output() submitEmitter = new EventEmitter<{entryPoint: any, targetPoint: any}>();
  public form: FormGroup;
  public entryPoint = false;
  public targetPoint = false;
  @ViewChild('startAddress') startAddress: ElementRef;
  @ViewChild('targetAddress') targetAddress: ElementRef;
  public importantLocations: Location[] = [];
  public validStart = false;
  public validTarget = false;
  public predictionPlaces: PredictionPlace[] = [];
  public sessionToken: google.maps.places.AutocompleteSessionToken;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public listenerOnClick: () => void;
  public listenerArrowkeys: () => void;
  public placeArrowIndex = -1;
  public user: User;
  public inputTimeout;
  public dialogInfoSubscription: Subscription;

  constructor(
    private lService: LocationService,
    private renderer: Renderer2,
    private mapService: MapService,
    private uService: UserService,
    private formBuilder: FormBuilder,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      detour_start: [null],
      detour_target: [null],
    })
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.getDataServerside();
  }

  ngAfterViewInit(): void {
    this.listenerOnClick = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne && !this.startAddress.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.targetAddress.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      this.placeArrowIndex = -1;
    })
    if(window.screen.width > 1750) {
      this.listenerArrowkeys = this.renderer.listen('document','keydown',(e: KeyboardEvent) => {
        if(this.showDropdownOne || this.showDropdownTwo){
          if(e.key == 'ArrowUp'){
            if(this.placeArrowIndex <= 0) this.placeArrowIndex = (this.predictionPlaces.length - 1);
            else this.placeArrowIndex--;
          } else if(e.key == 'ArrowDown'){
            if(this.placeArrowIndex == (this.predictionPlaces.length - 1)) this.placeArrowIndex = 0;
            else this.placeArrowIndex++;
          } else if(e.key == 'Enter'){
            this.setLocation(this.predictionPlaces[this.placeArrowIndex], (this.showDropdownOne ? 1 : 2))
            this.placeArrowIndex = -1;
          }
          const dropdown = document.getElementById('dropdown');
          const element = document.getElementById('place_'+this.placeArrowIndex);
          if(dropdown && element){
            if(element.offsetTop > (dropdown.offsetHeight)){
              dropdown.scrollTop = element.offsetTop;
            } else if(element.offsetLeft < (dropdown.offsetHeight )){
              dropdown.scrollTop = element.offsetHeight - 32;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    if(this.listenerOnClick) this.listenerOnClick();
    if(this.listenerArrowkeys) this.listenerArrowkeys();
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    if(this.dialogInfoSubscription) this.dialogInfoSubscription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogInfoSubscription = this.dService.closeDialogInfo$.subscribe((value) => {
      if(value){

      }
    });
  }

  getDataServerside() {
    this.uService.getUser(Number(localStorage.getItem('id'))).subscribe((result) => {
      if(result){
        this.user = result.user;
        if(this.user.company_id == 1){
          this.lService.getImportantLocations().subscribe((response) => {
            if(response){
              this.importantLocations = response;
            }
          })
        }
      }
    })
  }

  getPlaces(search: string, dropdownNumber: number) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.mapService.getPredictions(search, this.sessionToken, this.importantLocations).then((result) => {
        this.predictionPlaces = result;
        if(this.predictionPlaces.length == 0){
          if(dropdownNumber == 1) this.showDropdownOne = false;
          if(dropdownNumber == 2) this.showDropdownTwo = false;
        } else {
          if(dropdownNumber == 1) this.showDropdownOne = true;
          if(dropdownNumber == 2) this.showDropdownTwo = true;
        }
      });
    }, 300);
  }

  async setLocation(predictionPlace: PredictionPlace, inputNumber: number) {
    if(predictionPlace.place_id.includes('magna_')){
      let place = new Location(
        0,
        predictionPlace.full_name,
        predictionPlace.latitude,
        predictionPlace.longitude,
        1,
      );
      if(inputNumber == 1){
        this.form.controls.detour_start.setValue(place);
        this.startAddress.nativeElement.value = place.location_name;
      } else if(inputNumber == 2){
        this.form.controls.detour_target.setValue(place);
        this.targetAddress.nativeElement.value = place.location_name;
      }
    } else {
      this.mapService.getLocationDetails(predictionPlace).then((result) => {
        if(result){
          if(inputNumber == 1){
            this.checkAllowedDistance(result.latitude, result.longitude, inputNumber).then((allowed) => {
              if(allowed){
                this.form.controls.detour_start.setValue(result);
                this.startAddress.nativeElement.value = result.location_name;
              } else {
                this.showDialog();
              }
            })
          } else if(inputNumber == 2){
            this.checkAllowedDistance(result.latitude, result.longitude, inputNumber).then((allowed) => {
              if(allowed){
                this.form.controls.detour_target.setValue(result);
                this.targetAddress.nativeElement.value = result.location_name;
              } else {
                this.showDialog();
              }
            })
          }
          this.predictionPlaces = [];
        }
      })
    }
    this.showDropdownOne = false;
    this.showDropdownTwo = false;
  }

  checkAllowedDistance(requestDetourLat: number, requestDetourLng: number, typ: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if(this.trip.driver.allowed_detour){
        let start = new google.maps.LatLng(typ == 1 ? this.trip.start_latitude : this.trip.target_latitude, typ == 1 ? this.trip.start_longitude : this.trip.target_longitude);
        let target = new google.maps.LatLng(requestDetourLat,requestDetourLng);
        this.mapService.initializeMap(start,target,null).then((result) => {
          if(result){
            for(let route of result.routes){
              if(route.legs[0]?.distance.value > (this.trip.driver.allowed_detour * 1000)){
                resolve(false);
              } else {
                resolve(true);
              }
            }
          }
        });
      } else {
        resolve(true);
      }
    })
  }

  showDialog() {
    this.dService.openInformation(
      {
        title: 'Umweg zu groß',
        message: 'Der Fahrer dieser Fahrt akzeptiert nur Umwege bis zu ' + this.trip.driver.allowed_detour +'km.',
        btn_submit_txt: 'Ok',
        typ: 'info',
        submit_value: null,
      }
    );
  }

  isMagnaPlace(place_id: string): boolean {
    if(place_id.includes('magna')) return true;
    else return false;
  }

  checkboxAction(input: number) {
    if(input == 1){
      this.entryPoint = !this.entryPoint;
      if(!this.entryPoint){
        this.form.controls.detour_start.setValue(null);
        this.startAddress.nativeElement.value = '';
      }
    } else if(input == 2){
      this.targetPoint = !this.targetPoint;
      if(!this.targetPoint){
        this.form.controls.detour_target.setValue(null);
        this.targetAddress.nativeElement.value = '';
      }
    }
  }

  submit() {
    if(this.form.controls.detour_start.value || this.form.controls.detour_target.value){
      this.submitEmitter.emit({entryPoint: this.form.controls.detour_start.value, targetPoint: this.form.controls.detour_target.value});
    }
  }
}
