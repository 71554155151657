<div id="verify_view">
  <div *ngIf="!invalidToken && !tokenExpired" class="form">
    <img class="logo" src="../../../assets/logo.svg">
    <div style="width: 100%;">
      <div *ngIf="!success" class="flex">
        <label class="lbl_medium_24">Verifizierung</label>
        <label class="lbl_regular_14">Bitte bestätige deine E-Mail-Adresse.</label>
        <button class="btn_submit" (click)="onSubmit()">Verifizieren</button>
      </div>
      <div *ngIf="success" class="flex">
        <label class="lbl_medium_24">Verifizierung Erfolgreich!</label>
        <button class="btn_submit" (click)="toLogin()">Zum Login</button>
      </div>
    </div>
  </div>
  <div *ngIf="tokenExpired" class="expired_container">
    <div class="lbl_black_80">401</div>
    <div class="lbl_medium_24">Link abgelaufen.</div>
  </div>
  <div *ngIf="invalidToken" class="lbl_medium_24">Not Authorized</div>
</div>
