<div class="trip_wrapper" (click)="bookTripEmitter.emit(trip)">
  <div class="trip_wrapper_header">
    <div class="avatar_container">
      <img [ngClass]="trip.driver.avatar ? 'avatar' : 'avatar_placeholder'" [src]="trip.driver?.avatar ? ('data:image/png;base64,' + trip.driver?.avatar) : '../../../assets/userimageplaceholder.svg'">
    </div>
    <div class="trip_user_data_container_header">
      <label class="lbl_medium_28 lbl_white">{{trip.driver.first_name}}</label>
      <div class="trip_review_container_header">
        <div class="star_container">
          <div *ngFor="let start of getArray(5); let i=index" class="icon_star">{{trip.driver?.getRating() >= (i+1) ? '&#9733;' : '&#10025;'}}</div>
        </div>
        <label class="lbl_regular_14 lbl_white">{{trip.driver.rating_count}} Bewertungen</label>
      </div>
    </div>
  </div>
  <div class="trip_wrapper_body">
    <div class="data_wrapper">
      <img class="data_icon" src="../../../../assets/calenderwhite.svg">
      <div class="data_time">
        <label class="lbl_black_14 lbl_white">{{trip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
        <label class="lbl_black_14 lbl_white">{{trip.timestamp | date: 'HH:mm'}} Uhr</label>
      </div>
    </div>
    <div class="data_wrapper">
      <img class="data_icon" src="../../../../assets/startwhite.svg">
      <label class="lbl_regular_14 lbl_white">{{trip.start_description}}</label>
    </div>
    <div class="data_wrapper">
      <img class="data_icon" src="../../../../assets/endwithe.svg">
      <label class="lbl_regular_14 lbl_white">{{trip.target_description}}</label>
    </div>
    <div class="data_wrapper">
      <img class="data_icon" src="../../../../assets/detourwhite.svg">
      <label class="lbl_regular_14 lbl_white">{{trip.accept_detours ? 'Umwege sind erlaubt' : 'Keine Umwege'}}</label>
    </div>
    <div class="trip_footer">
      <label class="lbl_regular_16 lbl_white">{{trip.passenger_capacity}} {{trip.passenger_capacity == 1 ? 'Platz frei' : 'Plätze frei'}}</label>
      <div>
        <label class="lbl_regular_16 lbl_white">je </label>
        <label class="lbl_black_28 lbl_white">€ {{(trip.passenger_fee / 100) | currency: 'EUR': '': '1.2-2'}}</label>
      </div>
    </div>
  </div>
</div>
