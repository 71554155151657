import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})

//https://mobile.hey-way.com/agb.html

export class AgbComponent implements OnInit {
  private template: any;

  constructor(
    private uService: UserService
  ) { }

  ngOnInit(): void {
  }
}
