<div class="view_container" (window:resize)="onResize($event)">
  <app-profile-header
    [openView]="openView"
    (backEmitter)="cancel()"
  ></app-profile-header>
  <div *ngIf="openView == 1 && user" class="view_body" id="scroll_view">
    <div class="header">
      <div class="profile_container">
        <div class="user_img_container" (click)="selectFile.click()">
          <img [ngClass]="user?.avatar ? 'user_img' : 'user_img_placeholder'" [src]="user?.avatar ? ('data:image/png;base64,' + user?.avatar) : '../../../assets/userimageplaceholder.svg'">
          <input #selectFile type="file" accept="image/x-png,image/jpeg" (change)="uploadProfileAvatar($event)" multiple="false" style="display: none;">
        </div>
        <div class="user_data_container">
          <label class="lbl_medium_28" style="margin-bottom: 15px;">{{user?.first_name}} {{user?.last_name}}</label>
          <div class="star_container">
            <div *ngFor="let start of getArray(5); let i=index" class="icon_star">{{user?.getRating() >= (i+1) ? '&#9733;' : '&#10025;'}}</div>
          </div>
          <label class="lbl_regular_14">{{user?.rating_count}} {{user?.rating_count == 1 ? 'Bewertung' : 'Bewertungen'}}</label>
          <label class="lbl_regular_14">Mitglied seit: {{user?.registered_since | date: 'dd.MM.YYYY'}}</label>
        </div>
      </div>
      <div class="progress_bar">
        <div id="progress_bar_inner" [ngStyle]="{'width': profileCompletion}"></div>
      </div>
    </div>
    <div *ngIf="!isPhone" class="body">
      <div class="column">
        <div class="data_wrapper">
          <div class="title_container">
            <img class="icon_data" src="../../../assets/userimageplaceholder.svg">
            <div class="title_container_inner">
              <label class="lbl_regular_16 lbl_active">{{user?.phonenumber && user?.vehicle.vehicle_brand_and_model ? 'Vollständiges Profil' : getProfileTitle()}}</label>
              <label class="lbl_regular_12">{{user?.phonenumber && user?.vehicle.vehicle_brand_and_model ? '' : 'Vervollständige dein Profil'}}</label>
            </div>
          </div>
          <div class="menu_item_container" (click)="openMenuItem(2)">
            <div class="menu_value_container">
              <img class="icon_data" src="../../../assets/phonegrey.svg">
              <label class="lbl_regular_16">Telefonnummer</label>
              <div *ngIf="!user?.phonenumber" class="menu_data_missing"></div>
            </div>
            <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
          </div>
          <div class="menu_item_container" (click)="openMenuItem(3)">
            <div class="menu_value_container">
              <img class="icon_data" src="../../../assets/mycargrey.svg">
              <label class="lbl_regular_16">Mein Auto</label>
              <div *ngIf="!user?.vehicle?.vehicle_color || !user?.vehicle?.vehicle_color" class="menu_data_missing"></div>
            </div>
            <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
          </div>
        </div>
        <div *ngIf="!isTablet">
          <div class="data_wrapper">
            <div class="title_container">
              <img class="icon_data" src="../../../assets/support.svg">
              <div class="title_container_inner">
                <label class="lbl_regular_16 lbl_active">Support</label>
              </div>
            </div>
            <div class="menu_item_container" (click)="openMenuItem(10)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/legalsmallgrey.svg">
                <label class="lbl_regular_16">AGB</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(11)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/legalsmallgrey.svg">
                <label class="lbl_regular_16">Datenschutz</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(8)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/faqgrey.svg">
                <div class="title_container_inner">
                  <label class="lbl_regular_16">FAQ</label>
                  <label class="lbl_regular_12">Häufig gestellte Fragen</label>
                </div>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(9)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/contactgrey.svg">
                <label class="lbl_regular_16">Kontaktiere uns</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
          </div>
        </div>
        <div *ngIf="isTablet">
          <div class="data_wrapper">
            <div class="title_container">
              <img class="icon_data" src="../../../assets/settings.svg">
              <label class="lbl_regular_16 lbl_active">Einstellungen</label>
            </div>
            <div class="menu_item_container" *ngIf="user?.company_id == 1" (click)="openMenuItem(4)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/detourgrey.svg">
                <label class="lbl_regular_16">Umwege</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" *ngIf="user?.company_id == 1" (click)="openMenuItem(5)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/messagegrey.svg">
                <label class="lbl_regular_16">Benachrichtigungen</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(6)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/lockgrey.svg">
                <label class="lbl_regular_16">Passwort ändern</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="logoutAction()">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/logoutgrey.svg">
                <label class="lbl_regular_16">Abmelden</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="deleteAction()">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/deletegrey.svg">
                <label class="lbl_regular_16">Account löschen</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div *ngIf="isTablet">
          <div class="data_wrapper">
            <div class="title_container">
              <img class="icon_data" src="../../../assets/support.svg">
              <div class="title_container_inner">
                <label class="lbl_regular_16 lbl_active">Support</label>
              </div>
            </div>
            <div class="menu_item_container" (click)="openMenuItem(10)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/legalsmallgrey.svg">
                <label class="lbl_regular_16">AGB</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(11)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/legalsmallgrey.svg">
                <label class="lbl_regular_16">Datenschutz</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(8)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/faqgrey.svg">
                <div class="title_container_inner">
                  <label class="lbl_regular_16">FAQ</label>
                  <label class="lbl_regular_12">Häufig gestellte Fragen</label>
                </div>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(9)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/contactgrey.svg">
                <label class="lbl_regular_16">Kontaktiere uns</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
          </div>
        </div>
        <div *ngIf="!isTablet">
          <div class="data_wrapper">
            <div class="title_container">
              <img class="icon_data" src="../../../assets/settings.svg">
              <label class="lbl_regular_16 lbl_active">Einstellungen</label>
            </div>
            <div class="menu_item_container" *ngIf="user?.company_id == 1" (click)="openMenuItem(4)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/detourgrey.svg">
                <label class="lbl_regular_16">Umwege</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" *ngIf="user?.company_id == 1" (click)="openMenuItem(5)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/messagegrey.svg">
                <label class="lbl_regular_16">Benachrichtigungen</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(6)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/lockgrey.svg">
                <label class="lbl_regular_16">Passwort ändern</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="logoutAction()">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/logoutgrey.svg">
                <label class="lbl_regular_16">Abmelden</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="deleteAction()">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/deletegrey.svg">
                <label class="lbl_regular_16">Account löschen</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
          </div>
        </div>
        <div class="data_wrapper" (click)="openMenuItem(7)">
          <div class="title_container" style="justify-content: space-between;">
            <div class="menu_value_container">
              <img class="icon_data" src="../../../assets/waymark.svg">
              <label class="lbl_regular_16 lbl_active">Über uns</label>
            </div>
            <img class="menu_icon_arraow menu_icon_last" src="../../../assets/navarrow.svg">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isPhone" class="body">
      <div class="column">
        <div class="data_wrapper">
          <div class="title_container" (click)="openMenu('menu_one')">
            <div class="title_container_flex">
              <img class="icon_data" src="../../../assets/userimageplaceholder.svg">
              <div class="title_container_inner">
                <label class="lbl_regular_16">{{user?.phonenumber && user?.vehicle.vehicle_brand_and_model ? 'Vollständiges Profil' : getProfileTitle()}}</label>
                <label class="lbl_regular_12">{{user?.phonenumber && user?.vehicle.vehicle_brand_and_model ? '' : 'Vervollständige dein Profil'}}</label>
              </div>
            </div>
            <img class="open_menu_icon" [ngClass]="showMenuOne ? 'open_menu_icon_active' : ''" src="../../../assets/navarrow.svg">
          </div>
          <div id="menu_one" [ngClass]="showMenuOne ? 'menu_items_container_open' : 'menu_items_container'">
            <div class="menu_item_container" (click)="openMenuItem(2)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/phonegrey.svg">
                <label class="lbl_regular_16">Telefonnummer</label>
                <div *ngIf="!user?.phonenumber" class="menu_data_missing"></div>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(3)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/mycargrey.svg">
                <label class="lbl_regular_16">Mein Auto</label>
                <div *ngIf="!user?.vehicle.vehicle_color || !user?.vehicle.vehicle_color" class="menu_data_missing"></div>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
          </div>
        </div>
        <div class="data_wrapper">
          <div class="title_container" (click)="openMenu('menu_three')">
            <div class="title_container_flex">
              <img class="icon_data" src="../../../assets/settings.svg">
              <div class="title_container_inner">
                <label class="lbl_regular_16">Einstellungen</label>
              </div>
            </div>
            <img class="open_menu_icon" [ngClass]="showMenuThree ? 'open_menu_icon_active' : ''" src="../../../assets/navarrow.svg">
          </div>
          <div id="menu_three"  [ngClass]="showMenuThree ? 'menu_items_container_open' : 'menu_items_container'">
            <div class="menu_item_container" *ngIf="user?.company_id == 1" (click)="openMenuItem(4)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/detourgrey.svg">
                <label class="lbl_regular_16">Umwege</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" *ngIf="user?.company_id == 1" (click)="openMenuItem(5)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/messagegrey.svg">
                <label class="lbl_regular_16">Benachrichtigungen</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(6)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/lockgrey.svg">
                <label class="lbl_regular_16">Passwort ändern</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="logoutAction()">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/logoutgrey.svg">
                <label class="lbl_regular_16">Abmelden</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="deleteAction()">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/deletegrey.svg">
                <label class="lbl_regular_16">Account löschen</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
          </div>
        </div>
        <div class="data_wrapper">
          <div class="title_container" (click)="openMenu('menu_two')">
            <div class="title_container_flex">
              <img class="icon_data" src="../../../assets/support.svg">
              <div class="title_container_inner">
                <label class="lbl_regular_16">Support</label>
              </div>
            </div>
            <img class="open_menu_icon" [ngClass]="showMenuTwo ? 'open_menu_icon_active' : ''" src="../../../assets/navarrow.svg">
          </div>
          <div id="menu_two" [ngClass]="showMenuTwo ? 'menu_items_container_open' : 'menu_items_container'">
            <div class="menu_item_container" (click)="openMenuItem(10)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/legalsmallgrey.svg">
                <label class="lbl_regular_16">AGB</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(11)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/legalsmallgrey.svg">
                <label class="lbl_regular_16">Datenschutz</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(8)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/faqgrey.svg">
                <div class="title_container_inner">
                  <label class="lbl_regular_16">FAQ</label>
                  <label class="lbl_regular_12">Häufig gestellte Fragen</label>
                </div>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
            <div class="menu_item_container" (click)="openMenuItem(9)">
              <div class="menu_value_container">
                <img class="icon_data" src="../../../assets/contactgrey.svg">
                <label class="lbl_regular_16">Kontaktiere uns</label>
              </div>
              <img class="menu_icon_arraow" src="../../../assets/navarrow.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="data_wrapper" (click)="openMenuItem(7)">
        <div class="title_container" style="justify-content: space-between;">
          <div class="menu_value_container">
            <img class="icon_data" src="../../../assets/waymark.svg">
            <label class="lbl_regular_16">Über uns</label>
          </div>
          <img class="menu_icon_arraow menu_icon_last" style="padding-top: 0;"  src="../../../assets/navarrow.svg">
        </div>
      </div>
    </div>
  </div>

  <app-profile-telephone *ngIf="openView == 2"
    [form]="form"
    (updateEmitter)="updateUser()"
  ></app-profile-telephone>
  <app-profile-car *ngIf="openView == 3"
    [form]="form"
    (updateEmitter)="updateUser()"
  ></app-profile-car>
  <app-profile-detour *ngIf="openView == 4"
    [form]="form"
    (updateEmitter)="updateUser()"
  ></app-profile-detour>
  <app-profile-notification *ngIf="openView == 5"
    [form]="form"
    (updateEmitter)="updateUser()"
  ></app-profile-notification>
  <app-profile-change-password *ngIf="openView == 6"
    (udpatePasswordSuccessEmitter)="openView = 1"
  ></app-profile-change-password>
  <app-about-us *ngIf="openView == 7"></app-about-us>
  <app-faqs *ngIf="openView == 8"></app-faqs>
  <app-contact *ngIf="openView == 9"></app-contact>
  <app-agb *ngIf="openView == 10"></app-agb>
  <app-data-privacy *ngIf="openView == 11"></app-data-privacy>
</div>
