<div class="page_body">
  <div class="flexbox" [ngStyle]="{'justify-content': form.controls.offer_return.value == true ? 'space-between' : 'center'}">
    <div class="box_wrapper box_one">
      <label class="lbl_regular_20 title">Hinfahrt</label>
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/calenderwhite.svg">
        <div class="data_time">
          <label class="lbl_black_16">{{form.controls.time.value | date: 'EEEE, dd.MM.yyyy'}}</label>
          <label class="lbl_black_16">{{form.controls.time.value | date: 'HH:mm'}} Uhr</label>
        </div>
      </div>
      <div class="flex_space_between">
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/startwhite.svg">
          <label class="lbl_regular_16">{{form.controls.start.value.location_name}}</label>
        </div>
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/endwithe.svg">
          <label class="lbl_regular_16">{{form.controls.target.value.location_name}}</label>
        </div>
      </div>
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/detourwhite.svg">
        <label class="lbl_regular_16">{{form.controls.accept_detours.value ? 'Umwege sind erlaubt' : 'Keine Umwege'}}</label>
      </div>
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/repeat.svg" style="align-self: flex-start;">
        <div class="several_container">
          <label class="lbl_regular_16">{{getHowOften(form.controls.several_time_typ.value)}}</label>
          <div class="several_container" *ngIf="form.controls.several_time_typ.value == 2 || form.controls.several_time_typ.value == 3">
            <label class="lbl_regular_16">Letzte Fahrt: {{form.controls.last_trip.value | date: 'dd.MM.yyyy, HH:mm'}}</label>
          </div>
          <div class="several_container" *ngIf="form.controls.several_time_typ.value == 4">
            <label *ngFor="let timestamp of form.controls.customized_trips.value" class="lbl_regular_16">{{timestamp | date: 'dd.MM.yyyy, HH:mm'}}</label>
          </div>
        </div>
      </div>
      <button class="btn_white_transparent" (click)="openDialogEmitter.emit(false)">Hinfahrt mehrmals anbieten</button>
      <div class="box_footer">
        <label class="lbl_regular_16">{{isWorkway(form.controls.start.value.company_id, form.controls.target.value.company_id)}} {{form.controls.passenger_capacity.value}} {{form.controls.passenger_capacity.value == 1 ? 'Platz frei' : 'Plätze frei'}}</label>
        <div>
          <label class="lbl_regular_16">je </label>
          <label class="lbl_black_28">€ {{(form.controls.passenger_fee.value) | currency: 'EUR': '': '1.2-2'}}</label>
        </div>
      </div>
    </div>
    <div class="box_wrapper box_two" *ngIf="form.controls.offer_return.value == true">
      <label class="lbl_regular_20 title">Rückfahrt</label>
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/calenderwhite.svg">
        <div class="data_time">
          <label class="lbl_black_16">{{form.controls.return_time.value | date: 'EEEE, dd.MM.yyyy'}}</label>
          <label class="lbl_black_16">{{form.controls.return_time.value | date: 'HH:mm'}} Uhr</label>
        </div>
      </div>
      <div class="flex_space_between">
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/startwhite.svg">
          <label class="lbl_regular_16">{{form.controls.return_start.value.location_name}}</label>
        </div>
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/endwithe.svg">
          <label class="lbl_regular_16">{{form.controls.return_target.value.location_name}}</label>
        </div>
      </div>
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/detourwhite.svg">
        <label class="lbl_regular_16">{{form.controls.accept_detours.value ? 'Umwege sind erlaubt' : 'Keine Umwege'}}</label>
      </div>
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/repeat.svg" style="align-self: flex-start;">
        <div class="several_container">
          <label class="lbl_regular_16">{{getHowOften(form.controls.return_several_time_typ.value)}}</label>
          <div class="several_container" *ngIf="form.controls.return_several_time_typ.value == 2 || form.controls.return_several_time_typ.value == 3">
            <label class="lbl_regular_16">Letzte Fahrt: {{form.controls.return_last_trip.value | date: 'dd.MM.yyyy, HH:mm'}}</label>
          </div>
          <div class="several_container" *ngIf="form.controls.return_several_time_typ.value == 4">
            <label *ngFor="let timestamp of form.controls.return_customized_trips.value" class="lbl_regular_16">{{timestamp | date: 'dd.MM.yyyy, HH:mm'}}</label>
          </div>
        </div>
      </div>
      <button class="btn_white_transparent" (click)="openDialogEmitter.emit(true)">Rückfahrt mehrmals anbieten</button>
      <div class="box_footer">
        <label class="lbl_regular_16">{{isWorkway(form.controls.return_start.value.company_id, form.controls.return_target.value.company_id)}} {{form.controls.passenger_capacity.value}} {{form.controls.passenger_capacity.value == 1 ? 'Platz frei' : 'Plätze frei'}}</label>
        <div>
          <label class="lbl_regular_16">je </label>
          <label class="lbl_black_28">€ {{(form.controls.passenger_fee.value) | currency: 'EUR': '': '1.2-2'}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="btn_container">
    <button class="btn_submit" (click)="nexPage()">Fahrt anbieten</button>
  </div>
</div>


<!-- <div class="page_body">
  <div class="flexbox">
    <div class="drive_wrapper">
      <label class="lbl_regular_20">Hinfahrt</label>
      <div class="data_wrapper">
        <img src="">
        <label class="lbl_black_16">{{form.controls.time.value | date: 'DDD, dd.mm.yyyy, HH:mm'}}</label>
      </div>
      <div class="location_wrapper">
        <div class="data_wrapper">
          <img src="">
          <label class="lbl_regular_16">{{form.controls.start.value}}</label>
        </div>
        <div class="data_wrapper">
          <img src="">
          <label class="lbl_regular_16">{{form.controls.end.value}}</label>
        </div>
      </div>
      <div class="data_wrapper">
        <img src="">
        <label class="lbl_regular_16">{{form.controls.end.value}}</label>
      </div>
      <button class="btn_light">Hinfahrt mehrmals anbieten</button>
      <div class="footer">
        <label class="lbl_regular_16">Arbeitsweg {{form.controls.contribution_per_passenger.value}} Plätze frei</label>
        <div>
          <label class="lbl_regular_16">je</label>
          <label class="lbl_black_28">€ {{form.controls.contribution_per_passenger.value}}</label>
        </div>
      </div>
    </div>
    <div class="drive_wrapper"></div>
  </div>
  <button class="btn_submit" (click)="nexPage()">Fahrt anbieten</button>
</div> -->
