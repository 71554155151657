import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-detour',
  templateUrl: './profile-detour.component.html',
  styleUrls: ['./profile-detour.component.scss']
})
export class ProfileDetourComponent implements OnInit {
  @Input() form: FormGroup;
  public submitted = false;
  @Output() updateEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.submitted = true;
    if(this.form.controls.allowed_detour.invalid){
      return;
    } else {
      this.updateEmitter.emit();
    }
  }
}
