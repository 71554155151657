<div class="header_container">
  <div *ngIf="trip" class="button_container" (click)="backEmitter.emit()">
    <img class="icon_arrow" src="../../../../assets/navarrow.svg">
    <button *ngIf="screenWidth > 550" class="btn_label"></button>
  </div>
  <label class="lbl_medium_20">{{getTitle()}}</label>
  <!-- <div *ngIf="trip && !selectedPassenger && isCreatedTrip && showView != 5" class="button_container_2" (click)="openEditEmitter.emit()">
    <button class="btn_label">{{trip.passengers.length > 0 ? 'Stornieren' : 'Bearbeiten'}}</button>
  </div> -->
  <div *ngIf="trip && !selectedPassenger && showView != 5" class="button_container_2" (click)="stornoTripEmitter.emit()">
    <button class="btn_label">Stornieren</button>
  </div>
  <!-- <div *ngIf="trip && !selectedPassenger && !isCreatedTrip && showView != 5" class="button_container_2" (click)="stornoTripEmitter.emit()">
    <button class="btn_label">Stornieren</button>
  </div> -->
</div>
