import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
  @Input() screenWidth: number;
  @Output() backEmitter = new EventEmitter<undefined>();

  public form: FormGroup;
  public submitted = false;
  public success = false;

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  constructor(
    private formBulider: FormBuilder,
    private lService: LoginService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBulider.group({
      email: [null,[Validators.required]]
    })
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid){
      return;
    } else {
      this.lService.emailResetPassword(this.form.controls.email.value).subscribe((response) => {
        if(response){
          this.submitted = false;
          this.success = true;
        }
      })
    }
  }

  toLogin() {
    this.submitted = false;
    this.success = false;
    this.form.reset();
    this.backEmitter.emit();
  }
}
