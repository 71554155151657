import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-page-three',
  templateUrl: './page-three.component.html',
  styleUrls: ['./page-three.component.scss']
})
export class PageThreeComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Output() nextPageEmitter = new EventEmitter<undefined>();
  public dialogInfoSubscription: Subscription;
  public grenzWertFee: number;

  constructor(
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    this.grenzWertFee = Math.floor((0.05 * (this.form.controls.route.value.legs[0].distance.value / 1000) / 0.5)) * 0.5;
    if(this.grenzWertFee < 0.5) this.grenzWertFee = 0.5;
    this.form.controls.passenger_fee.setValue(this.grenzWertFee);
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogInfoSubscription) this.dialogInfoSubscription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogInfoSubscription = this.dService.closeDialogInfo$.subscribe((value) => {
      if(value){
        this.nextPageEmitter.emit();
      }
    });
  }

  isValid(): boolean {
    if((this.form.controls.passenger_capacity.value && this.form.controls.passenger_capacity.value < 10) && (this.form.controls.passenger_fee.value || this.form.controls.passenger_fee.value == 0)) return true;
    else return false;
  }

  nexPage() {
    if(this.form.controls.passenger_fee.value > this.grenzWertFee){
      this.dService.openInformation(
        {
          title: 'Für diesen Betrag benötigst du ein Gewerbe',
          //message: 'Bitte beachte, dass du bei einem Betrag über € ' + this.grenzWertFee + ' ein eingetragenes Gewerbe vorweisen musst.',
          message: 'Bitte beachte, dass du bei einem Betrag von € ' + this.form.controls.passenger_fee.value + ' ein eingetragenes Gewerbe vorweisen musst, ansonsten darf der Beitrag je Mitfahrer_in den gesetzlichen Betrag von € 0.05 je Kilometer nicht übersteigen',
          btn_submit_txt: 'Verstanden',
          typ: 'info',
          submit_value: null,
        }
      );
    } else {
      this.nextPageEmitter.emit();
    }
  }
}
