import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trip } from 'src/app/buisness-object/trip/Trip';

@Component({
  selector: 'app-search-result-element',
  templateUrl: './search-result-element.component.html',
  styleUrls: ['./search-result-element.component.scss']
})
export class SearchResultElementComponent implements OnInit {
  @Input() trip: Trip;
  @Output() bookTripEmitter = new EventEmitter<Trip>();

  constructor() { }

  ngOnInit(): void {
  }

  getArray(i: number): any {
    return new Array(i);
  }
}
