<div class="view_body">
  <div class="detour_form">
    <Label class="lbl_regular_22 lbl_active">Suche dir deinen alternativen Ein- und/oder Ausstiegsort</Label>
    <div class="data_body">
      <div class="row_container">
        <div class="checkbox_container">
          <div class="checkbox_value_wrapper">
            <img class="icon_data" src="../../../../assets/start.svg">
            <div class="checkbox_lbl_wrapper">
              <label class="lbl_regular_16">Einstiegsort</label>
              <label class="lbl_regular_12">Du möchtest an einem alternativen Ort abgeholt werden</label>
            </div>
          </div>
          <div class="checkbox" [ngClass]="entryPoint ? 'checkbox_checked' : ''" (click)="checkboxAction(1)">
            <img *ngIf="entryPoint" src="../../../../assets/successhue.svg">
          </div>
        </div>
        <div class="input_container_one" [ngClass]="entryPoint ? 'input_container_open' : ''">
          <div class="inputfield_container" >
            <input #startAddress class="inputfield" type="text" placeholder="Start" (input)="this.form.controls.detour_start.setValue(null); getPlaces(startAddress.value, 1);">
            <div class="input_valid_indicator" [ngClass]="!form.controls.detour_start.value ? 'input_valid_indicator_invalid' : ''"></div>
          </div>
        </div>
        <div *ngIf="showDropdownOne" class="dropdown_cotainer">
          <div id="dropdown" class="dropdown_scroll_wrapper">
            <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''"  (click)="setLocation(place, 1)">
              <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
              <div>{{place.full_name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row_container">
        <div class="checkbox_container">
          <div class="checkbox_value_wrapper">
            <img class="icon_data" src="../../../../assets/end.svg">
            <div class="checkbox_lbl_wrapper">
              <label class="lbl_regular_16">Austiegsort</label>
              <label class="lbl_regular_12">Du möchtest an einem alternativen Ort aussteigen</label>
            </div>
          </div>
          <div class="checkbox" [ngClass]="targetPoint ? 'checkbox_checked' : ''" (click)="checkboxAction(2)">
            <img *ngIf="targetPoint" src="../../../../assets/successhue.svg">
          </div>
        </div>
        <div class="input_container_one" [ngClass]="targetPoint ? 'input_container_open' : ''">
          <div class="inputfield_container" >
            <input #targetAddress class="inputfield" type="text" placeholder="Ziel" (input)="this.form.controls.detour_target.setValue(null); getPlaces(targetAddress.value, 2);">
            <div class="input_valid_indicator" [ngClass]="!form.controls.detour_target.value ? 'input_valid_indicator_invalid' : ''"></div>
          </div>
        </div>
        <div *ngIf="showDropdownTwo" class="dropdown_cotainer">
          <div id="dropdown" class="dropdown_scroll_wrapper">
            <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''" (click)="setLocation(place, 2)">
              <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
              <div>{{place.full_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <button class="btn_submit" [ngClass]="form.controls.detour_start.value || form.controls.detour_target.value ? '' : 'btn_submit_inactive'" [disabled]="!form.controls.detour_start.value && !form.controls.detour_target.value" (click)="submit()">Fahrt buchen</button>
    </div>
  </div>
</div>
