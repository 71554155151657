<div id="dialog_window">
  <div class="close_icon" (click)="closeDialogEmitter.emit()" (keydown.enter)="$event.preventDefault()">&#10005;</div>
  <form id="dialog_container">
    <label class="lbl_medium_22 lbl_active title">An welchen Tagen findet diese Fahrt statt?</label>
    <div class="body">
      <div class="year_wrapper">
        <img *ngIf="isNotTodaysMonth(month[0])" class="icon_arrow_year_left" src="../../../../assets/navarrow.svg" (click)="previousMonth(month[0])">
        <Label class="lbl_medium_16">{{month[0] | date: 'MMMM YYYY'}}</Label>
        <img class="icon_arrow_year_right" style="transform: rotate(180deg);" src="../../../../assets/navarrow.svg" (click)="nextMonth(month[0])">
      </div>
      <div class="week_day_wrappper">
        <Label class="week_day lbl_regular_12 lbl_active">MO</Label>
        <Label class="week_day lbl_regular_12 lbl_active">DI</Label>
        <Label class="week_day lbl_regular_12 lbl_active">MI</Label>
        <Label class="week_day lbl_regular_12 lbl_active">DO</Label>
        <Label class="week_day lbl_regular_12 lbl_active">FR</Label>
        <Label class="week_day lbl_regular_12 lbl_active">SA</Label>
        <Label class="week_day lbl_regular_12 lbl_active">SO</Label>
      </div>
      <div class="days_container">
        <div class="day_wrapper">
          <div *ngFor="let lastMonthDay of lastMonthDays"></div>
          <div *ngFor="let day of month" (click)="chosenDates.length < maxAmountOfDate ? selectDateAction(day) : null"
            class="day"
            [ngClass]="isSelectedDay(day) ? 'day_enable day_selected' : (isInThePast(day) ? 'day_disabled' : 'day_enable')">
            <Label class="day_date lbl_regular_14" [ngClass]="isSelectedDay(day) ? 'day_enable lbl_active' : (isInThePast(day) ? 'lbl_light' : 'day_enable')">
              {{day | date: 'dd'}}
            </Label>
            <Label *ngIf="isSelectedDay(day)" class="day_time day_enable lbl_regular_8">
              {{getTime(day) | date: 'HH:mm'}}
            </Label>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container_time">
      <button type="button" class="btn_gray lbl_black_16" (click)="closeDialogEmitter.emit()">Zurück</button>
      <button type="button" class="btn_submit lbl_black_16" [ngClass]="!isValidTime() ? 'btn_submit_inactive' : ''" [disabled]="!isValidTime()" (click)="onSubmit()" (click)="onSubmit()">Speichern</button>
    </div>
  </form>
</div>
<app-dialog-time *ngIf="showTimePicker"
  [selectedDate]="selectedDate"
  [isEdit]="isEditDate"
  (nextEmitter)="setTime($event)"
  (backEmitter)="closeTimeDialog()"
  (removeEmitter)="removeDate()"
></app-dialog-time>
