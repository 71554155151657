<div class="view_body">
  <div class="grid">
    <div class="faq_container" *ngFor="let faq of faqs">
      <div class="faq_title_container">
        <img class="icon" src="../../../../assets/faq.svg">
        <label class="lbl_regular_16 lbl_active">{{faq.title}}</label>
      </div>
      <label class="lbl_regular_14">{{faq.content}}</label>
    </div>
  </div>
</div>
