import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-profile-notification',
  templateUrl: './profile-notification.component.html',
  styleUrls: ['./profile-notification.component.scss']
})
export class ProfileNotificationComponent implements OnInit {
  @Input() form: FormGroup;
  public submitted = false;
  @Output() updateEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.updateEmitter.emit();
  }
}
