import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import '@angular/common/locales/global/de';
import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { ResgisterFormComponent } from './view/login/resgister-form/resgister-form.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './view/sidebar/sidebar.component';
import { ForgotPasswordFormComponent } from './view/login/forgot-password-form/forgot-password-form.component';
import { DashboardHeaderComponent } from './view/dashboard/dashboard-header/dashboard-header.component';
import { LoadingComponent } from './view/loading/loading.component';
import { LoadingScreenInterceptor } from './service/loading/LoadingInterceptor';
import { DialogNotificationComponent } from './view/dialog/dialog-notification/dialog-notification.component';
import { DialogQueryComponent } from './view/dialog/dialog-query/dialog-query.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';
import { ProfileComponent } from './view/profile/profile.component';
import { ProfileHeaderComponent } from './view/profile/profile-header/profile-header.component';
import { VerificationComponent } from './view/verification/verification.component';
import { CreateDriveComponent } from './view/create-drive/create-drive.component';
import { MyDrivesComponent } from './view/my-drives/my-drives.component';
import { ProfileTelephoneComponent } from './view/profile/profile-telephone/profile-telephone.component';
import { ProfileCarComponent } from './view/profile/profile-car/profile-car.component';
import { ProfileChangePasswordComponent } from './view/profile/profile-change-password/profile-change-password.component';
import { ProfileNotificationComponent } from './view/profile/profile-notification/profile-notification.component';
import { ProfileDetourComponent } from './view/profile/profile-detour/profile-detour.component';
import { AboutUsComponent } from './view/profile/about-us/about-us.component';
import { FaqsComponent } from './view/profile/faqs/faqs.component';
import { ContactComponent } from './view/profile/contact/contact.component';
import { AgbComponent } from './view/profile/agb/agb.component';
import { DataPrivacyComponent } from './view/profile/data-privacy/data-privacy.component';
import { CreateDriveHeaderComponent } from './view/create-drive/create-drive-header/create-drive-header.component';
import { PageOneComponent } from './view/create-drive/page-one/page-one.component';
import { PageTwoComponent } from './view/create-drive/page-two/page-two.component';
import { PageThreeComponent } from './view/create-drive/page-three/page-three.component';
import { PageFourComponent } from './view/create-drive/page-four/page-four.component';
import { DialogInfoComponent } from './view/dialog/dialog-info/dialog-info.component';
import { PageFiveComponent } from './view/create-drive/page-five/page-five.component';
import { PageSixComponent } from './view/create-drive/page-six/page-six.component';
import { DialogCalendarComponent } from './view/dialog/dialog-calendar/dialog-calendar.component';
import { MyDrivesHeaderComponent } from './view/my-drives/my-drives-header/my-drives-header.component';
import { DriveListElementComponent } from './view/my-drives/drive-list-element/drive-list-element.component';
import { HighscoreComponent } from './view/dashboard/highscore/highscore.component';
import { MyDrivesDetailsComponent } from './view/my-drives/my-drives-details/my-drives-details.component';
import { UserProfileComponent } from './view/my-drives/user-profile/user-profile.component';
import { ChatComponent } from './view/my-drives/chat/chat.component';
import { DialogSeveralTimesComponent } from './view/dialog/dialog-several-times/dialog-several-times.component';
import { DialogTimeComponent } from './view/dialog/dialog-time/dialog-time.component';
import { DialogInputCalenderComponent } from './view/dialog/dialog-input-calender/dialog-input-calender.component';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { SearchResultsComponent } from './view/dashboard/search-results/search-results.component';
import { SearchResultElementComponent } from './view/dashboard/search-results/search-result-element/search-result-element.component';
import { RequestDetourComponent } from './view/dashboard/request-detour/request-detour.component';
import { RequestSuccessComponent } from './view/dashboard/request-success/request-success.component';
import { EditDriveComponent } from './view/my-drives/edit-drive/edit-drive.component';
import { DialogSuccessComponent } from './view/dialog/dialog-success/dialog-success.component';
import { DialogDateLastComponent } from './view/dialog/dialog-date-last/dialog-date-last.component';
import { CustomDateFormat1Directive } from './directive/customDateFormat1/custom-date-format1.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ResgisterFormComponent,
    SidebarComponent,
    ForgotPasswordFormComponent,
    DashboardHeaderComponent,
    LoadingComponent,
    DialogNotificationComponent,
    DialogQueryComponent,
    ResetPasswordComponent,
    ProfileComponent,
    ProfileHeaderComponent,
    VerificationComponent,
    CreateDriveComponent,
    MyDrivesComponent,
    ProfileTelephoneComponent,
    ProfileCarComponent,
    ProfileChangePasswordComponent,
    ProfileNotificationComponent,
    ProfileDetourComponent,
    AboutUsComponent,
    FaqsComponent,
    ContactComponent,
    AgbComponent,
    DataPrivacyComponent,
    CreateDriveHeaderComponent,
    PageOneComponent,
    PageTwoComponent,
    PageThreeComponent,
    PageFourComponent,
    DialogInfoComponent,
    PageFiveComponent,
    PageSixComponent,
    DialogSeveralTimesComponent,
    DialogCalendarComponent,
    MyDrivesHeaderComponent,
    DriveListElementComponent,
    HighscoreComponent,
    MyDrivesDetailsComponent,
    UserProfileComponent,
    ChatComponent,
    DialogTimeComponent,
    DialogInputCalenderComponent,
    SearchResultsComponent,
    SearchResultElementComponent,
    RequestDetourComponent,
    RequestSuccessComponent,
    EditDriveComponent,
    DialogSuccessComponent,
    DialogDateLastComponent,
    CustomDateFormat1Directive
  ],
  imports: [
    //CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    BrowserAnimationsModule,
    GoogleMapsModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
  },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
