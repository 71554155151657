import { GlobalVariables } from "src/app/helper/Globavraibles";
import { UserFactory } from "../../user/factory/UserFactory";
import { User } from "../../user/User";
import { Trip } from "../Trip";

export class TripFactory {
  static jsonFactory(rawData: any): Trip[] {
    let datas: Trip[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): Trip {
      let trip = new Trip(
          rawData.trip_id,
          rawData.polyline,
          rawData.timestamp,
          rawData.start_description,
          rawData.start_latitude,
          rawData.start_longitude,
          rawData.target_description,
          rawData.target_latitude,
          rawData.target_longitude,
          rawData.distance,
          rawData.duration,
          rawData.accept_detours,
          rawData.passenger_capacity,
          rawData.passenger_fee,
          rawData.passengers ? UserFactory.jsonFactoryWithStatus(rawData.passengers) : [],
          rawData.driver ? UserFactory.jsonFactoryOne(rawData.driver) : null,
          rawData.co2_footprint,
          rawData.detour_start_description,
          rawData.detour_target_description,
          rawData.status,
      );
      trip.requestPending = this.getRequestIsPending(trip.passengers);
      return trip;
  }

  static getRequestIsPending(passengers: User[]): boolean {
    for(let passenger of passengers){
      if(passenger?.status == 0){
        GlobalVariables.amount_of_pending_requests = GlobalVariables.amount_of_pending_requests + 1;
        return true;
      }
    }
    return false;
  }
}
