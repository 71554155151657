import { Faq } from "../Faq";

export class FaqFactory {
  static jsonFactory(rawData: any): Faq[] {
    let datas: Faq[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): Faq {
      return new Faq(
          rawData.faq_id,
          rawData.title,
          rawData.content,
      );
  }
}
