import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { Subscription } from 'rxjs';
import { User } from './../../buisness-object/user/User';
import { Trip } from './../../buisness-object/trip/Trip';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateTrip, SubTrip, Tour } from 'src/app/buisness-object/trip/CreateTrip';
import { TripService } from 'src/app/service/trip/trip.service';
import { UserService } from 'src/app/service/user/user.service';
import moment from 'moment';

@Component({
  selector: 'app-create-drive',
  templateUrl: './create-drive.component.html',
  styleUrls: ['./create-drive.component.scss']
})
export class CreateDriveComponent implements OnInit, OnDestroy {
  public page = 1;
  public editPage = 1;
  public showDialogSeveral = false;
  public dialog = 1;
  public form: FormGroup;
  public submitted = false;
  public isReturnTrip = false;
  public showTemplates = false;
  public templates: Trip[] = [];
  public selectedTemplate: Trip;
  public createdTrip: Trip;
  public user: User;
  public formSubscription: Subscription;
  public showTemplateEdit = false;

  constructor(
    private formBuilder: FormBuilder,
    private tService: TripService,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.uService.getUser(Number(localStorage.getItem('id'))).subscribe((result) => {
      if(result){
        this.templates = result.templates;
        this.templates.sort((a,b) => {
          if(a.passenger_fee > b.passenger_fee) return 1;
          if(a.passenger_fee < b.passenger_fee) return -1;
          return 0;
        })
        this.user = result.user;
      }
    })
    this.configForm();
  }

  onUnload = e => {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.onUnload);
    if(this.formSubscription) this.formSubscription.unsubscribe();
  }

  configForm() {
    this.form = this.formBuilder.group({
      start: [null,[Validators.required]],
      target: [null,[Validators.required]],
      time: [null,[Validators.required]],
      route: [null,[Validators.required]],
      accept_detours: [false,[Validators.required]],
      passenger_capacity: [null,[Validators.required]],
      passenger_fee: [null,[Validators.required]],
      offer_return: [false,[Validators.required]],
      return_start: [new FormControl({value: null, disabled: true}),[Validators.required]],
      return_target: [new FormControl({value: null, disabled: true}),[Validators.required]],
      return_time: [new FormControl({value: null, disabled: true}),[Validators.required]],
      several_time_typ: [1],
      return_several_time_typ: [1],
      last_trip: [null],
      return_last_trip: [null],
      customized_trips: [null],
      return_customized_trips: [null],
      return_route: [null]
    });
    moment.locale('de');
    this.form.controls.time.setValue(new Date());
    this.form.controls.passenger_fee.setValue(1);
    this.formSubscription = this.form.valueChanges.subscribe(change => {
      GlobalVariables.is_create_process = true;
      window.addEventListener('beforeunload', this.onUnload);
      this.formSubscription.unsubscribe();
    })
  }

  nextPage() {
    this.page++;
  }

  backAction() {
    if(this.showTemplateEdit && this.editPage == 2){
      this.editPage = 1;
    } else if(this.showTemplateEdit && this.editPage == 3){
      this.editPage = 2;
    } else if(this.showTemplateEdit && this.editPage == 1){
      this.showTemplateEdit = false;
      this.showTemplates = true;
    } else if(this.showTemplateEdit){
      this.selectedTemplate = null;
      this.showTemplateEdit = false;
      this.showTemplates = true;
    }else if(this.showTemplates){
      this.selectedTemplate = null;
      this.showTemplateEdit = false;
      this.showTemplates = false;
    } else {
      this.page--;
    }
  }

  cancelAction() {
    this.form.reset();
    this.configForm();
    this.createdTrip = null;
    this.submitted = false;
    this.page = 1;
  }

  createTrip(tripTemplate?: Trip) {
    let trip: CreateTrip;
    if(!tripTemplate) {
      let trips: Tour[] = [];
      let subTrips: SubTrip[] = [];
      let toTrip = new SubTrip(
        1,
        this.form.controls.route.value.overview_polyline,
        this.form.controls.route.value.legs[0].distance.value,
        this.form.controls.route.value.legs[0].duration.value,
        this.form.controls.start.value.location_name,
        this.form.controls.start.value.latitude,
        this.form.controls.start.value.longitude,
        this.form.controls.target.value.location_name,
        this.form.controls.target.value.latitude,
        this.form.controls.target.value.longitude,
      );
      subTrips.push(toTrip)
      trips = this.getTripsTime(toTrip.sid);
      if(this.form.controls.offer_return.value){
        let backTrip = new SubTrip(
          2,
          this.form.controls.return_route.value.overview_polyline,
          this.form.controls.return_route.value.legs[0].distance.value,
          this.form.controls.return_route.value.legs[0].duration.value,
          this.form.controls.return_start.value.location_name,
          this.form.controls.return_start.value.latitude,
          this.form.controls.return_start.value.longitude,
          this.form.controls.return_target.value.location_name,
          this.form.controls.return_target.value.latitude,
          this.form.controls.return_target.value.longitude,
        );
        subTrips.push(backTrip);
        trips = trips.concat(this.getTripsTimeReturn(backTrip.sid));
      }
      trip = new CreateTrip(
        Number(localStorage.getItem('id')),
        this.form.controls.passenger_capacity.value,
        this.form.controls.passenger_fee.value * 100,
        this.form.controls.accept_detours.value,
        subTrips,
        trips
      );
    } else {
      let trips: Tour[] = [];
      let subTrips: SubTrip[] = [];
      let toTrip = new SubTrip(
        1,
        tripTemplate.polyline,
        tripTemplate.distance,
        tripTemplate.duration,
        tripTemplate.start_description,
        tripTemplate.start_latitude,
        tripTemplate.start_longitude,
        tripTemplate.target_description,
        tripTemplate.target_latitude,
        tripTemplate.target_longitude,
      );
      subTrips.push(toTrip)
      trips.push(new Tour(1, [tripTemplate.timestamp]));
      trip = new CreateTrip(
        Number(localStorage.getItem('id')),
        tripTemplate.passenger_capacity,
        tripTemplate.passenger_fee * 100,
        tripTemplate.accept_detours,
        subTrips,
        trips
      );
    }
    this.tService.createTrip(trip).subscribe((result) => {
      if(result){
        if(this.form.controls.offer_return.value == true){
          let amountOfTripsForward = trip.trips[0].timestamps.length;
          let amountOfTripsReturn = trip.trips[1].timestamps.length;
          this.createdTrip = result[result.length - amountOfTripsForward - amountOfTripsReturn];
        } else {
          let amountOfTripsForward = trip.trips[0].timestamps.length;
          this.createdTrip = result[result.length - amountOfTripsForward];
        }
        this.selectedTemplate = null;
        this.showTemplates = false;
        this.showTemplateEdit = false;
        GlobalVariables.is_create_process = false;
        this.page = 6;
      }
    })
  }

  getTripsTime(sid: number): Tour[] {
    let tours: Tour[] = [];
    let timestamps: number[] = [];
    if(this.form.controls.several_time_typ.value == 1){
      //einmalig
      timestamps.push(this.form.controls.time.value.getTime())
      let tour = new Tour(sid,timestamps);
      tours.push(tour)
    } else if(this.form.controls.several_time_typ.value == 2){
      //täglich
      let diff = Math.abs(this.form.controls.time.value.getTime() - this.form.controls.last_trip.value.getTime());
      let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      let year = this.form.controls.time.value.getFullYear();
      let month = this.form.controls.time.value.getMonth();
      let day = this.form.controls.time.value.getDate();
      let hour = this.form.controls.time.value.getHours();
      let min = this.form.controls.time.value.getMinutes();
      for(let i = 0; i <= diffDays; i++){
        let date = new Date(year, month, day + i);
        date.setHours(hour);
        date.setMinutes(min);
        timestamps.push(date.getTime());
      }
      tours.push(new Tour(sid, timestamps));
    } else if(this.form.controls.several_time_typ.value == 3){
      //werktags
      let diff = Math.abs(this.form.controls.time.value.getTime() - this.form.controls.last_trip.value.getTime());
      let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      let year = this.form.controls.time.value.getFullYear();
      let month = this.form.controls.time.value.getMonth();
      let day = this.form.controls.time.value.getDate();
      let hour = this.form.controls.time.value.getHours();
      let min = this.form.controls.time.value.getMinutes();
      for(let i = 0; i <= diffDays; i++){
        let date = new Date(year, month, day + i);
        date.setHours(hour);
        date.setMinutes(min);
        if(date.getDay() != 6 && date.getDay() != 0) timestamps.push(date.getTime());
      }
      tours.push(new Tour(sid, timestamps));
    } else if(this.form.controls.several_time_typ.value == 4){
      //benutzerdefiniert
      timestamps.push(this.form.controls.time.value.getTime())
      for(let date of this.form.controls.customized_trips.value){
        timestamps.push(date.getTime());
      }
      tours.push(new Tour(sid, timestamps));
    }
    return tours;
  }
  getTripsTimeReturn(sid: number): Tour[] {
    let tours: Tour[] = [];
    let timestamps: number[] = [];
    if(this.form.controls.return_several_time_typ.value == 1){
      //einmalig
      timestamps.push(this.form.controls.return_time.value.getTime())
      let tour = new Tour(sid,timestamps);
      tours.push(tour)
    } else if(this.form.controls.return_several_time_typ.value == 2){
      //täglich
      let diff = Math.abs(this.form.controls.return_time.value.getTime() - this.form.controls.return_last_trip.value.getTime());
      let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      let year = this.form.controls.return_time.value.getFullYear();
      let month = this.form.controls.return_time.value.getMonth();
      let day = this.form.controls.return_time.value.getDate();
      let hour = this.form.controls.return_time.value.getHours();
      let min = this.form.controls.return_time.value.getMinutes();
      for(let i = 0; i < diffDays; i++){
        let date = new Date(year, month, day + i);
        date.setHours(hour);
        date.setMinutes(min);
        timestamps.push(date.getTime());
      }
      tours.push(new Tour(sid, timestamps));
    } else if(this.form.controls.return_several_time_typ.value == 3){
      //werktags
      let diff = Math.abs(this.form.controls.return_time.value.getTime() - this.form.controls.return_last_trip.value.getTime());
      let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      let year = this.form.controls.return_time.value.getFullYear();
      let month = this.form.controls.return_time.value.getMonth();
      let day = this.form.controls.return_time.value.getDate();
      let hour = this.form.controls.return_time.value.getHours();
      let min = this.form.controls.return_time.value.getMinutes();
      for(let i = 0; i < diffDays; i++){
        let date = new Date(year, month, day + i);
        date.setHours(hour);
        date.setMinutes(min);
        if(date.getDay() != 6 && date.getDay() != 0) timestamps.push(date.getTime());
      }
      tours.push(new Tour(sid, timestamps));
    } else if(this.form.controls.return_several_time_typ.value == 4){
      //benutzerdefiniert
      timestamps.push(this.form.controls.return_time.value.getTime())
      for(let date of this.form.controls.return_customized_trips.value){
        timestamps.push(date.getTime());
      }
      tours.push(new Tour(sid, timestamps));
    }
    return tours;
  }

  openDialog(isReturnTrip: boolean) {
    this.isReturnTrip = isReturnTrip;
    this.showDialogSeveral = true;
  }

  submitDialogServeralTimes() {
    let typ = this.isReturnTrip ? this.form.controls.return_several_time_typ.value : this.form.controls.several_time_typ.value;
    if(typ == 1){
      this.form.controls.last_trip.setValue(null);
      this.form.controls.return_last_trip.setValue(null);
      this.form.controls.customized_trips.setValue(null);
      this.form.controls.return_customized_trips.setValue(null);
      this.showDialogSeveral = false;
    } else if(typ == 2) this.dialog = 2;
    else if(typ == 3) this.dialog = 2;
    else if(typ == 4) this.dialog = 3;
  }
  submitDialogDateLast() {
    this.showDialogSeveral = false;
    this.dialog = 1;
  }
  submitDialogCalendar(dates: Date[]) {
    if(this.isReturnTrip) this.form.controls.return_customized_trips.setValue(dates);
    else this.form.controls.customized_trips.setValue(dates);
    this.showDialogSeveral = false;
    this.dialog = 1;
  }

  closeDialog(dialog: number) {
    if(dialog == 1){
      let typ = this.isReturnTrip ? this.form.controls.return_several_time_typ.value : this.form.controls.several_time_typ.value;
      if(typ == 1 && this.isReturnTrip){
        this.form.controls.return_last_trip.setValue(null);
        this.form.controls.return_customized_trips.setValue(null);
        this.form.controls.return_several_time_typ.setValue(1);
      } else if(typ == 1 && !this.isReturnTrip){
        this.form.controls.last_trip.setValue(null);
        this.form.controls.customized_trips.setValue(null);
        this.form.controls.several_time_typ.setValue(1);
      } else {
        if((typ == 2 || typ == 3) && this.isReturnTrip && !this.form.controls.return_last_trip.value) this.form.controls.return_several_time_typ.setValue(1);
        if((typ == 2 || typ == 3) && !this.isReturnTrip && !this.form.controls.last_trip.value) this.form.controls.several_time_typ.setValue(1);
        if(typ == 4 && this.isReturnTrip && !this.form.controls.return_customized_trips.value) this.form.controls.return_several_time_typ.setValue(1);
        if(typ == 4 && !this.isReturnTrip && !this.form.controls.customized_trips.value) this.form.controls.several_time_typ.setValue(1);
      }
      this.showDialogSeveral = false;
    } else {
      this.dialog = 1;
    }
  }

  openTemplate(trip: Trip){
    this.showTemplates = false;
    this.selectedTemplate = trip;
    this.showTemplateEdit = true;
  }

  // onResize(e) {
  //   this.screenWidth = GlobalVariables.window_width;
  // }
}
