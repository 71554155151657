<div class="page_body">
  <div class="flexbox">
    <div class="box_wrapper box_one">
      <div>
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/calenderwhite.svg">
          <div class="data_time">
            <label class="lbl_black_16">{{trip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
            <label class="lbl_black_16">{{trip.timestamp | date: 'HH:mm'}} Uhr</label>
          </div>
        </div>
        <div class="flex_space_between">
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/startwhite.svg">
            <label class="lbl_regular_16">{{trip.start_description}}</label>
          </div>
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/endwithe.svg">
            <label class="lbl_regular_16">{{trip.target_description}}</label>
          </div>
        </div>
      </div>
      <label class="lbl_black_16">Deine nächste Fahrt.</label>
    </div>
    <div class="box_wrapper box_two" style="align-items: center">
      <label class="lbl_black_80">{{trip.getCo2InKg() | number: '1.2-2'}} kg</label>
      <label class="lbl_medium_18" style="text-align: center;">CO<sub>2</sub>-Ersparnis auf dieser Fahrt bei Vollbesetzung</label>
    </div>
  </div>
  <div class="btn_container">
    <button class="btn_submit" (click)="nexPage()">Fertig</button>
  </div>
</div>
