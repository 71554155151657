<div class="view_body">
  <div class="flexbox">
    <div class="box_wrapper box_one">
      <div>
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/calenderwhite.svg">
          <label class="lbl_black_16">{{trip.timestamp | date: 'MMMM, dd.MM.yyyy HH:mm'}}</label>
        </div>
        <div class="flex_space_between">
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/startwhite.svg">
            <label class="lbl_regular_16">{{trip.start_description}}</label>
          </div>
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/endwithe.svg">
            <label class="lbl_regular_16">{{trip.target_description}}</label>
          </div>
        </div>
      </div>
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/drivercar.svg">
        <label class="lbl_regular_16">{{trip.getVehicle()}}</label>
      </div>
      <div *ngIf="trip.detour_request_start">
        <Label class="lbl_regular_14 lbl_white">Du möchtest hier zusteigen:</Label>
        <div class="data_wrapper" style="align-items: center;">
          <img class="data_icon" src="../../../../assets/startnew.svg">
          <label class="lbl_regular_16 lbl_white">{{trip.detour_request_start}}</label>
        </div>
      </div>
      <div *ngIf="trip.detour_request_target">
        <Label class="lbl_regular_14 lbl_white">Du möchtest hier aussteigen:</Label>
        <div class="data_wrapper" style="align-items: center;">
          <img class="data_icon" src="../../../../assets/endwithe.svg">
          <label class="lbl_regular_16 lbl_white">{{trip.detour_request_target}}</label>
        </div>
      </div>
    </div>
    <div class="box_wrapper box_two" style="align-items: center">
      <label class="lbl_black_80">{{trip.getCo2InKg() | number: '1.2-2'}} kg</label>
      <label class="lbl_medium_18" style="text-align: center;">CO<sub>2</sub>-Ersparnis auf dieser Fahrt bei Vollbesetzung</label>
    </div>
  </div>
  <div class="btn_container">
    <button class="btn_submit" (click)="goBackToDashboardEmitter.emit()">Fertig</button>
  </div>
</div>
