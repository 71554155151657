<div id="dialog_window" *ngIf="show">
  <div class="close_icon" (click)="show = false">&#10005;</div>
  <form id="dialog_container">
    <label class="lbl_medium_22 lbl_active">{{title}}</label>
    <label class="lbl_regular_14">{{message}}
      <div *ngIf="typ && typ == 'info_login'" id="dialog_login_info_link_wrapper">
        <a href="https://play.google.com/store/apps/details?id=at.waymark.waymark&pli=1">Download Android</a>
        <a href="https://apps.apple.com/at/app/hey-way/id1276089411">Download IOS</a>
      </div>
    </label>
    <div class="btn_container">
      <button class="btn_submit lbl_black_16" (click)="onSubmit()">{{btnSubmitTxt}}</button>
    </div>
  </form>
</div>
