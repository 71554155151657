import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { MapService } from 'src/app/service/map/map.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-my-drives-details',
  templateUrl: './my-drives-details.component.html',
  styleUrls: ['./my-drives-details.component.scss']
})
export class MyDrivesDetailsComponent implements OnInit, AfterViewInit {
  @Input() trip: Trip;
  @Input() user: User;
  @Input() tab: number;
  @Output() openProfileEmitter = new EventEmitter<User>();
  @Output() openChatEmitter = new EventEmitter<User>();
  @Output() openEditEmitter = new EventEmitter<undefined>();
  @Output() acceptOrRefuseEmitter = new EventEmitter<{trip_id: number, user_id: number, accept: boolean}>();
  public isCreatedTrip = true;
  public passengers: User[] = [];
  public isTablet = GlobalVariables.is_tablet_device;
  public isPhone = GlobalVariables.is_phone_device;
  public isLoaded = false;
  public passenger_capicity = 0;

  constructor(
    private uService: UserService,
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    if(this.trip.driver && this.trip.driver.user_id != Number(localStorage.getItem('id'))){
      this.isCreatedTrip = false;
      this.user = this.trip.driver;
    }
    this.getFreePlaces();
    this.getDateServerside();
  }

  ngAfterViewInit(): void {
    if(window.screen.width > 1150 || !this.isCreatedTrip){
      this.getRoutes();
    }
  }

  getFreePlaces() {
    this.passenger_capicity = this.trip.passenger_capacity;
    for(let passenger of this.trip.passengers){
      if(passenger.status == 1){
        this.passenger_capicity--;
      }
    }
  }

  async getDateServerside() {
    this.uService.getAvatar(this.user.user_id).subscribe((avatar) => {
      if(avatar) this.user.avatar = avatar.data;
    })
    for(let passenger of this.trip.passengers){
      passenger = await this.getPassengerAvatar(passenger);
    }
    this.isLoaded = true;
  }

  getRoutes() {
    let start = new google.maps.LatLng(this.trip.start_latitude,this.trip.start_longitude);
    let target = new google.maps.LatLng(this.trip.target_latitude,this.trip.target_longitude);
    this.mapService.initializeMap(start,target,'map').then((result) => {});
  }

  getPassengerAvatar(user: User): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.uService.getAvatar(user.user_id).subscribe((avatar) => {
        if(avatar){
          user.avatar = avatar.data;
          resolve(user)
        }
      })
    })
  }

  getArray(i: number): any {
    return new Array(i);
  }

  openProfile(passenger: User) {
    this.openProfileEmitter.emit(passenger);
  }

  acceptOrRefusePassenger(userId: number, accept: boolean) {
    if(accept){
      this.uService.confirmPassenger(this.trip.trip_id, userId).subscribe((response) => {
        if(response){
          let index = this.trip.passengers.findIndex(p => p.user_id == userId);
          if(index > -1) this.trip.passengers[index].status = 1;
          this.getFreePlaces();
          this.acceptOrRefuseEmitter.emit({ trip_id: this.trip.trip_id, user_id: userId, accept: true});
        }
      })
    } else {
      this.uService.refusePassenger(this.trip.trip_id, userId).subscribe((response) => {
        if(response){
          let index = this.trip.passengers.findIndex(p => p.user_id == userId);
          if(index > -1) {
            this.trip.passengers.splice(index, 1);
          }
          this.acceptOrRefuseEmitter.emit({ trip_id: this.trip.trip_id, user_id: userId, accept: false})
        }
      })
    }
  }

  goToTab(tab: number) {
    this.tab = tab;
    if(tab == 3){
      setTimeout(() => {
        this.getRoutes();
      }, 100);
    }
  }

  openChat(user: User) {
    if(this.isPhone || this.isTablet){
      this.openChatEmitter.emit(user);
    } else {
      this.openProfile(user);
    }
  }
}
