<form class="page_body" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <label class="lbl_medium_22 lbl_active">Erstelle deine Rückfahrt</label>
  <img class="icon" src="../../../../assets/repeatride.svg">
  <div class="checkbox_container">
    <label class="lbl_regular_16">Rückfahrt anbieten</label>
    <div class="checkbox" [ngClass]="form.controls.offer_return.value ? 'checkbox_checked' : ''" (click)="form.controls.offer_return.setValue(!form.controls.offer_return.value)">
      <img *ngIf="form.controls.offer_return.value == true" src="../../../../assets/successhue.svg">
    </div>
  </div>
  <div class="input_container">
    <div class="input_container_one">
      <img class="input_container_one_icon" src="../../../assets/start.svg">
      <div class="inputfield_container" [ngClass]="form.controls.offer_return.value == false ? 'input_disabled' : ''">
        <input #startAddress class="inputfield" type="text" placeholder="Start" [disabled]="form.controls.offer_return.value == false" (input)="this.form.controls.return_start.setValue(null); getPlaces(startAddress.value, 1);" (focus)="showDropdownOne = true">
        <div class="input_valid_indicator" [ngClass]="!form.controls.return_start.value ? 'input_valid_indicator_invalid' : ''"></div>
        <div *ngIf="showDropdownOne && predictionPlaces.length > 0" class="dropdown_cotainer">
          <div id="dropdown" class="dropdown_scroll_wrapper">
            <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''"  (click)="setLocation(place, 1)">
              <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
              <div>{{place.full_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="switch_location_icon" src="../../../../assets/switch.svg" (click)="switchLocations()">
    <div class="input_container_one">
      <img class="input_container_one_icon" src="../../../assets/end.svg">
      <div class="inputfield_container" [ngClass]="form.controls.offer_return.value == false ? 'input_disabled' : ''">
        <input #targetAddress class="inputfield" type="text" placeholder="Ziel" [disabled]="form.controls.offer_return.value == false" (input)="this.form.controls.return_target.setValue(null); getPlaces(targetAddress.value, 2);" (focus)="showDropdownTwo = true">
        <div class="input_valid_indicator" [ngClass]="!form.controls.return_target.value ? 'input_valid_indicator_invalid' : ''"></div>
        <div *ngIf="showDropdownTwo && predictionPlaces.length > 0" class="dropdown_cotainer">
          <div id="dropdown" class="dropdown_scroll_wrapper">
            <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''" (click)="setLocation(place, 2)">
              <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
              <div>{{place.full_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input_container_one">
      <img class="input_container_one_icon" src="../../../assets/calender.svg">
      <div class="inputfield_container_date" [ngClass]="form.controls.offer_return.value == false ? 'input_disabled' : ''">
        <input #time_input class="inputfield" placeholder="Zeit" readonly
          formControlName="return_time"
          (focus)="form.controls.offer_return.value ? openCalender() : null"
          (click)="form.controls.offer_return.value ? openCalender() : null">
        <div class="input_valid_indicator" [ngClass]="form.controls.return_time.invalid ? 'input_valid_indicator_invalid' : ''"></div>
      </div>
    </div>
  </div>
  <div class="btn_container">
    <button class="btn_submit" type="button" [ngClass]="!isValid() ? 'btn_submit_inactive' : ''" [disabled]="!isValid()" (click)="nexPage()">Weiter</button>
  </div>
</form>
