import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, enableProdMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Slogan } from 'src/app/buisness-object/faq/Slogan';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { TripService } from 'src/app/service/trip/trip.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public footprint: number;
  public ranking: number;
  public eco_points: number;
  public slogans: Slogan[] = [];
  public showSlogan = 1;
  public sloganInterval: any;
  public reloadInterval: any;
  public user: User;
  public isHorizontal = GlobalVariables.window_width <= 1750;
  public showView = 1;
  public foundTrips: Trip[] = [];
  public nextTrip: Trip;
  public selectedTrip: Trip;
  public bookedTrip: Trip;
  public dialogQuerySubsription: Subscription;
  public showFootprint = true;
  public isTablet = window.innerWidth < 1750;
  public showSlogenInfo = false;

  constructor(
    private uService: UserService,
    private tService: TripService,
    private dService: DialogService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if(this.isHorizontal) this.showFootprint = false;
    this.uService.getUser(Number(localStorage.getItem('id'))).subscribe((data) => {
      if(data){
        this.footprint = data.user.footprint;
        this.ranking = data.user.ranking;
        this.eco_points = data.user.eco_points;
      }
    })
    this.reloadInterval = setInterval(() => {
      this.getDataServerside(true);
    }, 60000)
    this.getDataServerside();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.reloadInterval) clearInterval(this.reloadInterval);
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'book_trip') this.bookTrip(value.submit_value);
        else if(value.typ == 'book_trip_no_detours'){
          this.bookTrip(value.submit_value)
        }
      }
    });
  }

  getDataServerside(reload?: boolean) {
    forkJoin([
      this.uService.getUser(Number(localStorage.getItem('id')), reload),
      this.uService.getSlogans()
    ]).subscribe(([data,slogans]:[any, Slogan[]]) => {
      if(data && slogans){
        this.user = data.user;
        this.slogans = slogans;
        this.getNextTrip(data.created_trips.concat(data.joined_trips));
        this.getRandomSlogan();
      }
    })
  }

  getNextTrip(trips: Trip[]) {
    trips.sort((a,b) => {
      if(a.timestamp > b.timestamp) return 1;
      if(a.timestamp < b.timestamp) return -1;
      return 0;
    })
    this.nextTrip = trips[0];
  }

  getRandomSlogan() {
    this.showSlogan = Math.floor(Math.random() * (this.slogans.length - 1 + 1)) + 1;
  }

  getRankingLabel(): string {
    if(this.user.company_id == 1) return 'Magna';
    else return 'App';
  }

  // setSloganTimer() {
  //   this.sloganInterval = setInterval(() => {
  //     let random = Math.floor(Math.random() * (this.slogans.length - 1 + 1)) + 1;
  //     if(random != this.showSlogan) this.showSlogan = random;
  //   }, 2000);
  // }

  closeView() {
    if(this.showView == 4){
      this.showView = 3;
    } else {
      this.showView = 1;
    }
    //this.getRandomSlogan();
  }

  searchTrip(searchData: any) {
    this.tService.searchTrips(
      searchData.start_lat,
      searchData.start_lng,
      searchData.target_lat,
      searchData.target_lng,
      searchData.timestamp,
      searchData.start_description,
      searchData.target_description,
    ).subscribe((result) => {
      if(result){
        this.foundTrips = result;
        this.foundTrips.sort((a,b) => {
          if(a.timestamp > b.timestamp) return 1;
          if(a.timestamp < b.timestamp) return -1;
          return 0;
        })
        this.showView = 3;
      }
    })
  }

  bookTripAction(trip: Trip) {
    this.selectedTrip = trip;
    if(this.selectedTrip.accept_detours){
      this.dService.openQuery(
        {
          title: 'Fahrt buchen',
          message: 'Du kannst die Fahrt direkt buchen oder einen Umweg anfragen.',
          btn_cancel_txt: 'Umweg anfragen',
          btn_submit_txt: 'Fahrt buchen',
          typ: 'book_trip',
          submit_value: trip,
          showClose: true,
          is_choise: true
        }
      );
    } else {
      this.dService.openQuery(
        {
          title: 'Fahrt buchen',
          message: 'Buche jetzt deine Fahrt.',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Fahrt buchen',
          typ: 'book_trip_no_detours',
          submit_value: trip,
          showClose: true,
          is_choise: false
        }
      );
    }
  }

  bookTrip(submit_value: boolean) {
    if(submit_value){
      this.tService.joinTrip(this.selectedTrip.trip_id).subscribe((result) => {
        if(result){
          this.showView = 5;
        }
      })
    } else {
      this.showView = 4;
    }
  }
  resetView() {
    this.selectedTrip = null;
    this.showView = 1;
  }

  requestDetour(data: any){
    if(data){
      this.selectedTrip.detour_request_start = data.entryPoint?.location_name;
      this.selectedTrip.detour_request_target = data.targetPoint?.location_name;
      this.tService.joinTrip(this.selectedTrip.trip_id, data.entryPoint?.location_name, data.targetPoint?.location_name).subscribe((result) => {
        if(result){
          this.showView = 5;
        }
      })
    }
  }

  openFootprintValue() {
    this.showFootprint = !this.showFootprint;
    if(this.showFootprint){
      let scollView = document.getElementById('scroll_view');
      let footprint = document.getElementById('footprint');
      if(footprint && scollView) {
        setTimeout(() => {
          scollView.scrollTop = footprint.offsetTop;
        }, 0);
      }
    }
  }

  goToNextTrip() {
    this.router.navigate(['/my-drives'], { queryParams: {tripId: this.nextTrip.trip_id}, relativeTo: this.route })
  }
  getFootprintHeight(): string {
    if(this.isHorizontal && this.showFootprint) return 'min-content';
    else return '';
  }

  openHighscore() {
    this.showView = 2;
  }
}
