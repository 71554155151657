import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Trip } from 'src/app/buisness-object/trip/Trip';

@Component({
  selector: 'app-page-six',
  templateUrl: './page-six.component.html',
  styleUrls: ['./page-six.component.scss']
})
export class PageSixComponent implements OnInit {
  @Input() trip: Trip;
  @Output() nextPageEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

  nexPage() {
    this.nextPageEmitter.emit();
  }
}
