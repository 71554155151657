export class Location {
  constructor(
    public location_id: number,
    public location_name: string,
    public latitude: number,
    public longitude: number,
    public company_id: number,
  ){}
}

export class LocationFactory {
  static jsonFactory(rawData: any): Location[] {
    let datas: Location[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): Location {
      return new Location(
          rawData.location_id,
          rawData.location_name,
          rawData.latitude,
          rawData.longitude,
          rawData.company_id,
      );
  }
}
