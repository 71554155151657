export class PredictionPlace {
  constructor(
    public place_id?: string,
    public first_line?: string,
    public second_line?: string,
    public full_name?: string,
    public latitude?: number,
    public longitude?: number
  ){}
}
