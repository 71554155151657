<div class="view_container">
  <app-dashboard-header *ngIf="user"
    [showView]="showView"
    [user]="user"
    (backEmitter)="closeView()"
    (searchEmitter)="searchTrip($event)"
  ></app-dashboard-header>
  <div id="scroll_view" class="view_body" [ngClass]="!nextTrip ? 'view_body_no_drive' : ''" *ngIf="showView == 1 && user">
    <div class="tile_drive_container" *ngIf="!isTablet && nextTrip" (click)="goToNextTrip()">
      <div class="data_wrapper">
        <img class="data_icon" src="../../../../assets/calenderwhite.svg">
        <div class="data_time">
          <label class="lbl_black_16" style="cursor: pointer;">{{nextTrip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
          <label class="lbl_black_16" style="cursor: pointer;">{{nextTrip.timestamp | date: 'HH:mm'}} Uhr</label>
        </div>
      </div>
      <div class="flex_space_between">
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/startwhite.svg">
          <label class="lbl_regular_16" style="cursor: pointer;">{{nextTrip.start_description}}</label>
        </div>
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/endwithe.svg">
          <label class="lbl_regular_16" style="cursor: pointer;">{{nextTrip.target_description}}</label>
        </div>
      </div>
      <label class="lbl_black_16">Deine nächste Fahrt. {{nextTrip.driver?.user_id == user.user_id ? 'Du fährst.' : ''}}</label>
    </div>
    <div class="tile_announcements_container" *ngIf="!isTablet && !user.company_id" [ngStyle]="{'grid-row': !nextTrip ? '1/2' : '2/3'}">
      <label style="align-self: flex-start;" class="lbl_regular_22">Lasst uns nie vergessen, dass die Pflege der Erde die wichtigste Arbeit der Menschen ist.</label>
      <label style="align-self: flex-start;" class="lbl_regular_18">Daniel Webster</label>
    </div>
    <!-- <div class="tile_announcements_container_company" *ngIf="!isTablet && user?.company_id == 1" [ngStyle]="{'grid-row': !nextTrip ? '1/2' : '2/3'}">
      <img class="company_logo" src="../../../assets/magnalogo.svg">
      <label style="align-self: flex-start;" class="lbl_regular_22">{{slogans[showSlogan - 1]?.slogan}}</label>
      <label style="align-self: flex-start;" class="lbl_regular_18">{{slogans[showSlogan - 1]?.author}}</label>
    </div> -->
    <div class="tile_announcements_container_company" *ngIf="!isTablet && user?.company_id == 1" [ngStyle]="{'grid-row': !nextTrip ? '1/2' : '2/3'}">
      <img class="company_logo" src="../../../assets/magnalogo.svg">
      <label class="lbl_regular_22" style="margin-bottom: 0; align-self: flex-start;">{{slogans[showSlogan - 1]?.slogan}}</label>
      <label class="lbl_regular_18" style="align-self: flex-start;" *ngIf="slogans[showSlogan - 1].author && slogans[showSlogan - 1].author.length > 1">{{slogans[showSlogan - 1]?.author}}</label>
      <label class="lbl_regular_18 slogen_info" style="align-self: flex-start; white-space: pre-line" [ngClass]="showSlogenInfo ? 'slogen_info_active': ''">{{slogans[showSlogan - 1]?.add_info}}</label>
      <div id="slogen_button" (click)="showSlogenInfo = !showSlogenInfo">
        <img class="slogen_arrow" [ngClass]="showSlogenInfo ? 'slogen_arrow_active': ''" src="../../../assets/navarrowwhite.svg">
      </div>
    </div>
    <div class="tile_ranking_container" *ngIf="!isTablet" [ngStyle]="{'grid-row': !nextTrip ? '2/4' : '3/4'}">
      <img class="icon_ranking_firework" src="../../../assets/fireworkwhite.png">
      <label class="lbl_black_80">{{ranking}}.</label>
      <label class="lbl_medium_18" style="margin-bottom: 20px;">Platz im {{getRankingLabel()}}-Ranking</label>
      <button class="btn_light" (click)="openHighscore()">{{getRankingLabel()}} Highscore anzeigen</button>
    </div>
    <div *ngIf="!isTablet" id="footprint" class="tile_co2_container" (click)="isHorizontal ? openFootprintValue() : null"
      [ngStyle]="{'grid-row': 1/3, 'min-height': getFootprintHeight()}"
      [ngClass]="isHorizontal && showFootprint ? 'tile_co2_container_open' : ''">
      <img class="icon_footprint" src="../../../assets/footprint.svg">
      <label class="lbl_medium_24" style="text-align: center;">Dein reduzierter Fußabdruck</label>
      <div *ngIf="showFootprint" class="footprint_value_container">
        <label class="lbl_black_80">{{footprint / 1000 | number: '1.2-2'}} kg</label>
        <label class="lbl_medium_18" style="margin-top: 10px;">CO<sub>2</sub> Ersparnis</label>
      </div>
    </div>
    <div *ngIf="!isTablet" class="tile_costs_container">
      <label class="lbl_black_80">€ {{(user.cost_contributions / 100) | number: '1.2-2'}}</label>
      <label class="lbl_medium_18" style="margin-top: 10px;">erhaltene Kostenbeiträge</label>
    </div>
    <div class="column_left" *ngIf="isTablet">
      <div class="tile_drive_container" *ngIf="nextTrip" (click)="goToNextTrip()">
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/calenderwhite.svg">
          <div class="data_time">
            <label class="lbl_black_16">{{nextTrip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
            <label class="lbl_black_16">{{nextTrip.timestamp | date: 'HH:mm'}} Uhr</label>
          </div>
        </div>
        <div class="flex_space_between">
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/startwhite.svg">
            <label class="lbl_regular_16">{{nextTrip.start_description}}</label>
          </div>
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/endwithe.svg">
            <label class="lbl_regular_16">{{nextTrip.target_description}}</label>
          </div>
        </div>
        <label class="lbl_black_16">Deine nächste Fahrt. {{nextTrip.driver?.user_id == user.user_id ? 'Du fährst.' : ''}}</label>
      </div>
      <div class="tile_announcements_container" *ngIf="!user.company_id">
        <label style="align-self: flex-start;" class="lbl_regular_22">Lasst uns nie vergessen, dass die Pflege der Erde die wichtigste Arbeit der Menschen ist.</label>
        <label style="align-self: flex-start;" class="lbl_regular_18">Daniel Webster</label>
      </div>
      <div class="tile_announcements_container_company" *ngIf="user?.company_id == 1">
        <img class="company_logo" src="../../../assets/magnalogo.svg">
        <label style="align-self: flex-start; margin-bottom: 0;" class="lbl_regular_22 lbl_slogan">{{slogans[showSlogan - 1]?.slogan}}</label>
        <label style="align-self: flex-start;" class="lbl_regular_18">{{slogans[showSlogan - 1]?.author}}</label>
        <label style="align-self: flex-start; white-space: pre-line" class="lbl_regular_18 slogen_info" [ngClass]="showSlogenInfo ? 'slogen_info_active': ''">{{slogans[showSlogan - 1]?.add_info}}</label>
        <div id="slogen_button"
         (click)="showSlogenInfo = !showSlogenInfo">
          <img class="slogen_arrow"  [ngClass]="showSlogenInfo ? 'slogen_arrow_active': ''" src="../../../assets/navarrowwhite.svg">
        </div>
      </div>
      <div id="footprint" class="tile_co2_container" (click)="isHorizontal ? openFootprintValue() : null" [ngStyle]="{'min-height': isHorizontal && showFootprint ? 'min-content' : ''}" [ngClass]="isHorizontal && showFootprint ? 'tile_co2_container_open' : ''">
        <img class="icon_footprint" src="../../../assets/footprint.svg">
        <label class="lbl_medium_24" style="text-align: center;">Dein reduzierter Fußabdruck</label>
        <div *ngIf="showFootprint" class="footprint_value_container">
          <label class="lbl_black_80">{{footprint | number: '1.0'}} kg</label>
          <label class="lbl_medium_18" style="margin-top: 10px;">CO<sub>2</sub> Ersparnis</label>
        </div>
      </div>
      <div class="tile_ranking_container">
        <img class="icon_ranking_firework" src="../../../assets/fireworkwhite.png">
        <label class="lbl_black_80">{{ranking}}.</label>
        <label class="lbl_medium_18" style="margin-bottom: 10px;">Platz im {{getRankingLabel()}}-Ranking</label>
        <button class="btn_light" (click)="showView = 2">{{getRankingLabel()}} Highscore anzeigen</button>
      </div>
      <div class="tile_costs_container">
        <label class="lbl_black_80 lbl_costs">{{user.cost_contributions}}€</label>
        <label class="lbl_medium_18" style="margin-bottom: 20px; margin-top: 10px;">Deine Kostenbeiträge</label>
      </div>
    </div>
  </div>
  <app-highscore *ngIf="showView == 2"
    [user]="user"
  ></app-highscore>
  <app-search-results *ngIf="showView == 3"
    [foundTrips]="foundTrips"
    (bookTripEmitter)="bookTripAction($event)"
  ></app-search-results>
  <app-request-detour *ngIf="showView == 4"
    [trip]="selectedTrip"
    (submitEmitter)="requestDetour($event)"
  ></app-request-detour>
  <app-request-success *ngIf="showView == 5"
    [trip]="selectedTrip"
    (goBackToDashboardEmitter)="resetView()"
  ></app-request-success>
</div>


<!-- <div class="view_container">
  <app-dashboard-header
    [showView]="showView"
    (backEmitter)="closeView()"
    (searchEmitter)="searchTrip($event)"
  ></app-dashboard-header>
  <div id="scroll_view" class="view_body" *ngIf="showView == 1 && user">
    <div class="column_left" *ngIf="!isTablet">
      <div class="tile_drive_container" *ngIf="nextTrip" (click)="goToNextTrip()">
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/calenderwhite.svg">
          <div class="data_time">
            <label class="lbl_black_16" style="cursor: pointer;">{{nextTrip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
            <label class="lbl_black_16" style="cursor: pointer;">{{nextTrip.timestamp | date: 'HH:mm'}} Uhr</label>
          </div>
        </div>
        <div class="flex_space_between">
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/startwhite.svg">
            <label class="lbl_regular_16" style="cursor: pointer;">{{nextTrip.start_description}}</label>
          </div>
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/endwithe.svg">
            <label class="lbl_regular_16" style="cursor: pointer;">{{nextTrip.target_description}}</label>
          </div>
        </div>
        <label class="lbl_black_16">Deine nächste Fahrt. {{nextTrip.driver?.user_id == user.user_id ? 'Du fährst.' : ''}}</label>
      </div>
      <div class="tile_announcements_container" *ngIf="!user.company_id">
        <label style="align-self: flex-start;" class="lbl_regular_22">Lasst uns nie vergessen, dass die Pflege der Erde die wichtigste Arbeit der Menschen ist.</label>
        <label style="align-self: flex-start;" class="lbl_regular_18">Daniel Webster</label>
      </div>
      <div class="tile_announcements_container_company" *ngIf="user?.company_id == 1">
        <img class="company_logo" src="../../../assets/magnalogo.svg">
        <label style="align-self: flex-start;" class="lbl_regular_22">{{slogans[showSlogan - 1]?.slogan}}</label>
        <label style="align-self: flex-start;" class="lbl_regular_18">{{slogans[showSlogan - 1]?.author}}</label>
      </div>
      <div class="tile_ranking_container">
        <img class="icon_ranking_firework" src="../../../assets/fireworkwhite.png">
        <label class="lbl_black_80">{{ranking}}.</label>
        <label class="lbl_medium_18" style="margin-bottom: 20px;">Platz im {{getRankingLabel()}}-Ranking</label>
        <button class="btn_light" (click)="showView = 2">{{getRankingLabel()}} Highscore anzeigen</button>
      </div>
    </div>
    <div class="column_">
      <div *ngIf="!isTablet" id="footprint" class="tile_co2_container" (click)="isHorizontal ? openFootprintValue() : null" [ngStyle]="{'min-height': isHorizontal && showFootprint ? 'min-content' : ''}" [ngClass]="isHorizontal && showFootprint ? 'tile_co2_container_open' : ''">
        <img class="icon_footprint" src="../../../assets/footprint.svg">
        <label class="lbl_medium_24" style="text-align: center;">Dein reduzierter Fußabdruck</label>
        <div *ngIf="showFootprint" class="footprint_value_container">
          <label class="lbl_black_80">{{footprint | number: '1.2-2'}} kg</label>
          <label class="lbl_medium_18" style="margin-top: 10px;">CO<sub>2</sub> Ersparnis</label>
        </div>
      </div>
      <div *ngIf="!isTablet && user.cost_contributions" class="tile_costs_container">
        <label class="lbl_black_80">{{user.cost_contributions}} €</label>
        <label class="lbl_medium_18" style="margin-bottom: 20px;">Deine Kostenbeträge</label>
      </div>
    </div>
    <div class="column_left" *ngIf="isTablet">
      <div class="tile_drive_container" *ngIf="nextTrip" (click)="goToNextTrip()">
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/calenderwhite.svg">
          <div class="data_time">
            <label class="lbl_black_16">{{nextTrip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
            <label class="lbl_black_16">{{nextTrip.timestamp | date: 'HH:mm'}} Uhr</label>
          </div>
        </div>
        <div class="flex_space_between">
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/startwhite.svg">
            <label class="lbl_regular_16">{{nextTrip.start_description}}</label>
          </div>
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/endwithe.svg">
            <label class="lbl_regular_16">{{nextTrip.target_description}}</label>
          </div>
        </div>
        <label class="lbl_black_16">Deine nächste Fahrt. {{nextTrip.driver?.user_id == user.user_id ? 'Du fährst.' : ''}}</label>
      </div>
      <div class="tile_announcements_container" *ngIf="!user.company_id">
        <label style="align-self: flex-start;" class="lbl_regular_22">Lasst uns nie vergessen, dass die Pflege der Erde die wichtigste Arbeit der Menschen ist.</label>
        <label style="align-self: flex-start;" class="lbl_regular_18">Daniel Webster</label>
      </div>
      <div class="tile_announcements_container_company" *ngIf="user?.company_id == 1">
        <img class="company_logo" src="../../../assets/magnalogo.svg">
        <label style="align-self: flex-start;" class="lbl_regular_22">{{slogans[showSlogan - 1]?.slogan}}</label>
        <label style="align-self: flex-start;" class="lbl_regular_18">{{slogans[showSlogan - 1]?.author}}</label>
      </div>
      <div id="footprint" class="tile_co2_container" (click)="isHorizontal ? openFootprintValue() : null" [ngStyle]="{'min-height': isHorizontal && showFootprint ? 'min-content' : ''}" [ngClass]="isHorizontal && showFootprint ? 'tile_co2_container_open' : ''">
        <img class="icon_footprint" src="../../../assets/footprint.svg">
        <label class="lbl_medium_24" style="text-align: center;">Dein reduzierter Fußabdruck</label>
        <div *ngIf="showFootprint" class="footprint_value_container">
          <label class="lbl_black_80">{{footprint | number: '1.2-2'}} kg</label>
          <label class="lbl_medium_18" style="margin-top: 10px;">CO<sub>2</sub> Ersparnis</label>
        </div>
      </div>
      <div class="tile_ranking_container">
        <img class="icon_ranking_firework" src="../../../assets/fireworkwhite.png">
        <label class="lbl_black_80">{{ranking}}.</label>
        <label class="lbl_medium_18" style="margin-bottom: 20px;">Platz im {{getRankingLabel()}}-Ranking</label>
        <button class="btn_light" (click)="showView = 2">{{getRankingLabel()}} Highscore anzeigen</button>
      </div>
      <div *ngIf="user.cost_contributions" class="tile_costs_container">
        <label class="lbl_black_80 lbl_costs">{{user.cost_contributions}}€</label>
        <label class="lbl_medium_18" style="margin-bottom: 20px;">Deine Kostenbeträge</label>
      </div>
    </div>
  </div>
  <app-highscore *ngIf="showView == 2"
    [user]="user"
  ></app-highscore>
  <app-search-results *ngIf="showView == 3"
    [foundTrips]="foundTrips"
    (bookTripEmitter)="bookTripAction($event)"
  ></app-search-results>
  <app-request-detour *ngIf="showView == 4"
    [trip]="selectedTrip"
    (submitEmitter)="requestDetour($event)"
  ></app-request-detour>
  <app-request-success *ngIf="showView == 5"
    [trip]="selectedTrip"
    (goBackToDashboardEmitter)="resetView()"
  ></app-request-success>
</div> -->

