import { Vehicle } from "../vehicle/Vehicle";

export class User {
  public avatar: string
  constructor(
    public user_id: number,
    public first_name: string,
    public last_name: string,
    public birthdate: number,
    public email: string,
    public is_company?: boolean,
    public dataprivacy_accepted?: boolean,
    public allow_phonenumber?: boolean,
    public allowed_detour?: number,
    public company_id?: number,
    public eco_points?: number,
    public email_enabled?: boolean,
    public footprint?: number,
    public phonenumber?: string,
    public push_enabled?: boolean,
    public ranking?: number,
    public rating?: number,
    public rating_count?: number,
    public registered_since?: number,
    public vehicle?: Vehicle,
    public status?: number,
    public start_description?: string,
    public target_description?: string,
    public amount_of_trips?: number,
    public conversation_id?: number,
    public cost_contributions?: number
  ){}

  getRating(): number {
    if(!this.rating_count || this.rating_count == 0){
      return 5;
    }
    return Math.round(this.rating);
  }
}
