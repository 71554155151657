import { Vehicle } from "../Vehicle";

export class VehicleFactory {
  static jsonFactory(rawData: any): Vehicle[] {
    let datas: Vehicle[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): Vehicle {
      return new Vehicle(
          rawData.vehicle_color,
          rawData.vehicle_brand_and_model,
      );
  }
}
