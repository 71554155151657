<div class="view_body" *ngIf="true">
  <div class="header">
    <div class="profile_container">
      <div class="user_img_container">
        <img [ngClass]="user?.avatar ? 'user_img' : 'user_img_placeholder'" [src]="user?.avatar ? ('data:image/png;base64,' + user?.avatar) : '../../../assets/userimageplaceholder.svg'">
      </div>
      <div class="user_data_container">
        <label class="lbl_medium_28">{{user.first_name}} {{user.last_name}}</label>
        <div class="star_container">
          <div *ngFor="let start of getArray(5); let i=index" class="icon_star">{{user?.getRating() >= (i+1) ? '&#9733;' : '&#10025;'}}</div>
        </div>
        <label class="lbl_regular_14" *ngIf="!isPhone || !isTablet">{{user?.rating_count}} Bewertungen</label>
        <label class="lbl_regular_14">Mitglied seit: {{user?.registered_since | date: 'dd.MM.YYYY'}}</label>
        <label *ngIf="user?.allow_phonenumber && user?.phonenumber" class="lbl_regular_14 lbl_active phonenumber" (click)="openCall(user.phonenumber)">{{user?.phonenumber}}</label>
        <div *ngIf="!user?.allow_phonenumber && !user?.phonenumber"  style="height: 15px;"></div>
      </div>
    </div>
    <!-- <button *ngIf="isPhone || isTablet" class="btn_submit_secondary lbl_regular_14" (click)="openChat()">Jetzt chatten</button> -->
  </div>
  <div class="body">
    <app-chat *ngIf="isTablet || isPhone"
      [form]="form"
      [user]="user"
      [currentUser]="currentUser"
      [conversation]="conversation"
      [submitted]="submitted"
      (sendMessageEmitter)="sendMessage()"
    ></app-chat>
    <!-- <div class="column_1">
      <div class="co2_wrapper">
        <Label class="lbl_black_80">{{user.footprint | number: '1.2-2'}} kg</Label>
        <Label class="lbl_medium_18 lbl_white">CO<sub>2</sub>-Ersparnis insgesamt</Label>
      </div>
      <div class="ranking_wrapper">
        <Label class="lbl_black_80">{{user.ranking}}.</Label>
        <Label class="lbl_medium_18 lbl_white">Platz im {{getRankingLabel()}}-Ranking</Label>
      </div>
    </div> -->
    <div class="chat_wrapper" *ngIf="(!isPhone && !isTablet)">
      <div id="messages_list">
        <div class="message_wrapper" [ngClass]="message.user_id != currentUser?.user_id ? 'message_wrapper_recieve' : 'message_wrapper_send'" *ngFor="let message of conversation?.messages" [attr.id]="'message_'+message.message_id">
          <Label class="lbl_regular_12 lbl_light">{{message.timestamp | date: 'HH:mm'}}</Label>
          <Label class="lbl_regular_14 lbl_white message" [ngClass]="message.user_id != currentUser?.user_id ? 'message_recieve' : 'message_send'">{{message.content}}</Label>
        </div>
      </div>
      <form [formGroup]="form" class="answer_container">
        <div class="inputfield_container" [ngClass]="submitted && form.controls.message.invalid ? 'inputfield_container_invalid' : ''">
          <input class="inputfield" type="text" placeholder="Schreibe deine Nachricht..." formControlName="message" (focus)="submitted = false">
        </div>
        <div class="input_icon_container" (click)="sendMessage()">
          <img class="input_icon" src="../../../../../assets/contactgrey.svg">
        </div>
      </form>
    </div>
  </div>
</div>
<!-- <app-chat *ngIf="showChatPhone"
  [form]="form"
  [user]="user"
  [currentUser]="currentUser"
  [conversation]="conversation"
  [submitted]="submitted"
  (sendMessageEmitter)="sendMessage()"
></app-chat> -->

