import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { User } from 'src/app/buisness-object/user/User';
import { Vehicle } from 'src/app/buisness-object/vehicle/Vehicle';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LoginService } from 'src/app/service/login/login.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  public user: User;
  public form: FormGroup;
  public openView = 1;
  public dialogQuerySubsription: Subscription;
  public profileCompletion: string;
  public isPhone = GlobalVariables.is_phone_device;
  public isTablet = GlobalVariables.is_tablet_device;
  public showMenuOne = false;
  public showMenuTwo = false;
  public showMenuThree = false;
  public showMenuFour = false;
  public showMenuFive = false;
  public timeOut: any;

  constructor(
    private formBuilder: FormBuilder,
    private uService: UserService,
    private dService: DialogService,
    private lService: LoginService
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
    this.setDialogSubscription();
  }

  getDataServerside() {
    forkJoin([
      this.uService.getUser(Number(localStorage.getItem('id'))),
      this.uService.getAvatar(Number(localStorage.getItem('id')))
    ]).subscribe(([data,avatar]:[any, any]) => {
      if(data && avatar){
        this.user = data.user;
        this.user.avatar = avatar.data;
        this.configForm();
        this.fillForm();
        let completedData = 0;
        if(this.user?.phonenumber) completedData++;
        if(this.user?.vehicle?.vehicle_brand_and_model || this.user?.vehicle?.vehicle_color) completedData++;
        this.profileCompletion = ((100/2) * completedData)+'%';
      }
    })
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.timeOut) clearTimeout(this.timeOut);
  }

  onResize(e:any){
    if(this.timeOut) clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.isPhone = GlobalVariables.is_phone_device;
      this.isTablet = GlobalVariables.is_tablet_device;
    }, 150);
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'logout') this.logout();
        else if(value.typ == 'delete_account') this.deleteAccount();
      }
    });
  }

  configForm() {
    this.form = this.formBuilder.group({
      phonenumber: [null,[Validators.required,Validators.pattern('[+-/0-9][^\s]+')]],
      allow_phonenumber: [false,[Validators.required]],
      vehicle_color: [null],
      vehicle_brand_and_model: [null],
      push_enabled: [false,[Validators.required]],
      email_enabled: [false,[Validators.required]],
      allowed_detour: [null,[Validators.required]],
      first_name: [null,[Validators.required]],
      last_name: [null,[Validators.required]],
    })
  }

  fillForm() {
    this.form.controls.phonenumber.setValue(this.user.phonenumber);
    this.form.controls.allow_phonenumber.setValue(this.user.allow_phonenumber);
    this.form.controls.vehicle_color.setValue(this.user.vehicle?.vehicle_color);
    this.form.controls.vehicle_brand_and_model.setValue(this.user.vehicle?.vehicle_brand_and_model);
    this.form.controls.push_enabled.setValue(this.user.push_enabled);
    this.form.controls.email_enabled.setValue(this.user.email_enabled);
    this.form.controls.allowed_detour.setValue(this.user.allowed_detour);
    this.form.controls.first_name.setValue(this.user.first_name);
    this.form.controls.last_name.setValue(this.user.last_name);
  }

  getArray(i: number): any {
    return new Array(i);
  }

  openMenuItem(item: number) {
    if(item == 7){
      this.showMenuOne = false;
      this.showMenuTwo = false;
      this.showMenuThree = false;
    }
    this.openView = item;
  }

  cancel() {
    this.fillForm();
    this.openView = 1;
  }

  updateUser() {
    if(this.form.invalid && !this.user){
      return;
    } else {
      let user = new User(
        this.user.user_id,
        this.form.controls.first_name.value,
        this.form.controls.last_name.value,
        this.user.birthdate,
        this.user.email,
        this.user.is_company,
        this.user.dataprivacy_accepted,
        this.form.controls.allow_phonenumber.value,
        this.form.controls.allowed_detour.value,
        this.user.company_id,
        this.user.eco_points,
        this.form.controls.email_enabled.value,
        this.user.footprint,
        this.form.controls.phonenumber.value,
        this.form.controls.push_enabled.value,
        this.user.ranking,
        this.user.rating,
        this.user.rating_count,
        this.user.registered_since,
        new Vehicle(
          this.form.controls.vehicle_brand_and_model.value,
          this.form.controls.vehicle_color.value,
        ),
      )
      user.avatar = this.user.avatar
      this.uService.updateUser(user).subscribe((response) => {
        if(response){
          this.user = response;
          this.user.avatar = user.avatar;
          this.fillForm();
          let completedData = 0;
          if(this.user?.phonenumber) completedData++;
          if(this.user?.vehicle?.vehicle_brand_and_model || this.user?.vehicle?.vehicle_color) completedData++;
          this.profileCompletion = ((100/2) * completedData)+'%';
          if(window.innerWidth > 550){
            this.dService.showNotification({
              title: 'Erfolgreich',
              message: 'Profil aktualisiert.',
              success: true
            });
          }
          this.openView = 1;
        }
      })
    }
  }

  logoutAction() {
    this.dService.openQuery(
      {
        title: 'Abmelden',
        message: 'Bist du sicher das du dich abmelden möchtest?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Abmelden',
        typ: 'logout',
        submit_value: null,
      }
    );
  }

  logout() {
    this.lService.logout();
  }

  deleteAction() {
    this.dService.openQuery(
      {
        title: 'Möchtest du deinen Account wirklich löschen?',
        message: 'Alle deine Daten und Einstellungen gehen dadurch verloren.',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete_account',
        submit_value: null,
      }
    );
  }

  deleteAccount() {
    this.uService.deleteUser(this.user.user_id).subscribe((response) => {});
  }

  getProfileTitle(): string {
    let counter = 2;
    if(this.user){
      if(this.user.phonenumber) counter--;
      if(this.user.vehicle?.vehicle_brand_and_model) counter--;
      if(counter == 1) return 'Noch 1 Schritt';
      else return 'Noch ' + counter + ' Schritte';
    } else {
      return '';
    }
  }

  async uploadProfileAvatar(event: any) {
    if(event.target.files && event.target.files.length != 0){
      this.updateProfileAvatar(await this.readFiles(event.target.files[0]));
    }
  }

  async readFiles(files: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result as string)
      }
      reader.readAsDataURL(files);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }

  updateProfileAvatar(base64: string) {
    this.uService.createAvatar(this.user.user_id, base64.split(',')[1]).subscribe((response) => {
      if(response){
        this.user.avatar = base64.split(',')[1];
      }
    })
  }

  openMenu(menu: string) {
    let isOpen = false;
    if(menu == 'menu_one'){
      this.showMenuOne = !this.showMenuOne;
      if(this.showMenuOne){
        this.showMenuTwo = false;
        this.showMenuThree = false;
        isOpen = true;
      }
    } else if(menu == 'menu_two'){
      this.showMenuTwo = !this.showMenuTwo;
      if(this.showMenuTwo){
        this.showMenuOne = false;
        this.showMenuThree = false;
        isOpen = true;
      }
    } else if(menu == 'menu_three'){
      this.showMenuThree = !this.showMenuThree;
      if(this.showMenuThree){
        this.showMenuOne = false;
        this.showMenuTwo = false;
        isOpen = true;
      }
    }
    if(this.isPhone && isOpen){
      let scollView = document.getElementById('scroll_view');
      let menu_element = document.getElementById(menu);
      if(scollView && menu_element) {
        setTimeout(() => {
            scollView.scrollTop = menu_element.offsetTop;
        }, 0);
      }
    }
  }
}
