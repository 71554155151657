<div id="dialog_window" *ngIf="show">
  <div class="close_icon" (click)="onSubmit()">&#10005;</div>
  <form id="dialog_container">
    <label class="lbl_medium_22 lbl_active">{{title}}</label>
    <img class="icon" src="../../../../assets/editmycar.svg">
    <label class="lbl_regular_14">{{message}}</label>
    <div class="btn_container">
      <button class="btn_submit lbl_black_16" (click)="onSubmit()">{{btnSubmitTxt}}</button>
    </div>
  </form>
</div>
