import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/buisness-object/location/Location';
import { PredictionPlace } from 'src/app/buisness-object/location/PredictionPlace';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LocationService } from 'src/app/service/location/location.service';
import { MapService } from 'src/app/service/map/map.service';
import { UserService } from 'src/app/service/user/user.service';


@Component({
  selector: 'app-page-four',
  templateUrl: './page-four.component.html',
  styleUrls: ['./page-four.component.scss']
})
export class PageFourComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Output() nextPageEmitter = new EventEmitter<undefined>();
  public minDate = new Date();
  public dialogCalendarSubscription: Subscription;
  @ViewChild('startAddress') startAddress: ElementRef;
  @ViewChild('targetAddress') targetAddress: ElementRef;
  @ViewChild('time_input') time_input: ElementRef;
  public importantLocations: Location[] = [];
  public predictionPlaces: PredictionPlace[] = [];
  public predictionPlacesImportantLocations: PredictionPlace[] = [];
  public sessionToken: google.maps.places.AutocompleteSessionToken;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public listenerOnClick: () => void;
  public listenerArrowkeys: () => void;
  public placeArrowIndex = -1;
  public user: User;
  public inputTimeout;


  constructor(
    private dService: DialogService,
    private lService: LocationService,
    private renderer: Renderer2,
    private mapService: MapService,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.setDialogSubscription();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.getDataServerside();
    this.getReturnRoute(this.form.controls.return_route.value ? false : true);
  }

  ngAfterViewInit(): void {
    if(this.form.controls.offer_return.value){
      this.time_input.nativeElement.value = moment(this.form.controls.return_time.value).format('dd., DD.MM.yyyy, HH:mm');
    } else {
      if(this.form.controls.time.value) this.time_input.nativeElement.value =  moment(this.form.controls.time.value).format('dd., DD.MM.yyyy, HH:mm');
    }
    if(this.form.controls.start.value) this.startAddress.nativeElement.value = this.form.controls.return_start.value ? this.form.controls.return_start.value.location_name : this.form.controls.target.value.location_name;
    if(this.form.controls.target.value) this.targetAddress.nativeElement.value = this.form.controls.return_target.value ? this.form.controls.return_target.value.location_name : this.form.controls.start.value.location_name;
    this.listenerOnClick = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne && !this.startAddress.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.targetAddress.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      this.placeArrowIndex = -1;
    })
    if(window.screen.width > 1750) {
      this.listenerArrowkeys = this.renderer.listen('document','keydown',(e: KeyboardEvent) => {
        if(this.showDropdownOne || this.showDropdownTwo){
          if(e.key == 'ArrowUp'){
            if(this.placeArrowIndex <= 0) this.placeArrowIndex = (this.predictionPlaces.length - 1);
            else this.placeArrowIndex--;
          } else if(e.key == 'ArrowDown'){
            if(this.placeArrowIndex == (this.predictionPlaces.length - 1)) this.placeArrowIndex = 0;
            else this.placeArrowIndex++;
          } else if(e.key == 'Enter'){
            this.setLocation(this.predictionPlaces[this.placeArrowIndex], (this.showDropdownOne ? 1 : 2))
            this.placeArrowIndex = -1;
          }
          const dropdown = document.getElementById('dropdown');
          const element = document.getElementById('place_'+this.placeArrowIndex);
          if(dropdown && element){
            if(element.offsetTop > (dropdown.offsetHeight)){
              dropdown.scrollTop = element.offsetTop;
            } else if(element.offsetLeft < (dropdown.offsetHeight )){
              dropdown.scrollTop = element.offsetHeight - 32;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    if(this.dialogCalendarSubscription) this.dialogCalendarSubscription.unsubscribe();
    if(this.listenerOnClick) this.listenerOnClick();
    if(this.listenerArrowkeys) this.listenerArrowkeys();
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
  }

  getReturnRoute(isFirstView: boolean) {
    let start;
    let target;
    if(isFirstView){
      start = new google.maps.LatLng(this.form.controls.target.value.latitude,this.form.controls.target.value.longitude);
      target = new google.maps.LatLng(this.form.controls.start.value.latitude,this.form.controls.start.value.longitude);
    } else {
      start = new google.maps.LatLng(this.form.controls.return_start.value.latitude,this.form.controls.return_start.value.longitude);
      target = new google.maps.LatLng(this.form.controls.return_target.value.latitude,this.form.controls.return_target.value.longitude);
    }
    if(start && target){
      this.mapService.initializeMap(start,target, null).then((result) => {
        this.form.controls.return_route.setValue(result.routes[0]);
      });
    }
  }

  getDataServerside() {
    this.uService.getUser(Number(localStorage.getItem('id'))).subscribe((result) => {
      if(result){
        this.user = result.user;
        if(this.user.company_id == 1){
          this.lService.getImportantLocations().subscribe((response) => {
            if(response){
              this.importantLocations = response;
              for(let location of this.importantLocations){
                this.predictionPlacesImportantLocations.push(
                  new PredictionPlace(
                    'magna_',
                    location.location_name,
                    location.location_name,
                    location.location_name,
                    location.latitude,
                    location.longitude,
                  )
                )
              }
              this.predictionPlaces = this.predictionPlacesImportantLocations;
            }
          })
        }
      }
    })
  }

  setDialogSubscription() {
    this.dialogCalendarSubscription = this.dService.closeDialogInputCalender$.subscribe((data) => {
      if(data){
        if(data.submit_data == 'return_time' && data.submit_value){
          this.form.controls.return_several_time_typ.setValue(1);
          this.form.controls.return_customized_trips.setValue(null);
          this.form.controls.return_last_trip.setValue(null);
          this.form.controls.return_time.setValue(data.submit_value);
          this.time_input.nativeElement.value = moment(data.submit_value).format('dd., DD.MM.yyyy, HH:mm');
        }
      }
    });
  }

  getPlaces(search: string, input_number: number) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.mapService.getPredictions(search, this.sessionToken, this.importantLocations).then((result) => {
        this.predictionPlaces = result;
        if(this.predictionPlaces.length == 0){
          this.predictionPlaces = this.predictionPlacesImportantLocations;
        } else if(search.includes('magna')) {
          this.predictionPlaces = this.predictionPlacesImportantLocations.concat(this.predictionPlaces);
        }
        if(input_number == 1){
          this.showDropdownOne = true;
        } else if(input_number == 2){
          this.showDropdownTwo = true;
        }
      });
    }, 300);
  }

  setLocation(predictionPlace: PredictionPlace, inputNumber: number) {
    if(predictionPlace.place_id.includes('magna_')){
      let place = new Location(
        0,
        predictionPlace.full_name,
        predictionPlace.latitude,
        predictionPlace.longitude,
        1,
      );
      if(inputNumber == 1){
        this.form.controls.return_start.setValue(place);
        this.startAddress.nativeElement.value = place.location_name;
      } else if(inputNumber == 2){
        this.form.controls.return_target.setValue(place);
        this.targetAddress.nativeElement.value = place.location_name;
      }
    } else {
      this.mapService.getLocationDetails(predictionPlace).then((result) => {
        if(result){
          if(inputNumber == 1){
            this.form.controls.return_start.setValue(result);
            this.startAddress.nativeElement.value = result.location_name;
          } else if(inputNumber == 2){
            this.form.controls.return_target.setValue(result);
            this.targetAddress.nativeElement.value = result.location_name;
          }
          this.predictionPlaces = [];
          this.getReturnRoute(false);
        }
      })
    }
    this.predictionPlaces = this.predictionPlacesImportantLocations;
    this.showDropdownOne = false;
    this.showDropdownTwo = false;
  }

  openCalender() {
    this.time_input.nativeElement.blur();
    let d2 = new Date(this.form.controls.return_time.value.getTime());
    let d1 = new Date(this.form.controls.time.value.getTime());
    this.dService.openInputCalendar({
      date: d2 ? d2 : this.form.controls.time.value,
      submit_data: 'return_time',
      min_date: d1
    });
  }

  isValid(): boolean {
    if(this.form.controls.offer_return.value){
      if(this.form.controls.return_start.value && this.form.controls.return_target.value && this.form.controls.return_time.value) {
        return true;
      } else {
        return false;
      }
    } else {
      this.form.controls.offer_return.setValue(false);
      return true;
    }
  }

  nexPage() {
    this.nextPageEmitter.emit();
  }

  isMagnaPlace(place_id: string): boolean {
    if(place_id.includes('magna')) return true;
    else return false;
  }

  switchLocations() {
    let temp_start = this.form.controls.return_start?.value;
    this.form.controls.return_start.setValue(this.form.controls.return_target?.value);
    this.startAddress.nativeElement.value = this.form.controls.return_start?.value?.location_name ? this.form.controls.return_start.value.location_name : '';
    this.form.controls.return_target?.setValue(temp_start);
    this.targetAddress.nativeElement.value = this.form.controls.return_target?.value?.location_name ? this.form.controls.return_target.value.location_name : '';
  }
}
