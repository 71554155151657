import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/buisness-object/location/Location';
import { PredictionPlace } from 'src/app/buisness-object/location/PredictionPlace';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LocationService } from 'src/app/service/location/location.service';
import { MapService } from 'src/app/service/map/map.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-page-one',
  templateUrl: './page-one.component.html',
  styleUrls: ['./page-one.component.scss']
})
export class PageOneComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() isEdit: boolean;
  @Input() showTemplateBtn: boolean;
  @Output() nextPageEmitter = new EventEmitter<undefined>();
  @Output() showTemplatesEmitter = new EventEmitter<undefined>();
  public minDate = new Date();
  public showCalender = false;
  public dialogCalendarSubscription: Subscription;
  @ViewChild('startAddress') startAddress: ElementRef;
  @ViewChild('targetAddress') targetAddress: ElementRef;
  @ViewChild('time_input') time_input: ElementRef;
  public importantLocations: Location[] = [];
  public validStart = false;
  public validTarget = false;
  public predictionPlaces: PredictionPlace[] = [];
  public sessionToken: google.maps.places.AutocompleteSessionToken;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public listenerOnClick: () => void;
  public listenerArrowkeys: () => void;
  public placeArrowIndex = -1;
  public user: User;
  public inputTimeout;
  public predictionPlacesImportantLocations: PredictionPlace[] = [];
  public isPhone = GlobalVariables.is_phone_device;

  constructor(
    private dService: DialogService,
    private lService: LocationService,
    private renderer: Renderer2,
    private mapService: MapService,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.setDialogSubscription();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.getDataServerside();
  }

  ngAfterViewInit(): void {
    if(this.form.controls.time.value) this.time_input.nativeElement.value = moment(this.form.controls.time.value).format('dd., DD.MM.yyyy, HH:mm');
    if(this.isEdit){
      if(this.form.controls.start.value) this.startAddress.nativeElement.value = this.form.controls.start.value ? this.form.controls.start.value : '';
      if(this.form.controls.target.value) this.targetAddress.nativeElement.value = this.form.controls.target.value ? this.form.controls.target.value : '';
    } else {
      if(this.form.controls.start.value) this.startAddress.nativeElement.value = this.form.controls.start.value.location_name ? this.form.controls.start.value.location_name : '';
      if(this.form.controls.target.value) this.targetAddress.nativeElement.value = this.form.controls.target.value.location_name ? this.form.controls.target.value.location_name : '';
    }
    this.listenerOnClick = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne && !this.startAddress.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.targetAddress.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      this.placeArrowIndex = -1;
    })
    if(window.screen.width > 1750) {
      this.listenerArrowkeys = this.renderer.listen('document','keydown',(e: KeyboardEvent) => {
        if(this.showDropdownOne || this.showDropdownTwo){
          if(e.key == 'ArrowUp'){
            if(this.placeArrowIndex <= 0) this.placeArrowIndex = (this.predictionPlaces.length - 1);
            else this.placeArrowIndex--;
          } else if(e.key == 'ArrowDown'){
            if(this.placeArrowIndex == (this.predictionPlaces.length - 1)) this.placeArrowIndex = 0;
            else this.placeArrowIndex++;
          } else if(e.key == 'Enter'){
            this.setLocation(this.predictionPlaces[this.placeArrowIndex], (this.showDropdownOne ? 1 : 2))
            this.placeArrowIndex = -1;
          }
          const dropdown = document.getElementById('dropdown');
          const element = document.getElementById('place_'+this.placeArrowIndex);
          if(dropdown && element){
            if(element.offsetTop > (dropdown.offsetHeight)){
              dropdown.scrollTop = element.offsetTop;
            } else if(element.offsetLeft < (dropdown.offsetHeight )){
              dropdown.scrollTop = element.offsetHeight - 32;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    if(this.dialogCalendarSubscription) this.dialogCalendarSubscription.unsubscribe();
    if(this.listenerOnClick) this.listenerOnClick();
    if(this.listenerArrowkeys) this.listenerArrowkeys();
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
  }

  getDataServerside() {
    this.uService.getUser(Number(localStorage.getItem('id'))).subscribe((result) => {
      if(result){
        this.user = result.user;
        if(this.user.company_id == 1){
          this.lService.getImportantLocations().subscribe((response) => {
            if(response){
              this.importantLocations = response;
              for(let location of this.importantLocations){
                this.predictionPlacesImportantLocations.push(
                  new PredictionPlace(
                    'magna_',
                    location.location_name,
                    location.location_name,
                    location.location_name,
                    location.latitude,
                    location.longitude,
                  )
                )
              }
              this.predictionPlaces = this.predictionPlacesImportantLocations;
            }
          })
        }
      }
    })
  }

  setDialogSubscription() {
    this.dialogCalendarSubscription = this.dService.closeDialogInputCalender$.subscribe((data) => {
      if(data){
        if(data.submit_data == 'time' && data.submit_value){
          if(!this.isEdit){
            this.form.controls.several_time_typ.setValue(1);
            this.form.controls.customized_trips.setValue(null);
            this.form.controls.last_trip.setValue(null);
          }
          this.form.controls.time.setValue(data.submit_value);
          this.time_input.nativeElement.value = moment(data.submit_value).format('dd., DD.MM.yyyy, HH:mm');
        }
      }
    });
  }

  getPlaces(search: string, input_number: number) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.mapService.getPredictions(search, this.sessionToken, this.importantLocations).then((result) => {
        this.predictionPlaces = result;
        if(this.predictionPlaces.length == 0){
          this.predictionPlaces = this.predictionPlacesImportantLocations;
        } else if(search.includes('magna')) {
          this.predictionPlaces = this.predictionPlacesImportantLocations.concat(this.predictionPlaces);
        }
        if(input_number == 1){
          this.showDropdownOne = true;
        } else if(input_number == 2){
          this.showDropdownTwo = true;
        }
      });
    }, 300);
  }

  setLocation(predictionPlace: PredictionPlace, inputNumber: number) {
    if(predictionPlace.place_id.includes('magna_')){
      let place = new Location(
        0,
        predictionPlace.full_name,
        predictionPlace.latitude,
        predictionPlace.longitude,
        1,
      );
      if(inputNumber == 1){
        if(this.isEdit){
          this.form.controls.start.setValue(place.location_name);
          this.form.controls.start_latitude.setValue(place.latitude);
          this.form.controls.start_longitude.setValue(place.longitude);
        } else {
          this.form.controls.start.setValue(place);
        }
        this.startAddress.nativeElement.value = place.location_name;
      } else if(inputNumber == 2){
        if(this.isEdit){
          this.form.controls.target.setValue(place.location_name);
          this.form.controls.target_latitude.setValue(place.latitude);
          this.form.controls.target_longitude.setValue(place.longitude);
        } else {
          this.form.controls.target.setValue(place);
        }
        this.targetAddress.nativeElement.value = place.location_name;
      }
    } else {
      this.mapService.getLocationDetails(predictionPlace).then((result) => {
        if(result){
          if(inputNumber == 1){
            if(this.isEdit){
              this.form.controls.start.setValue(result.location_name);
              this.form.controls.start_latitude.setValue(result.latitude);
              this.form.controls.start_longitude.setValue(result.longitude);
            } else {
              this.form.controls.start.setValue(result);
            }
            this.startAddress.nativeElement.value = result.location_name;
          } else if(inputNumber == 2){
            if(this.isEdit){
              this.form.controls.target.setValue(result.location_name);
              this.form.controls.target_latitude.setValue(result.latitude);
              this.form.controls.target_longitude.setValue(result.longitude);
            } else {
              this.form.controls.target.setValue(result);
            }
            this.targetAddress.nativeElement.value = result.location_name;
          }
        }
      })
    }
    this.predictionPlaces = this.predictionPlacesImportantLocations;
    this.showDropdownOne = false;
    this.showDropdownTwo = false;
  }

  openCalender() {
    this.time_input.nativeElement.blur();
    this.dService.openInputCalendar({
      date: this.form.controls.time.value ? this.form.controls.time.value : null,
      submit_data: 'time',
      min_date: new Date()
    });
  }

  isValid(): boolean {
    if(this.form.controls.start.value && this.form.controls.target.value && this.form.controls.time.value) return true;
    else return false;
  }

  nexPage() {
    if(this.form.controls.return_start) this.form.controls.return_start.setValue(this.form.controls.target.value);
    if(this.form.controls.return_target) this.form.controls.return_target.setValue(this.form.controls.start.value);
    if(this.form.controls.return_time) this.form.controls.return_time.setValue(this.form.controls.time.value);
    this.nextPageEmitter.emit();
  }

  isMagnaPlace(place_id: string): boolean {
    if(place_id.includes('magna')) return true;
    else return false;
  }

  switchLocations() {
    if(this.isEdit){
      let temp_start = this.form.controls.start?.value;
      let temp_start_latitude = this.form.controls.start_latitude?.value;
      let temp_start_longitude = this.form.controls.start_longitude?.value;
      this.form.controls.start.setValue(this.form.controls.target?.value);
      this.form.controls.start_latitude?.setValue(this.form.controls.target_latitude?.value);
      this.form.controls.start_longitude?.setValue(this.form.controls.target_longitude?.value);
      this.startAddress.nativeElement.value = this.form.controls.start?.value ? this.form.controls.start.value : '';
      this.form.controls.target?.setValue(temp_start);
      this.form.controls.target_latitude?.setValue(temp_start_latitude);
      this.form.controls.target_longitude?.setValue(temp_start_longitude);
      this.targetAddress.nativeElement.value = this.form.controls.target?.value ? this.form.controls.target.value : '';
    } else {
      let temp_start = this.form.controls.start?.value;
      this.form.controls.start.setValue(this.form.controls.target?.value);
      this.startAddress.nativeElement.value = this.form.controls.start?.value?.location_name ? this.form.controls.start.value.location_name : '';
      this.form.controls.target?.setValue(temp_start);
      this.targetAddress.nativeElement.value = this.form.controls.target?.value?.location_name ? this.form.controls.target.value.location_name : '';
    }

  }
}



// async getPlaceAutocomplete() {
  //   const autocomplete__start = new google.maps.places.Autocomplete(this.startAddress.nativeElement,
  //   {
  //     componentRestrictions: { country:  'at' },
  //     types: ['establishment', 'geocode'],
  //   });
  //   google.maps.event.addListener(autocomplete__start, 'place_changed', () => {
  //     const place = autocomplete__start.getPlace();
  //     this.form.controls.start.setValue(place);
  //     this.startAddress.nativeElement.value = place.formatted_address;
  //   });
  //   const autocomplete_target = new google.maps.places.Autocomplete(this.targetAddress.nativeElement,
  //   {
  //     componentRestrictions: { country: 'AT' },
  //     types: ['establishment', 'geocode']
  //   });
  //   google.maps.event.addListener(autocomplete_target, 'place_changed', () => {
  //     const place = autocomplete_target.getPlace();
  //     this.form.controls.target.setValue(place);
  //     this.targetAddress.nativeElement.value = place.formatted_address;
  //   });
  // }

    // getCurrentLocation(): any {
  //   navigator.permissions.query({name: 'geolocation'}).then((result) => {
  //     if(result.state == 'denied'){
  //       alert('Geolocation is denied')
  //     } else {
  //       navigator.geolocation.getCurrentPosition(position => {
  //         //console.log(position)
  //         //let value =  new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
  //         let latlng = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude
  //         }
  //         let geocode = new google.maps.Geocoder();
  //         geocode.geocode({location: latlng}).then((result) => {
  //           //console.log(result)
  //         })
  //       })
  //     }
  //   })
  // }
