import { Conversation, Message } from "../Conversation";

export class ConversationFactory {
  static jsonFactory(rawData: any): Conversation[] {
    let datas: Conversation[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): Conversation {
      return new Conversation(
          rawData.conversation_id,
          rawData.trip_id,
          rawData.user_id1,
          rawData.user_id2,
          rawData.messages ? MessageFactory.jsonFactory(rawData.messages) : []
      );
  }
}


export class MessageFactory {
  static jsonFactory(rawData: any): Message[] {
    let datas: Message[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): Message {
      return new Message(
          rawData.message_id,
          rawData.conversation_id,
          rawData.user_id,
          rawData.content,
          rawData.timestamp,
      );
  }
}
