import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-input-calender',
  templateUrl: './dialog-input-calender.component.html',
  styleUrls: ['./dialog-input-calender.component.scss']
})
export class DialogInputCalenderComponent implements OnInit {
  public minDate: Date;
  public show = false;
  public subscription: Subscription;
  public date: Date;
  public today = new Date();
  public month: Date[] = [];
  public selectedDate: Date;
  public time: any;
  public submitted = false;
  public isEditDate = false;
  public submit_data;
  public lastMonthDays: Date[] = [];
  public only_work_days = false;
  public showMonthValue: Date;
  public invalidDate = false;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogInputCalendar$.subscribe((data: any) => {
      this.selectedDate = null;
      this.time = null;
      this.date = null;
      this.submit_data = null;
      this.minDate = null;
      this.only_work_days = false;
      this.submitted = false;
      this.date = data.date;
      this.submit_data = data.submit_data;
      this.minDate = data.min_date;
      this.only_work_days = data.only_work_days;
      this.show = true;
      if(this.date && this.date instanceof String){
        let momentStringArry = data.date.split(',')
        this.date = new Date(
          momentStringArry[1].trim().split('.')[2],
          momentStringArry[1].trim().split('.')[1] - 1,
          momentStringArry[1].trim().split('.')[0]
          );
        this.date.setHours(momentStringArry[2].trim().split(':')[0]);
        this.date.setMinutes(momentStringArry[2].trim().split(':')[1]);
        this.selectedDate = this.date;
      } else if(this.date) {
        this.selectedDate = this.date;
      } else {
        this.date = new Date();
        this.selectedDate = this.date;
      }
      if(this.minDate) this.today = this.minDate;
      else this.minDate = new Date();
      this.showMonthValue = this.minDate;
      this.time = (this.date.getHours() < 10 ? ('0' + this.date.getHours()) : this.date.getHours()) + ':' + (this.date.getMinutes() < 10 ? ('0' + this.date.getMinutes()) : this.date.getMinutes());
      this.month = this.getMonthDays(this.showMonthValue);
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if(this.subscription) this.subscription.unsubscribe();
  }

  getMonthDays(day: Date) : Date[] {
    let days: Date[] = [];
    let year = day.getFullYear();
    let month = day.getMonth();
    let lastDayOfMonth = new Date(year, month + 1, 0);
    let i = 1;
    while(i <= lastDayOfMonth.getDate()) {
      days.push(new Date(year, month, i));
      i++;
    }
    let lastMonthDays: Date[] = [];
    let emptyBoxes = days[0].getDay() == 0 ? 7 : days[0].getDay();
    for(let i = 1; i < emptyBoxes; i++){
      let dateOffset = (24*60*60*1000) * i;
      let day = new Date().setTime(days[0].getTime() - dateOffset);
      lastMonthDays.push(new Date(day));
    }
    this.lastMonthDays = lastMonthDays;
    return days;
  }

  dayIsDisabled(day: Date): boolean {
    if(this.isInThePast(day)){
      return true;
    }
    if(this.only_work_days == true && this.isWorkday(day)){
      return false;
    } else if(!this.only_work_days) {
      return false;
    } else {
      return true;
    }
  }

  isInThePast(day: Date): boolean {
    let check = new Date(this.today.getTime());
    check.setHours(0,0,0,0);
    day.setHours(0,0,0,0);
    if(day.getTime() < check.getTime()) return true;
    else return false;
  }

  isWorkday(day: Date): boolean {
    if(day.getDay() == 6 || day.getDay() == 0) return false;
    else return true;
  }

  isDayToday(day: Date): boolean {
    if(this.isEqualDate(day, this.today)) return true;
    else return false;
  }

  nextMonth(day: Date) {
    let date = new Date(day.getFullYear(), day.getMonth() + 1);
    this.showMonthValue = date;
    this.month = this.getMonthDays(date);
  }

  previousMonth(day: Date) {
    let date = new Date(day.getFullYear(), day.getMonth() - 1);
    this.showMonthValue = date;
    this.month = this.getMonthDays(date);
  }

  selectDateAction(day: Date) {
    if(this.selectedDate && this.time){
      let temp: Date;
      temp = day;
      temp.setHours(Number(this.time.split(':')[0]));
      temp.setMinutes(Number(this.time.split(':')[1]));
      this.selectedDate = temp;
    } else {
      this.selectedDate = day;
    }
    this.month = this.getMonthDays(this.showMonthValue);
  }

  setTime(time: string) {
    this.time = time;
    if(this.selectedDate){
      let temp: Date;
      temp = this.selectedDate;
      temp.setHours(Number(time.split(':')[0]));
      temp.setMinutes(Number(time.split(':')[1]));
      this.selectedDate = temp;
      this.month = this.getMonthDays(this.showMonthValue);
    }
  }

  isSelectedDay(day: Date):boolean {
    if(this.selectedDate && this.isEqualDate(day, this.selectedDate)) return true;
    return false;
  }

  getTime(day): Date {
    if(this.isEqualDate(day, this.selectedDate)){
      return this.selectedDate;
    }
    return null;
  }

  isEqualDate(date_1: Date, date_2: Date): boolean {
    if(date_1.getDate() == date_2.getDate() &&
      date_1.getMonth() == date_2.getMonth() &&
      date_1.getFullYear() == date_2.getFullYear()){
      return true;
    } else {
      return false;
    }
  }

  isValidTime(): boolean {
    if(this.time && this.selectedDate){
      if(this.isEqualDate(this.selectedDate, this.today)){
        let now = new Date(this.minDate.getTime());
        if((Number(this.time.split(':')[1]) < now.getMinutes() &&
          Number(this.time.split(':')[0]) <= now.getHours()) ||
          Number(this.time.split(':')[0]) < now.getHours())
        {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isNotTodaysMonth(day: Date): boolean {
    if(day.getFullYear() == this.today.getFullYear() && day.getMonth() == this.today.getMonth()){
      return false;
    }
    return true;
  }

  closeTimeDialog() {
    this.selectedDate = null;
  }

  onSubmit() {
    this.submitted = true;
    if(this.selectedDate){
      this.dialogService.closeInputCalendar(
        {
          submit_value: this.selectedDate,
          submit_data: this.submit_data
        }
      );
      this.selectedDate = null;
      this.time = null;
      this.date = null;
      this.submit_data = null;
      this.minDate = null;
      this.only_work_days = false;
      this.submitted = false;
      this.show = false;
    }
  }

  closeCalendar() {
    this.dialogService.closeInputCalendar(
      {
        submit_value: null,
        submit_data: null
      }
    );
    this.selectedDate = null;
    this.time = null;
    this.date = null;
    this.submit_data = null;
    this.minDate = null;
    this.only_work_days = false;
    this.submitted = false;
    this.show = false;
  }
}
