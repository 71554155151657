import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GlobalVariables } from './helper/Globavraibles';
/// <reference  types="@types/googlemaps"  />

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hey-way';

  constructor(){
    this.calculateViewDimension();
    window.addEventListener('resize', this.calculateViewDimension)
  }

  isLoginView(outlet: RouterOutlet): boolean {
    return outlet?.activatedRouteData?.isLogin;
  }

  calculateViewDimension() {
    var px_height = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', px_height + 'px');
    var px_width = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', px_width + 'px');
    GlobalVariables.window_width = window.innerWidth;
    GlobalVariables.is_phone_device = window.innerWidth <= 540;
    GlobalVariables.is_tablet_device = window.innerWidth <= 1180;
  }
}
