import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trip } from 'src/app/buisness-object/trip/Trip';

@Component({
  selector: 'app-request-success',
  templateUrl: './request-success.component.html',
  styleUrls: ['./request-success.component.scss']
})
export class RequestSuccessComponent implements OnInit {
  @Input() trip: Trip;
  @Output() goBackToDashboardEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

}
