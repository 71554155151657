<form [formGroup]="form" class="form">
  <label *ngIf="screenWidth > 520 && !success" class="lbl_medium_24 lbl_active">Passwort vergessen</label>
  <img class="logo" src="../../../../assets/passwordbig.svg">
  <div *ngIf="!success" class="inputfield_container" [ngClass]="submitted && form.controls.email.invalid ? 'inputfield_container_invalid' : ''">
    <input class="inputfield" type="text" placeholder="E-Mail-Adresse"
      formControlName="email"
      (change)="submitted = false;">
    <div class="input_valid_indicator" [ngClass]="form.controls.email.invalid ? 'input_valid_indicator_invalid' : ''"></div>
  </div>
  <label *ngIf="success" class="lbl_regular_14">E-Mail wurde abgesendet.</label>
  <label *ngIf="!success" class="lbl_regular_14">Bitte gib deine E-Mail-Adresse bekannt. Du bekommst anschließend eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts.</label>
  <button *ngIf="!success" class="btn_submit" [ngClass]="form.controls.email.invalid ? 'btn_submit_inactive' : ''" [disabled]="form.controls.email.invalid" (click)="onSubmit()">Zurücksetzen</button>
  <button *ngIf="success" class="btn_submit" (click)="toLogin()">Zum Login</button>
  <button *ngIf="screenWidth > 520 && !success" class="btn_label" style="margin-top: 20px;" (click)="toLogin()">zurück</button>
</form>
