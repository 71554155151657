import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DriveService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public loadMapsApi(): Observable<any> {
    const observable = this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAy5VpEkntfl0UxSMhCfMrf9OFLjKcU-mk','callback')
    return observable.pipe(
      map((rawResult: any) => {
        return rawResult;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }
}
