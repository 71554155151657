import { Component, OnInit } from '@angular/core';
import { Faq } from 'src/app/buisness-object/faq/Faq';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  public faqs: Faq[] = [];

  constructor(
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.uService.getFaqs().subscribe((response) => {
      if(response){
        this.faqs = response;
      }
    })
  }

}
