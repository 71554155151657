<form class="page_body" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <label class="lbl_medium_22 lbl_active">Wichtige Infos für deine Mitfahrenden</label>
  <img class="icon" src="../../../../assets/passengers.svg">
  <div class="data_container">
    <label class="lbl_medium_18 lbl_active">Würdest du Umwege für alternative Ein- und Ausstiegsorte machen?</label>
    <div class="checkbox_container">
      <label class="lbl_regular_16">Umwege zulassen</label>
      <div class="checkbox" [ngClass]="form.controls.accept_detours.value ? 'checkbox_checked' : ''" (click)="form.controls.accept_detours.setValue(!form.controls.accept_detours.value)">
        <img *ngIf="form.controls.accept_detours.value == true" src="../../../../assets/successhue.svg">
      </div>
    </div>
  </div>
  <div class="data_container">
    <label class="lbl_medium_18 lbl_active">Für wie viele Mitfahrende hast du Platz?</label>
    <div class="inputfield_container" [ngClass]="submitted && form.controls.passenger_capacity.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="number" min="1" max="9" step="1" placeholder="z.B. 2"
        formControlName="passenger_capacity">
      <div class="input_valid_indicator" [ngClass]="form.controls.passenger_capacity.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
  </div>
  <div class="data_container">
    <label class="lbl_medium_18 lbl_active">Wähle den Kostenbeitrag je Mitfahrenden</label>
    <div class="inputfield_container" [ngClass]="submitted && form.controls.passenger_fee.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="number" min="0.00" step="0.01" placeholder="z.B. 1.00"
        formControlName="passenger_fee">
      <div class="input_valid_indicator" [ngClass]="form.controls.passenger_fee.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
  </div>
  <div class="btn_container">
    <button class="btn_submit" type="button" [ngClass]="!isValid() ? 'btn_submit_inactive' : ''" [disabled]="!isValid()" (click)="nexPage()">Weiter</button>
  </div>
</form>
