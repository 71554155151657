import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginService } from './service/login/login.service';
import { CreateDriveComponent } from './view/create-drive/create-drive.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { LoginComponent } from './view/login/login.component';
import { MyDrivesComponent } from './view/my-drives/my-drives.component';
import { ProfileComponent } from './view/profile/profile.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';
import { VerificationComponent } from './view/verification/verification.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { isLogin: true }
  },
  {
    path: 'verification',
    component: VerificationComponent,
    data: { isLogin: true }
  },
  {
    path: 'user/forgetpassword',
    component: ResetPasswordComponent,
    data: { isLogin: true }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoginService]
  },
  {
    path: 'create-drive',
    component: CreateDriveComponent,
    canActivate: [LoginService]
  },
  {
    path: 'my-drives',
    component: MyDrivesComponent,
    canActivate: [LoginService]
  },
  {
    path: 'my-drives/:tripId',
    component: MyDrivesComponent,
    canActivate: [LoginService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [LoginService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
