import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output , ViewChild, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Location } from 'src/app/buisness-object/location/Location';
import { LocationService } from 'src/app/service/location/location.service';
import moment from 'moment';
import { PredictionPlace } from 'src/app/buisness-object/location/PredictionPlace';
import { MapService } from 'src/app/service/map/map.service';
import { User } from 'src/app/buisness-object/user/User';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit, AfterViewInit {
  @Input() showView: number;
  @Input() user: User;
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() searchEmitter = new EventEmitter<any>();
  public form: FormGroup;
  public submitted = false;
  public screenWidth;
  public showSearch = false;
  public isTablet = GlobalVariables.is_tablet_device;
  public showCalender = false;
  public dialogCalendarSubscription: Subscription;
  @ViewChild('startAddress') startAddress: ElementRef;
  @ViewChild('targetAddress') targetAddress: ElementRef;
  @ViewChild('time_input') time_input: ElementRef;
  public autocomplete__start;
  public autocomplete_target;
  public start_listener;
  public target_listener;
  public importantLocations: Location[] = [];
  public predictionPlaces: PredictionPlace[] = [];
  public predictionPlacesImportantLocations: PredictionPlace[] = [];
  public sessionToken: google.maps.places.AutocompleteSessionToken;
  public showDropdownOne = false;
  public showDropdownTwo = false;
  public listenerOnClick: () => void;
  public listenerArrowkeys: () => void;
  public placeArrowIndex = -1;
  public inputTimeout;
  public lastSearch: {
    start_place: any,
    target_place: any,
    time: any
  };

  constructor(
    private formBuilder: FormBuilder,
    private dService: DialogService,
    private lService: LocationService,
    private renderer: Renderer2,
    private mapService: MapService,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.screenWidth = GlobalVariables.window_width;
    this.lastSearch = JSON.parse(localStorage.getItem('last_search'));
    if(!this.lastSearch){
      this.lastSearch = {
        start_place: null,
        target_place: null,
        time: new Date().getTime()
      }
    }
    moment.locale('de');
    setTimeout(() => {

    }, 1000);
    this.getDataServerside();
    this.configForm();
    this.setDialogSubscription();
  }

  ngAfterViewInit(): void {
    this.listenerOnClick = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropdownOne && !this.startAddress.nativeElement.contains(e.target)) this.showDropdownOne = false;
      if(this.showDropdownTwo && !this.targetAddress.nativeElement.contains(e.target)) this.showDropdownTwo = false;
      this.placeArrowIndex = -1;
    })
    if(window.innerWidth > 1750) {
      if(this.lastSearch){
        this.form.controls.time.setValue(new Date(this.lastSearch.time));
        this.time_input.nativeElement.value = moment(this.lastSearch.time).format('dd., DD.MM.yyyy, HH:mm');
        this.startAddress.nativeElement.value = this.lastSearch.start_place?.location_name ? this.lastSearch.start_place.location_name : '';
        this.targetAddress.nativeElement.value = this.lastSearch.target_place?.location_name ? this.lastSearch.target_place.location_name : '';
      } else {
        this.form.controls.time.setValue(new Date());
        this.time_input.nativeElement.value = moment().format('dd., DD.MM.yyyy, HH:mm');
      }
      this.listenerArrowkeys = this.renderer.listen('document','keydown',(e: KeyboardEvent) => {
        if(this.showDropdownOne || this.showDropdownTwo){
          if(e.key == 'ArrowUp'){
            if(this.placeArrowIndex <= 0) this.placeArrowIndex = (this.predictionPlaces.length - 1);
            else this.placeArrowIndex--;
          } else if(e.key == 'ArrowDown'){
            if(this.placeArrowIndex == (this.predictionPlaces.length - 1)) this.placeArrowIndex = 0;
            else this.placeArrowIndex++;
          } else if(e.key == 'Enter'){
            this.setLocation(this.predictionPlaces[this.placeArrowIndex], (this.showDropdownOne ? 1 : 2))
            this.placeArrowIndex = -1;
          }
          const dropdown = document.getElementById('dropdown');
          const element = document.getElementById('place_'+this.placeArrowIndex);
          if(dropdown && element){
            if(element.offsetTop > (dropdown.offsetHeight)){
              dropdown.scrollTop = element.offsetTop;
            } else if(element.offsetLeft < (dropdown.offsetHeight )){
              dropdown.scrollTop = element.offsetHeight - 32;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    if(this.dialogCalendarSubscription) this.dialogCalendarSubscription.unsubscribe();
    if(this.listenerOnClick) this.listenerOnClick();
    if(this.listenerArrowkeys) this.listenerArrowkeys();
  }

  onResize(e) {
    this.screenWidth = GlobalVariables.window_width;
  }

  configForm() {
    this.form = this.formBuilder.group({
      start: [this.lastSearch ? this.lastSearch.start_place : null, [Validators.required]],
      target: [this.lastSearch ? this.lastSearch.target_place : null, [Validators.required]],
      time: [this.lastSearch ? new Date(this.lastSearch.time) : new Date(), [Validators.required]]
    })
  }

  getPlaces(search: string, input_number: number) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.mapService.getPredictions(search, this.sessionToken, this.importantLocations).then((result) => {
        this.predictionPlaces = result;
        if(this.predictionPlaces.length == 0){
          this.predictionPlaces = this.predictionPlacesImportantLocations;
        } else if(search.includes('magna')) {
          this.predictionPlaces = this.predictionPlacesImportantLocations.concat(this.predictionPlaces);
        }
        if(input_number == 1){
          this.showDropdownOne = true;
        } else if(input_number == 2){
          this.showDropdownTwo = true;
        }
      });
    }, 300);
  }

  setLocation(predictionPlace: PredictionPlace, inputNumber: number) {
    if(this.user.company_id == 1 && predictionPlace.place_id.includes('magna_')){
      let place = new Location(
        0,
        predictionPlace.full_name,
        predictionPlace.latitude,
        predictionPlace.longitude,
        1,
      );
      if(inputNumber == 1){
        this.form.controls.start.setValue(place);
        this.startAddress.nativeElement.value = place.location_name;
        this.lastSearch.start_place = place;

      } else if(inputNumber == 2){
        this.form.controls.target.setValue(place);
        this.targetAddress.nativeElement.value = place.location_name;
        this.lastSearch.target_place = place;
      }
    } else {
      this.mapService.getLocationDetails(predictionPlace).then((result) => {
        if(result){
          if(inputNumber == 1){
            this.form.controls.start.setValue(result);
            this.startAddress.nativeElement.value = result.location_name;
            this.lastSearch.start_place = result;
          } else if(inputNumber == 2){
            this.form.controls.target.setValue(result);
            this.targetAddress.nativeElement.value = result.location_name;
            this.lastSearch.target_place = result;
          }
          this.predictionPlaces = [];
        }
      })
    }
    this.predictionPlaces = this.predictionPlacesImportantLocations;
    this.showDropdownOne = false;
    this.showDropdownTwo = false;
  }

  setDialogSubscription() {
    this.dialogCalendarSubscription = this.dService.closeDialogInputCalender$.subscribe((data) => {
      if(data){
        if(data.submit_data == 'time' && data.submit_value) {
          this.form.controls.time.setValue(data.submit_value);
          this.time_input.nativeElement.value = moment(data.submit_value).format('dd., DD.MM.yyyy, HH:mm');
        }
      }
    });
  }

  getDataServerside() {
    if(this.user){
      this.user = this.user;
      if(this.user.company_id == 1){
        this.lService.getImportantLocations().subscribe((response) => {
          if(response){
            this.importantLocations = response;
            for(let location of this.importantLocations){
              this.predictionPlacesImportantLocations.push(
                new PredictionPlace(
                  'magna_',
                  location.location_name,
                  location.location_name,
                  location.location_name,
                  location.latitude,
                  location.longitude,
                )
              )
            }
            this.predictionPlaces = this.predictionPlacesImportantLocations;
          }
        })
      }
    }
  }

  openSearch() {
    this.showSearch = !this.showSearch;
    if(this.screenWidth <= 1750){
      setTimeout(() => {
        if(this.lastSearch){
          this.form.controls.time.setValue(new Date(this.lastSearch.time));
          this.time_input.nativeElement.value = moment(this.lastSearch.time).format('dd., DD.MM.yyyy, HH:mm');
          this.startAddress.nativeElement.value = this.lastSearch.start_place.location_name;
          this.targetAddress.nativeElement.value = this.lastSearch.target_place.location_name;
        } else {
          this.form.controls.time.setValue(new Date());
          this.time_input.nativeElement.value = moment().format('dd., DD.MM.yyyy, HH:mm');
        }
      }, 0);
    }
  }

  openCalender() {
    this.time_input.nativeElement.blur();
    this.dService.openInputCalendar({
      date: this.form.controls.time.value ? this.form.controls.time.value : null,
      submit_data: 'time'
    });
  }

  checkValid() {
    return this.form.valid;
  }

  onSubmit() {
    let searchData = {
      start_lat: this.form.controls.start.value.latitude,
      start_lng: this.form.controls.start.value.longitude,
      target_lat: this.form.controls.target.value.latitude,
      target_lng: this.form.controls.target.value.longitude,
      timestamp: this.form.controls.time.value.getTime(),
      start_description: this.form.controls.start.value.location_name,
      target_description: this.form.controls.target.value.location_name,
    }
    this.lastSearch.time = this.form.controls.time.value.getTime();
    localStorage.setItem('last_search', JSON.stringify(this.lastSearch));
    this.showSearch = false;
    this.searchEmitter.emit(searchData);
  }

  closeSearch() {
    this.showSearch = false;
  }

  isMagnaPlace(place_id: string): boolean {
    if(place_id.includes('magna')) return true;
    else return false;
  }

  backAction() {
    this.backEmitter.emit()
    setTimeout(() => {
      this.time_input.nativeElement.value = moment(new Date(this.form.controls.time.value)).format('dd., DD.MM.yyyy, HH:mm');
      this.startAddress.nativeElement.value = this.form.controls.start.value.location_name;
      this.targetAddress.nativeElement.value = this.form.controls.target.value.location_name;
    }, 50);
  }
}
