import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Trip } from 'src/app/buisness-object/trip/Trip';

@Component({
  selector: 'app-drive-list-element',
  templateUrl: './drive-list-element.component.html',
  styleUrls: ['./drive-list-element.component.scss']
})
export class DriveListElementComponent implements OnInit {
  @Input() trip: Trip;
  @Input() isCreatedTrips: boolean;
  @Output() openDetailsEmitter = new EventEmitter<Trip>();
  public screenWidth;

  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  iaMagnaLocation(str: string): boolean {
    if(str.toLowerCase().includes('magna')) return true;
    else return false;
  }
}
