import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DriveService } from 'src/app/service/drive/drive.service';
import { MapService } from 'src/app/service/map/map.service';

@Component({
  selector: 'app-page-two',
  templateUrl: './page-two.component.html',
  styleUrls: ['./page-two.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageTwoComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() isEdit: boolean;
  @Output() nextPageEmitter = new EventEmitter<undefined>();
  public routesResult: google.maps.DirectionsResult;
  public selectedRouteIndex = 0;
  public routes: google.maps.DirectionsRoute[] = [];

  constructor(
    private dService: DriveService,
    private mapService: MapService
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getRoutes();
  }

  getRoutes() {
    let start;
    let target;
    if(!this.isEdit){
      start = new google.maps.LatLng(this.form.controls.start.value.latitude,this.form.controls.start.value.longitude);
      target = new google.maps.LatLng(this.form.controls.target.value.latitude,this.form.controls.target.value.longitude);
    } else {
      start = new google.maps.LatLng(this.form.controls.start_latitude.value,this.form.controls.start_longitude.value);
      target = new google.maps.LatLng(this.form.controls.target_latitude.value,this.form.controls.target_longitude.value);
    }
    this.mapService.initializeMap(start,target,'map').then((result) => {
      this.routesResult = result;
      this.form.controls.route.setValue(this.routesResult.routes[0])
    });
  }

  getTime(route: google.maps.DirectionsRoute): string {
    let mins = Math.ceil(route.legs[0].duration.value / 60);
    let min = mins % 60;
    let hour = (mins - min) / 60;
    return hour + ' Std. ' + min + ' Min.';
  }
  getDistance(route: google.maps.DirectionsRoute): number {
    let m = Math.ceil(route.legs[0].distance.value / 1000);
    return m;
  }

  selectRoute(index: number) {
    this.selectedRouteIndex = index;
    this.mapService.hightlightRoute(index, this.routesResult);
    this.form.controls.route.setValue(this.routesResult.routes[index]);
  }

  checkValid(): boolean {
    return true;
  }
  nexPage() {
    this.nextPageEmitter.emit();
  }
}

