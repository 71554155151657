import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  @Input() foundTrips: Trip[];
  @Output() bookTripEmitter = new EventEmitter<Trip>();

  constructor(
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.getAvatars();
  }

  async getAvatars() {
    if(this.foundTrips.length > 0){
      for(let trip of this.foundTrips){
        trip.driver = await this.getAvatarServerside(trip.driver);
      }
    }
  }

  getAvatarServerside(user: User): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.uService.getAvatar(user.user_id).subscribe((avatar) => {
        if(avatar){
          user.avatar = avatar.data;
          resolve(user)
        }
      })
    })
  }
}
