import { Component, ElementRef, EventEmitter, Input, OnDestroy, AfterViewInit, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-date-last',
  templateUrl: './dialog-date-last.component.html',
  styleUrls: ['./dialog-date-last.component.scss']
})
export class DialogDateLastComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() form: FormGroup;
  @Input() isReturnTrip: boolean;
  @Output() submitEmitter = new EventEmitter<undefined>();
  @Output() closeDialogEmitter = new EventEmitter<undefined>();

  @ViewChild('time_input') time_input: ElementRef;
  public dialogCalendarSubscription: Subscription;
  public pickedDate: Date;
  public minDate: Date;

  constructor(
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    if(this.isReturnTrip){
      this.pickedDate = this.form.controls.return_last_trip.value;
      let d = new Date(this.form.controls.return_time.value.getTime());
      d.setHours(this.form.controls.return_time.value.getHours());
      d.setMinutes(this.form.controls.return_time.value.getMinutes());
      this.minDate = d;
    } else {
      this.pickedDate = this.form.controls.last_trip.value;
      let d = new Date(this.form.controls.time.value.getTime());
      d.setHours(this.form.controls.time.value.getHours());
      d.setMinutes(this.form.controls.time.value.getMinutes());
      this.minDate = d;
    }
    moment.locale('de');
    this.setDialogSubscription();
  }

  ngAfterViewInit(): void {
    if(!this.isReturnTrip && this.form.controls.last_trip.value){
      this.time_input.nativeElement.value = moment(this.form.controls.last_trip.value).format('dd., DD.MM.yyyy, HH:mm');
    } else if(this.isReturnTrip && this.form.controls.return_last_trip.value) {
      this.time_input.nativeElement.value = moment(this.form.controls.return_last_trip.value).format('dd., DD.MM.yyyy, HH:mm');
    }
  }

  ngOnDestroy(): void {
    if(this.dialogCalendarSubscription) this.dialogCalendarSubscription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogCalendarSubscription = this.dService.closeDialogInputCalender$.subscribe((data) => {
      if(data){
        if(data.submit_data == 'time' && data.submit_value){
          this.pickedDate = data.submit_value;
          this.time_input.nativeElement.value = moment(data.submit_value).format('dd., DD.MM.yyyy, HH:mm');
        }
      }
    });
  }

  onSubmit() {
    if(this.isReturnTrip) this.form.controls.return_last_trip.setValue(this.pickedDate);
    else this.form.controls.last_trip.setValue(this.pickedDate);
    this.submitEmitter.emit();
  }

  backAction() {
    this.closeDialogEmitter.emit();
  }

  openCalender() {
    this.time_input.nativeElement.blur();
    this.dService.openInputCalendar({
      date: this.pickedDate ? new Date(this.pickedDate.getTime()) : new Date(this.minDate.getTime()),
      submit_data: 'time',
      min_date: new Date(this.minDate.getTime()),
      only_work_days: this.isReturnTrip ? this.form.controls.return_several_time_typ.value == 3 : this.form.controls.several_time_typ.value == 3
    });
  }

  checkValid() {
    return this.pickedDate;
    //return this.isReturnTrip ? this.form.controls.return_last_trip.value : this.form.controls.last_trip.value;
  }
}
