<form class="page_body" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <label *ngIf="!isPhone && !isEdit" class="lbl_medium_22 lbl_active">Erstelle deine gewünschte Fahrt</label>
  <img class="icon" src="../../../../assets/editmycar.svg">
  <div class="input_container">
    <div class="input_container_one">
      <img class="input_container_one_icon" src="../../../assets/start.svg">
      <div class="inputfield_container" >
        <input #startAddress class="inputfield" type="text" placeholder="Start" (input)="this.form.controls.start.setValue(null); getPlaces(startAddress.value, 1);" (focus)="showDropdownOne = true">
        <div class="input_valid_indicator" [ngClass]="!form.controls.start.value ? 'input_valid_indicator_invalid' : ''"></div>
        <div *ngIf="showDropdownOne && predictionPlaces.length > 0" class="dropdown_cotainer">
          <div id="dropdown" class="dropdown_scroll_wrapper">
            <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''"  (click)="setLocation(place, 1)">
              <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
              <div>{{place.full_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="switch_location_icon" src="../../../../assets/switch.svg" (click)="switchLocations()">
    <div class="input_container_one">
      <img class="input_container_one_icon" src="../../../assets/end.svg">
      <div class="inputfield_container">
        <input #targetAddress class="inputfield" type="text" placeholder="Ziel" (input)="this.form.controls.target.setValue(null); getPlaces(targetAddress.value, 2);" (focus)="showDropdownTwo = true">
        <div class="input_valid_indicator" [ngClass]="!form.controls.target.value ? 'input_valid_indicator_invalid' : ''"></div>
        <div *ngIf="showDropdownTwo && predictionPlaces.length > 0" class="dropdown_cotainer">
          <div id="dropdown" class="dropdown_scroll_wrapper">
            <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''" (click)="setLocation(place, 2)">
              <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
              <div>{{place.full_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input_container_one" [ngClass]="submitted && form.controls.time.invalid ? 'inputfield_container_invalid' : ''">
      <img class="input_container_one_icon" src="../../../assets/calender.svg">
      <div class="inputfield_container">
        <input #time_input class="inputfield" placeholder="Zeit" readonly
          formControlName="time"
          (focus)="openCalender()"
          (click)="openCalender()">
        <div class="input_valid_indicator" [ngClass]="form.controls.time.invalid ? 'input_valid_indicator_invalid' : ''"></div>
      </div>
    </div>
  </div>
  <div class="btn_container">
    <button class="btn_submit" type="button" [ngClass]="!isValid() ? 'btn_submit_inactive' : ''"  (click)="nexPage()">Weiter</button>
    <button *ngIf="!isEdit && showTemplateBtn" type="button" class="btn_submit_secondary" (click)="showTemplatesEmitter.emit()">Vorlage verwenden</button>
  </div>
</form>
