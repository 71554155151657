<div id="view_body_list" class="view_body">
  <div class="column_1">
    <div class="company_logo_container">
      <img *ngIf="true" class="company_logo" [src]="getLogo()">
    </div>
    <div class="avatar_wrapper">
      <img class="icon_firework" src="../../../../assets/firework.png">
      <div class="avatar_container">
        <img [ngClass]="user?.avatar ? 'avatar' : 'avatar_placeholder'" [src]="user?.avatar ? ('data:image/png;base64,' + user?.avatar) : '../../../assets/userimageplaceholder.svg'">
      </div>
    </div>
    <label class="lbl_black_80">{{user.ranking}}.</label>
    <label class="lbl_regular_16">{{user.amount_of_trips}} Fahrten</label>
    <label class="lbl_regular_16">Damit hast du {{user.footprint / 1000 | number: '1.2-2'}} kg CO<sub>2</sub> gespart</label>
    <button class="btn_submit_secondary lbl_medium_14 lbl_active" (click)="showUser()">Meine Platzierung anzeigen</button>
  </div>
  <div class="column_2" id="heighscore_list">
    <div class="element_wrapper" *ngFor="let user of ranking; let i = index" [attr.id]="'user_'+user.user_id">
      <div class="element_flex">
        <div class="element_avatar_container">
          <img [ngClass]="user?.avatar ? 'avatar' : 'element_avatar_placeholder'" [src]="user?.avatar ? ('data:image/png;base64,' + user?.avatar) : '../../../assets/userimageplaceholder.svg'">
        </div>
        <div class="element_info_container">
          <Label class="lbl_regular_16">{{user.first_name}}</Label>
          <Label class="lbl_regular_12">{{user.footprint / 1000 | number: '1.2-2'}} kg CO<sub>2</sub> gespart in {{user.amount_of_trips}} Fahrten</Label>
        </div>
      </div>
      <Label class="lbl_black_32 lbl_ranking">{{user.ranking}}.</Label>
    </div>
    <div class="element_wrapper" *ngIf="user.ranking > 10" [attr.id]="'user_'+user.user_id">
      <div class="element_flex">
        <div class="element_avatar_container">
          <img [ngClass]="user?.avatar ? 'avatar' : 'element_avatar_placeholder'" [src]="user?.avatar ? ('data:image/png;base64,' + user?.avatar) : '../../../assets/userimageplaceholder.svg'">
        </div>
        <div class="element_info_container">
          <Label class="lbl_regular_16">{{user.first_name}}</Label>
          <Label class="lbl_regular_12">{{user.footprint / 1000 | number: '1.2-2'}} kg CO<sub>2</sub> gespart in {{user.amount_of_trips}} Fahrten</Label>
        </div>
      </div>
      <Label class="lbl_black_32 lbl_ranking">{{user.ranking}}.</Label>
    </div>
  </div>
</div>
