export class Conversation {
  constructor(
    public conversation_id: number,
    public trip_id: number,
    public user_id1: number,
    public user_id2: number,
    public messages: Message[]
  ){}
}

export class Message {
  constructor(
    public message_id: number,
    public conversation_id: number,
    public user_id: number,
    public content: string,
    public timestamp: number
  ){}
}
