<div id="dialog_window">
  <div class="close_icon" (click)="closeDialogEmitter.emit()">&#10005;</div>
  <div id="dialog_container">
    <label class="lbl_medium_22 lbl_active title">Wann findet die letzte Fahrt deiner Serienfahrt statt?</label>
    <div class="body">
      <div class="input_container_one">
        <img class="input_container_one_icon" src="../../../assets/calender.svg">
        <div class="inputfield_container">
          <input #time_input class="inputfield" placeholder="Zeit"
            (focus)="openCalender()"
            (click)="openCalender()">
          <div class="input_valid_indicator" [ngClass]="!pickedDate ? 'input_valid_indicator_invalid' : ''"></div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <button class="btn_gray lbl_black_16" (click)="backAction()">Zurück</button>
      <button class="btn_submit lbl_black_16" [ngClass]="!checkValid() ? 'btn_submit_inactive' : ''" [disabled]="!checkValid()" (click)="onSubmit()">Speichern</button>
    </div>
  </div>
</div>
