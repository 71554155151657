import { Injectable } from '@angular/core';
import { Location } from 'src/app/buisness-object/location/Location';
import { PredictionPlace } from 'src/app/buisness-object/location/PredictionPlace';

@Injectable({
  providedIn: 'root'
})

//https://developers.google.com/maps/documentation/cloud-customization/design-resources

export class MapService {

  public mystyle = [
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    },{
      featureType: "landscape",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    },{
      featureType: "poi",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    },{
      featureType: "road",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    },{
      featureType: "transit",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    },{
      featureType: "water",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    }
  ]

  constructor() { }

  initializeMap(start,target, element_name_id: string): Promise<google.maps.DirectionsResult> {
    return new Promise<google.maps.DirectionsResult>((resolve, reject) => {
      const map = new google.maps.Map(
        element_name_id ? document.getElementById(element_name_id) as HTMLElement : document.createElement('div'),
        {
          zoom: 3,
          disableDefaultUI: true,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          // mapTypeControlOptions: {
          //   mapTypeIds: ['mystyle', google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN]
          // },
          // mapTypeId: 'mystyle',
        }
      );
      //map.mapTypes.set('mystyle', new google.maps.StyledMapType(this.mystyle, { name: 'My Style' }));
      // let startMarker = new google.maps.Marker({
      //   position: this.startLocation,
      //   icon: {
      //     url: '../../../../assets/editmycar.svg',
      //     anchor: new google.maps.Point(35,10),
      //     scaledSize: new google.maps.Size(100,100),
      //   },
      //   map: this.map
      // });
      const heighlighted = {
        strokeColor: '#3483eb',
        strokeOpacity: 1.0,
        strokeWeight: 3
      };
      const interessiertKan = {
        strokeColor: '#3483eb',
        strokeOpacity: 0.6,
        strokeWeight: 3
      }
      const directionService = new google.maps.DirectionsService();
      directionService.route({
        origin: start,
        destination: target,
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
        unitSystem: 0,
        avoidHighways: false,
        avoidTolls: false,
      })
      .then((result: google.maps.DirectionsResult) => {
        for(let i = 0; i < result.routes.length; i++){
          new google.maps.DirectionsRenderer({
            map: map,
            directions: result,
            routeIndex: i,
            polylineOptions : i == 0 ? heighlighted : interessiertKan
          })
          // new google.maps.Marker({
          //   position: start,
          //   icon: {
          //     url: '../../../../assets/pin-map.svg',
          //     anchor: new google.maps.Point(35,10),
          //     scaledSize: new google.maps.Size(50,50),
          //   },
          //   map: map
          // })
        }
        resolve(result);
      })
      .catch((e) => {
        alert("Could not display directions due to: " + e);
      });
    })
  }

  hightlightRoute(index: number, routesResult: google.maps.DirectionsResult) {
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 3,
        disableDefaultUI: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    );
    const heighlighted = {
      strokeColor: '#3483eb',
      strokeOpacity: 1.0,
      strokeWeight: 4
    };
    const interssiertKan = {
      strokeColor: '#3483eb',
      strokeOpacity: 0.6,
      strokeWeight: 4
    }
    for(let i = 0; i < routesResult.routes.length; i++){
      new google.maps.DirectionsRenderer({
        map: map,
        directions: routesResult,
        routeIndex: i,
        polylineOptions : i == index ? heighlighted : interssiertKan
      })
    }
  }

  getPredictions(search: string, token: google.maps.places.AutocompleteSessionToken, importantLocations: Location[]): Promise<PredictionPlace[]> {
    return new Promise<PredictionPlace[]>((resolve, reject) => {
      let predictionPlaces: PredictionPlace[] = [];
      const companyIndicator = 'magna_'
      if(search && search.length > 0){
        const aService = new google.maps.places.AutocompleteService();
        aService.getPlacePredictions({
          input: search,
          componentRestrictions: { country:  ['at','si','de'] },
          sessionToken: token
        }).then((result) => {
          for(let location of importantLocations){
            if(location.location_name.toLowerCase().includes(search.toLowerCase())) {
              var predictionPlace = new PredictionPlace();
              predictionPlace.place_id = companyIndicator + location.location_id;
              predictionPlace.full_name = location.location_name;
              predictionPlace.first_line = location.location_name;
              predictionPlace.second_line = "";
              predictionPlace.latitude = location.latitude;
              predictionPlace.longitude = location.longitude;
              predictionPlaces.push(predictionPlace);
            }
          }
          for(let prediction of result.predictions) {
            var predictionPlace = new PredictionPlace();
            predictionPlace.place_id = prediction.place_id;
            predictionPlace.full_name = prediction.description.replace(', Österreich','');
            predictionPlace.first_line = prediction.structured_formatting.main_text;
            predictionPlace.second_line = prediction.structured_formatting.secondary_text;
            predictionPlaces.push(predictionPlace);
          }
          resolve(predictionPlaces);
        });
      } else {
        resolve([]);
      }
    })
  }

  getLocationDetails(predictionPlace: PredictionPlace): Promise<Location> {
    return new Promise<Location>((resolve, reject) => {
      const aService = new google.maps.places.PlacesService(document.createElement('div'));
      const request = {
        placeId: predictionPlace.place_id,
        fields: ["name", "formatted_address", "place_id", "geometry"],
      }
      aService.getDetails(request, (result) => {
        let place = new Location(
          0,
          result.formatted_address,
          result.geometry.location.lat(),
          result.geometry.location.lng(),
          0,
        );
        resolve(place);
      });
    })
  }
}
