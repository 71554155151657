<div class="view_body">
  <label class="lbl_medium_24 lbl_active">Deine Meinung ist uns wichtig!</label>
  <div class="body">
    <img class="logo" src="../../../../assets/waymarkred.svg">
    <div class="data_container">
      <label class="lbl_regular_14">E-Mail-Adresse</label>
      <label class="lbl_regular_14 lbl_active" onclick="location.href='mailto:support@waymark.at'">support@waymark.at</label>
    </div>
    <div class="data_container">
      <label class="lbl_regular_14">Mehr Informationen findest du auf:</label>
      <label class="lbl_regular_14 lbl_active" style="cursor: pointer;" (click)="openWebsite()">www.hey-way.com</label>
    </div>
  </div>
</div>
