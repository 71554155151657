<div class="view_body">
  <form [formGroup]="form" class="form_profile">
    <label class="lbl_medium_24 lbl_active">Wähle dein neues Passwort</label>
    <img class="icon" src="../../../../assets/passwordbig.svg">
    <div class="inputfield_container" [ngClass]="submitted && form.controls.password_old.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="{{showPassword_1 ? 'text' : 'password'}}" placeholder="Altes Passwort"
        formControlName="password_old">
      <img class="icon_password" (click)="showPassword_1 = !showPassword_1" [src]="showPassword_1 ? '../../../assets/inputhide.svg' : '../../../assets/inputshow.svg'">
      <div class="input_valid_indicator" [ngClass]="form.controls.password_old.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <div class="inputfield_container" [ngClass]="submitted && form.controls.password_new.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="{{showPassword_2 ? 'text' : 'password'}}" placeholder="Neues Passwort"
        formControlName="password_new">
      <img class="icon_password" (click)="showPassword_2 = !showPassword_2" [src]="showPassword_2 ? '../../../assets/inputhide.svg' : '../../../assets/inputshow.svg'">
      <div class="input_valid_indicator" [ngClass]="form.controls.password_new.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <label class="lbl_regular_14">Such dir ein Passwort aus, das zumindest aus einem Buchstaben und einer Zahl besteht. Es muss aus min. 5 Zeichen bestehen.</label>
    <button class="btn_submit" [ngClass]="form.invalid ? 'btn_submit_inactive' : ''" [disabled]="form.invalid" (click)="change()">Ändern</button>
  </form>
</div>
