<form [formGroup]="form" [ngClass]="success ? 'form_success' : 'form'" *ngIf="!showAgb && !showPrivacy">
  <img class="logo" src="../../../../assets/register.svg" [ngStyle]="{'margin-bottom': !success ? '8vh' : '60px'}">
  <div *ngIf="!success" class="content_wrapper">
    <div class="line_one">
      <div class="inputfield_container" [ngClass]="submitted && form.controls.first_name.invalid ? 'inputfield_container_invalid' : ''">
        <input class="inputfield line_one_inputfield" type="text" placeholder="Vorname"
          formControlName="first_name"
          (change)="submitted = false;">
        <div class="input_valid_indicator" [ngClass]="form.controls.first_name.invalid ? 'input_valid_indicator_invalid' : ''"></div>
      </div>
      <div class="inputfield_container" [ngClass]="submitted && form.controls.last_name.invalid ? 'inputfield_container_invalid' : ''">
        <input class="inputfield line_one_inputfield" type="text" placeholder="Nachname"
          formControlName="last_name"
          (change)="submitted = false;">
        <div class="input_valid_indicator" [ngClass]="form.controls.last_name.invalid ? 'input_valid_indicator_invalid' : ''"></div>
      </div>
    </div>
    <!-- <div class="inputfield_container" [ngClass]="submitted && form.controls.birthdate.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="datetime" placeholder="Geburtsdatum"
        formControlName="birthdate"
        (click)="openCalender()"
        (change)="submitted = false;">
      <div class="input_valid_indicator" [ngClass]="form.controls.birthdate.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div> -->
    <div class="inputfield_container" [ngClass]="submitted && form.controls.birthdate.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="datetime" placeholder="Geburtsdatum"
        formControlName="birthdate"
        (click)="datepicker.open()"
        (focus)="datepicker.open()"
        (change)="submitted = false;"
        [matDatepicker]="datepicker"
        [max]="maxDate"
        matInput>
      <mat-datepicker-toggle matSuffix [for]="datepicker" style="transform: translate(-65%, 0px);"></mat-datepicker-toggle>
            <mat-datepicker #datepicker
      ></mat-datepicker>
      <div class="input_valid_indicator" [ngClass]="form.controls.birthdate.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <div class="inputfield_container" [ngClass]="submitted && form.controls.email.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="text" placeholder="E-Mail-Adresse"
        formControlName="email"
        (change)="submitted = false;">
      <div class="input_valid_indicator" [ngClass]="form.controls.email.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <div class="inputfield_container" [ngClass]="submitted && form.controls.password.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="{{showPassword ? 'text' : 'password'}}" placeholder="Passwort"
        formControlName="password"
        (change)="submitted = false">
      <img class="icon_password" (click)="showPassword = !showPassword" [src]="showPassword ? '../../../assets/inputhide.svg' : '../../../assets/inputshow.svg'">
      <div class="input_valid_indicator" [ngClass]="form.controls.password.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <div>
      <div class="checkbox_container">
        <label>Als Mitarbeiter_in von Magna registrieren</label>
        <div class="checkbox" [ngClass]="form.controls.is_company.value ? 'checkbox_checked' : ''" (click)="form.controls.is_company.setValue(!form.controls.is_company.value)">
          <img *ngIf="form.controls.is_company.value == true" src="../../../../assets/successhue.svg">
        </div>
      </div>

      <!-- <label class="lbl_regular_16 title_data">Zustimmen</label> -->
      <div class="checkbox_container">
        <label class="">Ich erkläre mich mit den <span style="cursor: pointer;" (click)="showPrivacy = false; showAgb = !showAgb">AGB</span> einverstanden</label>
        <div class="checkbox" [ngClass]="form.controls.agb_accepted.value ? 'checkbox_checked' : ''" (click)="form.controls.agb_accepted.setValue(!form.controls.agb_accepted.value)">
          <img *ngIf="form.controls.agb_accepted.value == true" src="../../../../assets/successhue.svg">
        </div>
      </div>
      <div class="checkbox_container">
        <label class="">Ich erkläre mich mit der <span style="cursor: pointer;" (click)="showAgb = false; showPrivacy = !showPrivacy">Datenschutzerklärung</span> einverstanden</label>
        <div class="checkbox" [ngClass]="form.controls.dataprivacy_accepted.value ? 'checkbox_checked' : ''" (click)="form.controls.dataprivacy_accepted.setValue(!form.controls.dataprivacy_accepted.value)">
          <img *ngIf="form.controls.dataprivacy_accepted.value == true" src="../../../../assets/successhue.svg">
        </div>
      </div>

      <!-- <div class="checkbox_container">
        <div class="data_privacy_box">
          <img class="icon_data" src="../../../../assets/legal.svg">
          <div class="flex_data_privacy_box">
            <label class="lbl_regular_16 title_data">Zustimmen</label>
            <label class="lbl_regular_14">Ich erkläre mich mit den <span style="cursor: pointer;" (click)="showPrivacy = false; showAgb = !showAgb">AGB</span> sowie mit der <span style="cursor: pointer;" (click)="showAgb = false; showPrivacy = !showPrivacy">Datenschutzerklärung</span> einverstanden</label>
          </div>
        </div>
        <div class="checkbox" [ngClass]="form.controls.dataprivacy_accepted.value ? 'checkbox_checked' : ''" (click)="form.controls.dataprivacy_accepted.setValue(!form.controls.dataprivacy_accepted.value)">
          <img *ngIf="form.controls.dataprivacy_accepted.value == true" src="../../../../assets/successhue.svg">
        </div>
      </div> -->
    </div>
    <div class="btn_container">
      <label [ngStyle]="{'opacity': registerFailure ? '1' : '0'}" class="lbl_invalid">E-Mail-Adresse existiert bereits.</label>
      <button class="btn_submit" [ngClass]="!checkValid() ? 'btn_submit_inactive' : ''" [disabled]="!checkValid()" (click)="onSubmit()">Jetzt registrieren</button>
      <div class="flex_login_box">
        <label class="lbl_medium_18">Du besitzt bereits ein Konto? </label>
        <button class="btn_label" (click)="toLogin()">Jetzt Anmelden</button>
      </div>
    </div>
  </div>
  <div *ngIf="success">
    <label class="lbl_regular_20">Registrierung erfolgreich !</label>
    <button class="btn_submit" style="margin-top: 50px;" (click)="toLogin()">Zum Login</button>
  </div>
</form>
<div *ngIf="showAgb && !showPrivacy" class="iframe_links">
  <button class="btn_label" style="margin-bottom: 20px;" (click)="showAgb = false">Zurück</button>
  <iframe src="https://test-api.hey-way.com/agb.html" frameborder="0" width="100%" height="100%"></iframe>
</div>
<div *ngIf="!showAgb && showPrivacy" class="iframe_links">
  <button class="btn_label" style="margin-bottom: 20px;" (click)="showPrivacy = false">Zurück</button>
  <iframe class="iframe_wrapper" src="https://test-api.hey-way.com/dataprivacy.html" frameborder="0"></iframe>
</div>
