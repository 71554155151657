import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Conversation, Message } from 'src/app/buisness-object/conversation/Conversation';
import { ConversationFactory, MessageFactory } from 'src/app/buisness-object/conversation/factory/ConversationFactory';
import { FaqFactory } from 'src/app/buisness-object/faq/factory/FaqFactory';
import { SloganFactory } from 'src/app/buisness-object/faq/factory/SloganFactory';
import { Faq } from 'src/app/buisness-object/faq/Faq';
import { Slogan } from 'src/app/buisness-object/faq/Slogan';
import { TripFactory } from 'src/app/buisness-object/trip/factory/TripFactory';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { UserFactory } from 'src/app/buisness-object/user/factory/UserFactory';
import { User } from 'src/app/buisness-object/user/User';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user_data$ = new BehaviorSubject<any>(null);
  public notify_request$  = new BehaviorSubject<any>(0);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public getUser(id: number, reload?: boolean): Observable<any> {
    if(!reload && this.user_data$.getValue()){
      return of(this.user_data$.getValue())
    } else {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('token')
      });
      const observable = this.http.get(environment.api + '/app/user/'+id, { headers });
      return observable.pipe(
        map((rawResult: any) => {
          const user = UserFactory.jsonFactoryOne(rawResult.user);
          GlobalVariables.amount_of_pending_requests = 0;
          const created_trips = rawResult.created_trips ? TripFactory.jsonFactory(rawResult.created_trips) : undefined;
          const joined_trips = rawResult.joined_trips ? TripFactory.jsonFactory(rawResult.joined_trips) : undefined;
          const templates = rawResult.templates ? TripFactory.jsonFactory(rawResult.templates) : undefined;
          const conversations = rawResult.conversations ? ConversationFactory.jsonFactory(rawResult.conversations) : undefined;
          this.notify_request$.next(GlobalVariables.amount_of_pending_requests);
          let user_data = {
            user: user,
            created_trips: created_trips,
            joined_trips: joined_trips,
            templates: templates,
            conversations: conversations
          }
          this.user_data$.next(user_data);
          return user_data;
        }),catchError(error => {
          if(error.status == 403){
            this.lService.logout();
          } else {
            console.log(error);
            this.dService.showNotification({
              title: 'Fehler',
              message: 'Fehlermeldung: ' + error.error.error_message,
              success: false
            });
          }
          return [];
        })
      );
    }
  }

  updateUser(user: User): Observable<User> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/app/user',
    {
      "user" :
      {
        "user_id": user.user_id,
        "first_name" : user.first_name,
        "last_name" : user.last_name,
        "birthdate" : user.birthdate,
        "allow_phonenumber" : user.allow_phonenumber,
        "phonenumber": user.phonenumber,
        "push_enabled": user.push_enabled,
        "email_enabled" : user.email_enabled,
        "allowed_detour" : user.allowed_detour,
        "vehicle" : {
            "vehicle_color": user.vehicle?.vehicle_color,
            "vehicle_brand_and_model": user.vehicle?.vehicle_brand_and_model,
        }
      }
    }, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        const data = UserFactory.jsonFactoryOne(rawResult.user);
        let user_data = {
          user: data,
          created_trips: this.user_data$.getValue().created_trips,
          joined_trips: this.user_data$.getValue().joined_trips,
          templates: this.user_data$.getValue().templates
        }
        this.user_data$.next(user_data);
        return data;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateUserPassword(old_password: string, new_password: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/app/user/password',
    {
      old_password: old_password,
      new_password: new_password
    },
    {headers});
    return observable.pipe(
      map((rawResult: any) => {
        return true;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public deleteUser(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/app/user/'+id,
    {headers});
    return observable.pipe(
      map((rawResult: any) => {
        this.lService.logout();
        return true;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public getAvatar(id: number): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/app/user/avatar/'+id, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        return rawResult;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createAvatar(user_id: number, src: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/app/user/avatar', {
      user_id: user_id,
      picture: src
    },{ headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),
      catchError((error) => {
        if(error.status == 402){
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        console.log(error);
        return of(false);
      })
    );
  }

  confirmPassenger(trip_id: number, user_id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/app/trip/'+ trip_id + '/confirm',
    {
      "user_id": user_id
    }, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        GlobalVariables.amount_of_pending_requests--;
        this.notify_request$.next(GlobalVariables.amount_of_pending_requests);
        return true;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  refusePassenger(trip_id: number, user_id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/app/trip/'+ trip_id + '/refuse',
    {
      "user_id": user_id
    }, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        GlobalVariables.amount_of_pending_requests--;
        this.notify_request$.next(GlobalVariables.amount_of_pending_requests);
        return true;
      }),catchError(error => {
        console.log(error);
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return of(false);
      })
    );
  }

  public getHighscore(): Observable<User[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/app/highscore', { headers });
    return observable.pipe(
      map((rawResult: any) => {
        let users = UserFactory.jsonFactory(rawResult.ranking);
        users.sort((a,b) => {
          if(a.ranking > b.ranking) return 1;
          if(a.ranking < b.ranking) return -1;
          return 0;
        })
        return users;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getFaqs(): Observable<Faq[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/app/faq', { headers });
    return observable.pipe(
      map((rawResult: any) => {
        let data = FaqFactory.jsonFactory(rawResult.faqs)
        return data;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public getSlogans(): Observable<Slogan[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/app/slogan', { headers });
    return observable.pipe(
      map((rawResult: any) => {
        let data = SloganFactory.jsonFactory(rawResult.slogans)
        return data;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createConversation(conversation: Conversation): Observable<Conversation> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/app/conversation', {
      trip_id: conversation.trip_id,
      user_id1: conversation.user_id1,
      user_id2: conversation.user_id2,
      message: {
        user_id: conversation.messages[0].user_id,
        content: conversation.messages[0].content
      }
    },{ headers });
    return observable.pipe(
      map((result: any) => {
        let data = ConversationFactory.jsonFactoryOne(result.conversation)
        return data;
      }),
      catchError((error) => {
        if(error.status == 402){
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        console.log(error);
        return [];
      })
    );
  }

  public getConversation(id: number): Observable<Conversation> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/app/conversation/' + id, { headers });
    return observable.pipe(
      map((rawResult: any) => {
        let data = ConversationFactory.jsonFactoryOne(rawResult.conversation)
        return data;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: 'Fehlermeldung: ' + error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createMessage(message: Message) : Observable<Message> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/app/message',
    {
      content: message.content,
      user_id: message.user_id,
      conversation_id: message.conversation_id
    },{ headers });
    return observable.pipe(
      map((result: any) => {
        let data = MessageFactory.jsonFactoryOne(result.message)
        return data;
      }),
      catchError((error) => {
        if(error.status == 402 || error.status == 401){
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        console.log(error);
        return [];
      })
    );
  }
}
