import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { User } from 'src/app/buisness-object/user/User';
import { UserFactory } from 'src/app/buisness-object/user/factory/UserFactory';
import { DialogService } from '../dialog/dialog.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private dService: DialogService
  ) { }

  public login(username: string, password: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(username + ':' + password)
    });
    const observable = this.http.post(environment.api + '/user/session' , JSON.stringify([]), { headers });
    return observable.pipe(
      map((result: any) => {
        if(result.user.company_id != 1){
          this.dService.openInformation(
            {
              title: 'Login Fehlgeschlagen',
              message: 'Die Hey Way Webapp steht derzeit nur für unsere Partnerunternehmen zur Verfügung. Du kannst alle Hey Way Funktionen in der App verwenden. Hier findest du die App für dein Betriebssystem: ',
              btn_submit_txt: 'Ok',
              typ: 'info_login',
              submit_value: null,
            }
          );
          return false;
        } else {
          localStorage.setItem('token', result.token);
          localStorage.setItem('id', result.user.user_id);
          return true;
        }
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('last_search');
    this.router.navigate(['login'])
  }

  canActivate(): boolean {
    if(localStorage.getItem('token')){
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  public emailResetPassword(email: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.put(environment.api + '/user/password',
    {
      email: email
    },{ headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.logout();
        } else {
          console.log(error.status)
        }
        console.log(error);
        return of(false);
      })
    );
  }

  public resetPassword(token: string, password: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const observable = this.http.put(environment.api + '/user/resetpassword',
    {
      token: token,
      password: password
    },
    {headers});
    return observable.pipe(
      map((result: any)=> {
        return true;
      }),
      catchError(error => {
        console.log(error)
        return of(false);
      })
    );
  }

  public register(user: User, password: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const observable = this.http.post(environment.api + '/user', {
      user: {
        first_name: user.first_name,
        last_name: user.last_name,
        birthdate: user.birthdate,
        email: user.email,
        password: password,
        company_id: user.is_company,
      }
    },{ headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  public verifyUser(token: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const observable = this.http.put(environment.api + '/user/verify',
    {
      token: token
    },
    {headers});
    return observable.pipe(
      map((result: any)=> {
        return true;
      }),
      catchError(error => {
        return of(false);
      })
    );
  }
}
