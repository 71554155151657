import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login/login.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted = false;
  public accessDenied = false;
  public showPassword = false;
  public showRegisterForm = false;
  public showForgotPassword = false;
  public screenWidth;

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    if(this.form.valid) this.onSubmit();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private lService: LoginService,
    private router: Router,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.uService.user_data$.next(null)
    this.screenWidth = window.screen.width;
    this.form = this.formBuilder.group({
      email: [null, [Validators.required,Validators.pattern('^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9_\-\]+([.-]?[a-zA-Z]))[.]{1}[a-zA-Z]{2,}$')]],
      password: [null, [Validators.required]],
    })
  }

  checkValid(): boolean {
    if(this.form.valid) return true;
    else return false;
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.lService.login(this.form.controls.email.value, this.form.controls.password.value).subscribe((success) => {
      if(success){
        this.router.navigate(['dashboard']);
      } else {
        this.accessDenied = true;
        this.submitted = false;
      }
    });
  }

  resgisterAction() {
    this.form.reset();
    this.submitted = false;
    this.accessDenied = false;
    this.showRegisterForm = true;
  }

  forgotPasswordAction() {
    this.form.reset();
    this.submitted = false;
    this.accessDenied = false;
    this.showForgotPassword = true;
  }

  goBack() {
    this.form.reset();
    this.submitted = false;
    this.accessDenied = false;
    this.showRegisterForm = false;
    this.showForgotPassword = false;
  }
}
