<div id="reset_view">
  <label *ngIf="!success && !invalidToken && !tokenExpired" class="lbl_medium_24 lbl_active">Passwort ändern</label>
  <div *ngIf="invalidToken" class="lbl_medium_24">Not Authorized</div>
  <div *ngIf="tokenExpired" class="expired_container">
    <div class="lbl_black_80">401</div>
    <div class="lbl_medium_24">Link abgelaufen.</div>
  </div>
  <form *ngIf="!tokenExpired && !invalidToken && !success" [formGroup]="form" class="form">
    <img class="logo" src="../../../../assets/passwordbig.svg">
    <div class="inputfield_container" [ngClass]="submitted && form.controls.password_1.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="{{showPassword_1 ? 'text' : 'password'}}" placeholder="Neues Passwort"
        formControlName="password_1">
      <img class="icon_password" (click)="showPassword_1 = !showPassword_1" [src]="showPassword_1 ? '../../../assets/inputhide.svg' : '../../../assets/inputshow.svg'">
      <div class="input_valid_indicator" [ngClass]="form.controls.password_1.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <div class="inputfield_container" [ngClass]="submitted && form.controls.password_new.invalid ? 'inputfield_container_invalid' : ''">
      <input class="inputfield" type="{{showPassword_2 ? 'text' : 'password'}}" placeholder="Neues Passwort wiederholen"
        formControlName="password_2">
      <img class="icon_password" (click)="showPassword_2 = !showPassword_2" [src]="showPassword_2 ? '../../../assets/inputhide.svg' : '../../../assets/inputshow.svg'">
      <div class="input_valid_indicator" [ngClass]="form.controls.password_2.invalid ? 'input_valid_indicator_invalid' : ''"></div>
    </div>
    <label class="lbl_regular_14">Such dir ein Passwort aus, das zumindest aus einem Buchstaben und einer Zahl besteht. Es muss aus min. 5 Zeichen bestehen.</label>
    <button class="btn_submit" [ngClass]="form.invalid ? 'btn_submit_inactive' : ''" [disabled]="form.invalid" (click)="onSubmit()">Ändern</button>
  </form>
  <div *ngIf="success">
    <label *ngIf="success">Dein Passwort wurde geändert.</label>
    <button class="btn_submit" style="margin-top: 50px;" (click)="toLogin()">Zum Login</button>
  </div>
</div>
