<div class="list_element" [ngClass]="(trip.requestPending && isCreatedTrips) || (!trip.status && !isCreatedTrips)  ? 'request_pending' : ''" (click)="openDetailsEmitter.emit(trip)">
  <div *ngIf="(trip.requestPending && isCreatedTrips) || (!trip.status && !isCreatedTrips) && screenWidth > 520" class="data_wrapper">
    <img class="data_icon" src="../../../../assets/detourwhite.svg">
    <label class="lbl_black_14 lbl_white">Anfrage ausständig</label>
  </div>
  <div class="data_wrapper">
    <img class="data_icon" src="../../../../assets/calenderwhite.svg">
    <div class="data_time">
      <label class="lbl_white lbl_black_14">{{trip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
      <label class="lbl_white lbl_black_14">{{trip.timestamp | date: 'HH:mm'}} Uhr</label>
    </div>
  </div>
  <div class="data_wrapper">
    <img class="data_icon" src="../../../../assets/startwhite.svg">
    <label class="lbl_regular_14 lbl_white">{{trip.start_description}}</label>
  </div>
  <div class="data_wrapper">
    <img class="data_icon" src="../../../../assets/endwithe.svg">
    <label class="lbl_regular_14 lbl_white">{{trip.target_description}}</label>
  </div>
  <div class="data_wrapper" style="margin-bottom: 0;">
    <img class="data_icon" src="../../../../assets/drivercar.svg">
    <label class="lbl_regular_14 lbl_white">{{trip.getVehicle()}}</label>
  </div>
  <!-- <div class="data_wrapper" style="margin-bottom: 0;" *ngIf="screenWidth <= 520">
    <img class="data_icon" src="../../../../assets/detourwhite.svg">
    <label class="lbl_regular_14 lbl_white">Umwege sind erlaubt</label>
  </div> -->
</div>
