<div id="dialog_window">
  <div id="dialog_container">
    <label class="lbl_medium_22 lbl_active title">Wann findet diese Fahrt statt?</label>
    <div class="body">
      <div class="data_container">
        <div class="inputfield_container" [ngClass]="submitted && !time ? 'inputfield_container_invalid' : ''">
          <input class="inputfield" type="time" [(ngModel)]="time">
          <div class="input_valid_indicator" [ngClass]="!time ? 'input_valid_indicator_invalid' : ''"></div>
        </div>
      </div>
      <Label *ngIf="isEdit" class="lbl_regular_18 lbl_active" (click)="removeEmitter.emit()">Fahrt entfernen</Label>
    </div>
    <div class="btn_container_time">
      <button class="btn_gray lbl_black_16" (click)="backAction()">Zurück</button>
      <button class="btn_submit lbl_black_16" (click)="onSubmit()">Weiter</button>
    </div>
  </div>
</div>
