import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dialog-time',
  templateUrl: './dialog-time.component.html',
  styleUrls: ['./dialog-time.component.scss']
})
export class DialogTimeComponent implements OnInit {
  @Input() selectedDate: Date;
  @Input() isEdit: boolean;
  @Output() nextEmitter = new EventEmitter<string>();
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() removeEmitter = new EventEmitter<undefined>();
  public time: any;
  public submitted = false;

  constructor() { }

  ngOnInit(): void {
    if(this.selectedDate && this.isEdit){
      this.time = (this.selectedDate.getHours() < 10 ? '0'+this.selectedDate.getHours() : this.selectedDate.getHours()) + ':' + (this.selectedDate.getMinutes() < 10 ? '0'+this.selectedDate.getMinutes() : this.selectedDate.getMinutes());
    } else {
      this.time = '00:00'
    }
  }

  onSubmit() {
    this.submitted = true;
    if(this.time){
      this.submitted = false;
      this.nextEmitter.emit(this.time);
    }
  }

  backAction() {
    this.backEmitter.emit()
  }
}
