import { Slogan } from "../Slogan";

export class SloganFactory {
  static jsonFactory(rawData: any): Slogan[] {
    let datas: Slogan[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any): Slogan {
      return new Slogan(
          rawData.slogan_id,
          rawData.slogan,
          rawData.author,
          rawData.add_info,
      );
  }
}
