import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-resgister-form',
  templateUrl: './resgister-form.component.html',
  styleUrls: ['./resgister-form.component.scss']
})
export class ResgisterFormComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public submitted = false;
  public showPassword = false;
  public registerFailure = false;
  public showAgb = false;
  public showPrivacy = false;
  public success = false;
  public maxDate: Date;
  public dialogCalendarSubscription: Subscription;

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    if(this.checkValid()) this.onSubmit();
  }

  @Output() backEmitter = new EventEmitter<undefined>();

  constructor(
    private formBuilder: FormBuilder,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    let date = new Date();
    this.maxDate = new Date(date.getFullYear() - 18, date.getMonth(), date.getDate());
    this.form = this.formBuilder.group({
      first_name: [null,[Validators.required]],
      last_name: [null,[Validators.required]],
      birthdate: [null,[Validators.required]],
      email: [null, [Validators.required,Validators.pattern('^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9_\-\]+([.-]?[a-zA-Z]))[.]{1}[a-zA-Z]{2,}$')]],
      password: [null,[Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z]).{5,32}$')]],
      is_company: [false,[Validators.required]],
      agb_accepted: [false,[Validators.required]],
      dataprivacy_accepted: [false,[Validators.required]],
    });
  }

  ngOnDestroy(): void {
    if(this.dialogCalendarSubscription) this.dialogCalendarSubscription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogCalendarSubscription = this.dService.closeDialogInputCalender$.subscribe((value) => {
      if(value){
        if(value.submit_value) this.form.controls.time.setValue(value.submit_value)
      }
    });
  }

  openCalender() {
    this.dService.openInputCalendar({
      date: null
    });
  }

  checkValid(): boolean {
    if(this.form.valid && this.form.controls.dataprivacy_accepted.value == true && this.form.controls.agb_accepted.value == true) return true;
    else return false;
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid || !this.form.controls.dataprivacy_accepted.value || !this.form.controls.agb_accepted.value){
      return;
    } else {
      let user = new User(
        0,
        this.form.controls.first_name.value,
        this.form.controls.last_name.value,
        this.form.controls.birthdate.value.valueOf(),
        this.form.controls.email.value,
        this.form.controls.is_company.value,
        this.form.controls.dataprivacy_accepted.value,
      );
      this.lService.register(user, this.form.controls.password.value).subscribe((response) => {
        if(response){
          this.submitted = false;
          this.success = true;
        } else {
          this.registerFailure = true;
        }
      })
    }
  }

  toLogin() {
    this.form.reset();
    this.submitted = false;
    this.backEmitter.emit();
  }
}
