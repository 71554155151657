import { Trip } from './../../../buisness-object/trip/Trip';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalVariables } from 'src/app/helper/Globavraibles';

@Component({
  selector: 'app-create-drive-header',
  templateUrl: './create-drive-header.component.html',
  styleUrls: ['./create-drive-header.component.scss']
})
export class CreateDriveHeaderComponent implements OnInit {
  @Input() page: number;
  @Input() showTemplates: boolean;
  @Input() selectedTemplate: Trip;
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() cancelEmitter = new EventEmitter<undefined>();
  public screenWidth;

  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.screen.width;
  }

  onResize(e) {
    this.screenWidth = GlobalVariables.window_width;
  }
}
