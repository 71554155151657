import { EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';

@Component({
  selector: 'app-my-drives-header',
  templateUrl: './my-drives-header.component.html',
  styleUrls: ['./my-drives-header.component.scss']
})
export class MyDrivesHeaderComponent implements OnInit, OnChanges {
  @Input() trip: Trip;
  @Input() selectedPassenger: User;
  @Input() showView: number;
  @Output() backEmitter = new EventEmitter<undefined>();
  @Output() openEditEmitter = new EventEmitter<undefined>();
  @Output() stornoTripEmitter = new EventEmitter<undefined>();
  public screenWidth;
  public isCreatedTrip = true;

  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.screen.width;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.trip){
      if(this.trip?.driver && this.trip.driver.user_id != Number(localStorage.getItem('id'))){
        this.isCreatedTrip = false;
      } else {
        this.isCreatedTrip = true;
      }
    }
  }

  getTitle(): string {
    if(this.showView == 1) return 'Meine Fahrten';
    else if(this.showView == 2) return 'Details';
    else if(this.showView == 3) return 'Chat - Profil';
    else if(this.showView == 4) return '';
    else if(this.showView == 5) return 'Bearbeiten';
    else return '';
  }
}
