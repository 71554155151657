import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-page-five',
  templateUrl: './page-five.component.html',
  styleUrls: ['./page-five.component.scss']
})
export class PageFiveComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() nextPageEmitter = new EventEmitter<undefined>();
  @Output() openDialogEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {}

  getHowOften(typ: number): string {
    if(typ == 1) return 'Einmalig';
    else if(typ == 2) return 'Täglich';
    else if(typ == 3) return 'Werktags';
    else if(typ == 4) return 'Benutzerdefinierte Tage';
  }

  isWorkway(start_company_id: number, target_company_id: number): string {
    if((start_company_id != null && start_company_id != 0) || (target_company_id != null && target_company_id != 0)){
      return 'Arbeitsweg /';
    }
    return ''
  }

  nexPage() {
    this.nextPageEmitter.emit();
  }
}
