<div class="view_body">
  <div class="trip_overview_container" *ngIf="editPage == 1">
    <div class="trip_wrapper">
      <div class="trip_wrapper_header">
        <div class="avatar_container">
          <img [ngClass]="user?.avatar ? 'avatar' : 'avatar_placeholder'" [src]="user?.avatar ? ('data:image/png;base64,' + user?.avatar) : '../../../assets/userimageplaceholder.svg'">
        </div>
        <div class="trip_user_data_container_header">
          <div class="trip_review_container_header">
            <div class="star_container">
              <div *ngFor="let start of getArray(5); let i=index" class="icon_star">{{user?.getRating() >= (i+1) ? '&#9733;' : '&#10025;'}}</div>
            </div>
            <label class="lbl_regular_14 lbl_white">{{user.rating_count}} Bewertungen</label>
          </div>
          <label class="lbl_medium_28 lbl_white">{{user.first_name}} (Du)</label>
        </div>
      </div>
      <form [formGroup]="form" class="trip_wrapper_body">
        <div class="data_wrapper data_wrapper_phone">
          <img class="data_icon" src="../../../../assets/calenderwhite.svg">
          <label class="lbl_regular_16 lbl_white lbl_detour" (click)="openPageChange()">{{this.form.controls.time.value | date: 'dd.MM.yyyy HH:mm'}}</label>
        </div>
        <div *ngIf="!isPhone"></div>
        <div *ngIf="isPhone" class="data_wrapper_phone">
          <div class="data_wrapper_input">
            <img class="data_icon" src="../../../../assets/startwhite.svg">
            <label class="lbl_regular_16 lbl_white lbl_detour" (click)="openPageChange()">{{this.form.controls.start.value.location_name ? this.form.controls.start.value.location_name : this.form.controls.start.value}}</label>
          </div>
          <div class="data_wrapper_input">
            <img class="data_icon" src="../../../../assets/endwithe.svg">
            <label class="lbl_regular_16 lbl_white lbl_detour" (click)="openPageChange()">{{this.form.controls.target.value.location_name ? this.form.controls.target.value.location_name : this.form.controls.target.value}}</label>
          </div>
        </div>
        <div *ngIf="!isPhone" class="data_wrapper_input">
          <img class="data_icon" src="../../../../assets/startwhite.svg">
          <label class="lbl_regular_16 lbl_white lbl_detour" (click)="openPageChange()">{{this.form.controls.start.value.location_name ? this.form.controls.start.value.location_name : this.form.controls.start.value}}</label>
        </div>
        <div *ngIf="!isPhone" class="data_wrapper_input">
          <img class="data_icon" src="../../../../assets/endwithe.svg">
          <label class="lbl_regular_16 lbl_white lbl_detour" (click)="openPageChange()">{{this.form.controls.target.value.location_name ? this.form.controls.target.value.location_name : this.form.controls.target.value}}</label>
        </div>
        <div class="data_wrapper data_wrapper_phone">
          <img class="data_icon" src="../../../../assets/detourwhite.svg">
          <label class="lbl_regular_16 lbl_white lbl_detour" (click)="form.controls.accept_detours.setValue(!form.controls.accept_detours.value)">{{form.controls.accept_detours.value ? 'Umwege sind erlaubt' : 'Keine Umwege'}}</label>
        </div>
        <div *ngIf="!isPhone"></div>
        <div class="data_wrapper" style="align-self: flex-end;">
          <label class="lbl_regular_16 lbl_white" style="margin-right: 10px;">{{form.controls.passenger_capacity.value == 1 ? 'Platz frei' : 'Plätze frei'}}</label>
          <input class="inputfield" [ngStyle]="{'width': isPhone ? '30px' : '60px'}" type="number" min="1" max="9" step="1" placeholder="z.B. 2" formControlName="passenger_capacity">
        </div>
        <div class="data_wrapper passenger_fee" style="justify-self: flex-end;">
          <label class="lbl_regular_16 lbl_white" style="margin-right: 10px;">je € </label>
          <input class="inputfield" [ngStyle]="{'width': isPhone ? '60px' : '100px'}" type="number" min="0" step="0.10" placeholder="z.B. 1.00" formControlName="passenger_fee">
        </div>
      </form>
    </div>
    <div class="btn_container">
      <button class="btn_submit" [ngClass]="!isValidForm() ? 'btn_submit_inactive' : ''" [disabled]="!isValidForm()" (click)="updateTripAction()">{{isTemplate ? 'Fahrt anbieten' : 'Ändern'}}</button>
    </div>
  </div>
  <app-page-one *ngIf="editPage == 2" class="page_wrapper"
    [form]="form"
    [submitted]="submitted"
    [isEdit]="true"
    (nextPageEmitter)="nextPage()"
  ></app-page-one>
  <app-page-two *ngIf="editPage == 3" class="page_wrapper"
    [form]="form"
    [isEdit]="true"
    (nextPageEmitter)="nextPage()">
  </app-page-two>
</div>
