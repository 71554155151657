import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-profile-change-password',
  templateUrl: './profile-change-password.component.html',
  styleUrls: ['./profile-change-password.component.scss']
})
export class ProfileChangePasswordComponent implements OnInit {
  public form: FormGroup;
  public submitted = false;
  public showPassword_1 = false;
  public showPassword_2 = false;
  public failure = false;
  @Output() udpatePasswordSuccessEmitter = new EventEmitter<undefined>();

  constructor(
    private formBuilder: FormBuilder,
    private uService: UserService,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password_old: [null,[Validators.required]],
      password_new: [null,[Validators.required,Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z]).{5,32}$')]],
    })
  }

  change() {
    this.submitted = true;
    if(this.form.invalid){
      return;
    } else {
      this.uService.updateUserPassword(this.form.controls.password_old.value, this.form.controls.password_new.value).subscribe((response) => {
        if(response){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Passwort aktualisiert.',
            success: true
          });
          this.udpatePasswordSuccessEmitter.emit();
        } else {
          this.form.reset();
        }
      })
    }
  }
}
