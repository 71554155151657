<div class="header_container" (window:resize)="onResize($event)">
  <div *ngIf="(page > 1 && page < 6) || showTemplates || selectedTemplate" class="button_container_1" (click)="backEmitter.emit()">
    <img class="icon_arrow" src="../../../../assets/navarrow.svg">
    <button *ngIf="screenWidth > 550" class="btn_label"></button>
  </div>
  <label class="lbl_medium_20">{{page == 6 ? 'Fahrt erfolgreich erstellt!' : 'Fahrt erstellen'}}</label>
  <div *ngIf="page > 1 && page < 6" class="button_container_2" (click)="cancelEmitter.emit()">
    <button class="btn_label">Abbrechen</button>
  </div>
</div>
