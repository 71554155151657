<div id="app_container">
  <router-outlet #outlet="outlet"></router-outlet>
  <app-sidebar *ngIf="!isLoginView(outlet)"></app-sidebar>
  <app-dialog-notification></app-dialog-notification>
  <app-dialog-query></app-dialog-query>
  <app-dialog-info></app-dialog-info>
  <app-dialog-input-calender></app-dialog-input-calender>
  <app-dialog-success></app-dialog-success>
  <app-loading></app-loading>
</div>
