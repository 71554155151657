export class CreateTrip {
  constructor(
    public user_id: number,
    public passenger_capacity: number,
    public passenger_fee: number,
    public accept_detours: boolean,
    public sub_trips: SubTrip[],
    public trips: Tour[],
  ){}
}

export class SubTrip {
  constructor(
    public sid: number,
    public polyline: string,
    public distance: number,
    public duration: number,
    public start_description: string,
    public start_latitude: number,
    public start_longitude: number,
    public target_description: string,
    public target_latitude: number,
    public target_longitude: number
  ){}
}

export class Tour {
  constructor(
    public sid: number,
    public timestamps: number[]
  ){}
}
