<div class="header_container" [ngStyle]="{'box-shadow': showSearch ? 'var(--box-shadow-menu)' : ''}" (window:resize)="onResize($event)">
  <div class="form" *ngIf="showView == 1">
    <div *ngIf="screenWidth < 1750 && !showSearch" class="inputfield_container" (click)="openSearch()">
      <img style="margin-left: 15px;" src="../../../../assets/search.svg">
      <input class="inputfield" type="text" disabled="true" placeholder="Fahrt suchen">
    </div>
    <form class="form_wrapper" *ngIf="screenWidth > 1750 || showSearch" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
      <div [ngClass]="screenWidth > 1750 ? 'form' : (showSearch ? 'form_small form_small_open' : 'form_small')">
        <div class="input_container_one">
          <img class="input_container_one_icon" src="../../../assets/start.svg">
          <div class="inputfield_container" >
            <input #startAddress class="inputfield" type="text" placeholder="Start" (input)="this.form.controls.start.setValue(null); getPlaces(startAddress.value, 1);" (focus)="showDropdownOne = true">
            <div class="input_valid_indicator" [ngClass]="!form.controls.start.value ? 'input_valid_indicator_invalid' : ''"></div>
            <div *ngIf="showDropdownOne && predictionPlaces.length > 0" class="dropdown_cotainer">
              <div id="dropdown" class="dropdown_scroll_wrapper">
                <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''"  (click)="setLocation(place, 1)">
                  <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
                  <div>{{place.full_name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input_container_one">
          <img class="input_container_one_icon" src="../../../assets/end.svg">
          <div class="inputfield_container">
            <input #targetAddress class="inputfield" type="text" placeholder="Ziel" (input)="this.form.controls.target.setValue(null); getPlaces(targetAddress.value, 2);" (focus)="showDropdownTwo = true">
            <div class="input_valid_indicator" [ngClass]="!form.controls.target.value ? 'input_valid_indicator_invalid' : ''"></div>
            <div *ngIf="showDropdownTwo && predictionPlaces.length > 0" class="dropdown_cotainer">
              <div id="dropdown" class="dropdown_scroll_wrapper">
                <div *ngFor="let place of predictionPlaces; let i = index" [attr.id]="'place_'+i" class="dropdown_element" [ngClass]="placeArrowIndex == i ? 'dropdown_element_active' : ''" (click)="setLocation(place, 2)">
                  <img *ngIf="isMagnaPlace(place.place_id)" src="../../../../assets/magna.svg" class="dropdown_element_icon">
                  <div>{{place.full_name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input_container_one" [ngClass]="submitted && form.controls.time.invalid ? 'inputfield_container_invalid' : ''">
          <img class="input_container_one_icon" src="../../../assets/calender.svg">
          <div class="inputfield_container">
            <input #time_input type="datetime" class="inputfield" placeholder="Zeit"
              (focus)="openCalender()"
              (click)="openCalender()"
              readonly>
            <div class="input_valid_indicator" [ngClass]="form.controls.time.invalid ? 'input_valid_indicator_invalid' : ''"></div>
          </div>
        </div>
        <button type="button" class="btn_submit" [ngClass]="!checkValid() ? 'btn_submit_inactive' : ''" [disabled]="!checkValid()" (click)="onSubmit()">Fahrt suchen</button>
        <img *ngIf="screenWidth < 1750" (click)="closeSearch()" class="icon_close" src="../../../../assets/navarrow.svg">
      </div>
    </form>
  </div>
  <div *ngIf="showView == 2">
    <div class="button_container" (click)="backEmitter.emit()">
      <img class="icon_arrow" src="../../../../assets/navarrow.svg">
      <button *ngIf="screenWidth > 550" class="btn_label"></button>
    </div>
    <label class="lbl_medium_20">Highscore</label>
  </div>
  <div *ngIf="showView == 3">
    <div class="button_container" (click)="backAction()">
      <img class="icon_arrow" src="../../../../assets/navarrow.svg">
      <button *ngIf="screenWidth > 550" class="btn_label"></button>
    </div>
    <label class="lbl_medium_20">Ergebnisse</label>
  </div>
  <div *ngIf="showView == 4">
    <div class="button_container" (click)="backEmitter.emit()">
      <img class="icon_arrow" src="../../../../assets/navarrow.svg">
      <button *ngIf="screenWidth > 550" class="btn_label"></button>
    </div>
    <label class="lbl_medium_20">Umweg anfragen</label>
  </div>
  <div *ngIf="showView == 5">
    <label class="lbl_medium_20">Anfrage erfolgreich gesendet</label>
  </div>
</div>
