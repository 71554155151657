import { Directive } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

export const DATE_FORMAT_1 = {
  parse: {
    dateInput: 'DD MM.yyyy, HH:mm'
  },
  display: {
    dateInput: 'DD MM.yyyy, HH:mm',
    monthYearLabel: 'MM/YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM/YYYY'
  },
}

@Directive({
  selector: '[appCustomDateFormat1]',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_1},
  ],
})
export class CustomDateFormat1Directive {

  constructor() { }

}
