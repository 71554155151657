import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Trip } from 'src/app/buisness-object/trip/Trip';
import { User } from 'src/app/buisness-object/user/User';
import { GlobalVariables } from 'src/app/helper/Globavraibles';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { TripService } from 'src/app/service/trip/trip.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-my-drives',
  templateUrl: './my-drives.component.html',
  styleUrls: ['./my-drives.component.scss']
})
export class MyDrivesComponent implements OnInit, OnDestroy {
  public tab = 1;
  public tabDetails = 1;
  public user: User;
  public createdTrips: Trip[] = [];
  public joinedTrips: Trip[] = [];
  public templates: Trip[] = [];
  public trips_2: Trip[] = [];
  public selectedTrip: Trip;
  public selectedPassenger: User;
  public dialogQuerySubsription: Subscription;
  public showView = 1;
  public editPage = 1;
  public showChatPhone = false;
  public showingTripDetails = false;
  public pathSub: Subscription;
  public routeSub: Subscription;

  constructor(
    private tService: TripService,
    private uService: UserService,
    private dService: DialogService,
    private activateRoute: ActivatedRoute,
    public locationRoute: Location
  ) { }

  ngOnInit(): void {
    this.getDateServerside();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  getDateServerside() {
    forkJoin([
      this.uService.getUser(Number(localStorage.getItem('id')), true)
    ]).subscribe(([data]:[any]) => {
      if(data){
        this.user = data.user;
        this.createdTrips = data.created_trips;
        this.joinedTrips = data.joined_trips;
        this.templates = data.templates;
        this.mergeCreateTripsDriver()
        this.sortTrips();
        this.routeListener();
      }
    })
  }

  routeListener() {
    this.routeSub = this.activateRoute.queryParams.subscribe((params) => {
      if(params && Number(params.tripId)){
        let foundTrip;
        for(let trip of this.createdTrips){
          if(trip.trip_id == Number(params.tripId)){
            foundTrip = trip;
            break;
          }
        }
        if(!foundTrip){
          for(let trip of this.joinedTrips){
            if(trip.trip_id == Number(params.tripId)){
              foundTrip = trip;
              break;
            }
          }
        }
        this.openDetails(foundTrip);
      } else {
        this.selectedTrip = null;
        this.selectedPassenger = null;
        this.showView = 1;
      }
    })
  }

  changesStatus() {

  }

  mergeCreateTripsDriver() {
    for(let trip of this.createdTrips){
      trip.driver = this.user;
    }
  }

  sortTrips() {
    if(this.createdTrips.length > 0){
      this.createdTrips.sort((a, b) => {
        if(a.timestamp > b.timestamp) return 1;
        if(a.timestamp < b.timestamp) return -1;
        return 0;
      })
      let tempCreated: Trip[] = [];
      for(let i = 0; i < this.createdTrips.length; i++){
        if(this.createdTrips[i].requestPending){
          tempCreated.push(this.createdTrips[i])
          this.createdTrips.splice(i,1);
          i--;
        }
      }
      if(tempCreated.length > 0){
        tempCreated.sort((a, b) => {
          if(a.timestamp > b.timestamp) return 1;
          if(a.timestamp < b.timestamp) return -1;
          return 0;
        })
      }
      this.createdTrips = tempCreated.concat(this.createdTrips);
    }
    if(this.joinedTrips.length > 0){
      this.joinedTrips.sort((a, b) => {
        if(a.timestamp > b.timestamp) return 1;
        if(a.timestamp < b.timestamp) return -1;
        return 0;
      })
      let tempJoined: Trip[] = [];
      for(let i = 0; i < this.joinedTrips.length; i++){
        if(this.joinedTrips[i].status != undefined && !this.joinedTrips[i].status){
          tempJoined.push(this.joinedTrips[i])
          this.joinedTrips.splice(i,1);
          i--;
        }
      }
      if(tempJoined.length > 0){
        tempJoined.sort((a, b) => {
          if(a.timestamp > b.timestamp) return 1;
          if(a.timestamp < b.timestamp) return -1;
          return 0;
        })
      }
      this.joinedTrips = tempJoined.concat(this.joinedTrips);
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'edit_trip') this.openEdit(value.submit_value);
        else if(value.typ == 'storno_trip_as_driver') this.stornoTrip();
        else if(value.typ == 'storno_trip_as_passenger') this.cancelTripAsPassenger();
      }
    });
  }

  openDetails(trip: Trip) {
    this.selectedTrip = trip;
    this.showView = 2;
  }

  closeDetails() {
    if(this.showView == 5 && this.editPage == 2){
      this.editPage = 1;
    } else if(this.showView == 5 && this.editPage == 3){
      this.editPage = 2;
    } else if(this.showView == 5 && this.editPage == 1){
      this.showView = 2;
    } else if(this.showChatPhone){
      this.showChatPhone = false;
      this.showView = 3;
    } else if(this.showView == 3){
      this.showView = 2;
    } else {
      this.selectedPassenger = null;
      this.selectedTrip = null;
      this.tabDetails = 1;
      this.showView = 1;
      this.locationRoute.replaceState('/my-drives');
    }
  }

  openPhoneChatDirekt(user: User){
    this.selectedPassenger = user;
    this.showView = 3;
    this.showChatPhone = true;
  }

  openPhoneChat() {
    this.showChatPhone = true;
  }

  openProfile(passenger: User) {
    this.tabDetails = 2;
    this.selectedPassenger = passenger;
    this.showView = 3;
  }

  openEditAction() {
    if(this.selectedTrip){
      if(this.selectedTrip.passengers.length == 0){
        this.openEdit(true);
      }
    }
  }

  openEdit(isEdit: boolean) {
    if(isEdit){
      this.showView = 5;
    } else {
      this.dService.openQuery(
        {
          title: 'Fahrt stornieren?',
          message: 'Bist du sicher, dass du deine Fahrt stornieren möchtest?',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Fahrt stornieren',
          typ: 'storno_trip',
          submit_value: true,
          showClose: true,
        }
      );
    }
  }

  openCancelDialog() {
    this.dService.openQuery(
      {
        title: 'Fahrt stornieren?',
        message: 'Bist du sicher, dass du deine Fahrt stornieren möchtest?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Fahrt stornieren',
        typ: 'cancel_trip',
        submit_value: true,
        showClose: true,
      }
    );
  }

  stornoTripAction() {
    let driver = this.selectedTrip.driver.user_id == this.user.user_id ? true : false
    this.dService.openQuery(
      {
        title: 'Fahrt stornieren?',
        message: 'Bist du sicher, dass du deine Fahrt stornieren möchtest?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Fahrt stornieren',
        typ: driver ? 'storno_trip_as_driver' : 'storno_trip_as_passenger',
        submit_value: true,
        showClose: true,
      }
    );
  }

  stornoTrip() {
    this.tService.deleteTrip(this.selectedTrip.trip_id).subscribe((result) => {
      if(result){
        let index = this.createdTrips.findIndex(t => t.trip_id == this.selectedTrip.trip_id);
        if(index > -1) this.createdTrips.splice(index, 1);
        index = this.joinedTrips.findIndex(t => t.trip_id == this.selectedTrip.trip_id);
        if(index > -1) this.joinedTrips.splice(index, 1);
        this.selectedTrip = null;
        if(window.innerWidth > 550){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Die Fahrt wurde storniert',
            success: true
          });
        }
        this.showView = 1;
      }
    })
  }

  cancelTripAsPassenger() {
    this.tService.cancelTripAsPassenger(this.selectedTrip.trip_id).subscribe((result) => {
      if(result){
        let index = this.createdTrips.findIndex(t => t.trip_id == this.selectedTrip.trip_id);
        if(index > -1) this.createdTrips.splice(index, 1);
        index = this.joinedTrips.findIndex(t => t.trip_id == this.selectedTrip.trip_id);
        if(index > -1) this.joinedTrips.splice(index, 1);
        this.selectedTrip = null;
        if(window.innerWidth > 550){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Die Fahrt wurde storniert',
            success: true
          });
        }
        this.showView = 1;
      }
    })
  }

  openChat() {
    if(this.selectedPassenger) this.showView = 4;
  }

  acceptOrRefusePassenger(data: any) {
    if(data.accept){
      let index = this.createdTrips.findIndex(t => t.trip_id == data.trip_id);
      if(index > -1){
        let indexDeep = this.createdTrips[index].passengers.findIndex(p => p.user_id == data.user_id);
        if(indexDeep > -1){
          this.createdTrips[index].passengers[indexDeep].status = 1;
          this.createdTrips[index].requestPending = false;
        }
      }
    } else {
      let index = this.createdTrips.findIndex(t => t.trip_id == data.trip_id);
      if(index > -1){
        this.createdTrips[index].requestPending = false;
      }
    }
  }

  closeEdit(trip: Trip) {
    if(trip){
      trip.driver = this.user;
      this.selectedTrip = trip;
      let index = this.createdTrips.findIndex(t => t.trip_id == this.selectedTrip.trip_id);
      if(index > -1) this.createdTrips[index] = this.selectedTrip;
    }
    this.showView = 2;
  }
}
