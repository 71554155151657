import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoadingService } from 'src/app/service/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  public loading = false;
  @Input() isLogin: boolean;
  @Input() isProgressbar: boolean;
  loadingSubscription: Subscription;

  constructor(
    private loadingScreenService: LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(debounceTime(300)).subscribe((value => {
      this.loading = value;
    }));
  }

  ngOnDestroy(){
    this.loadingSubscription.unsubscribe();
  }
}
