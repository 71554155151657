export const GlobalVariables = {
  window_width: window.innerWidth,

  is_tablet_device: window.innerWidth <= 1180,
  is_phone_device: window.innerWidth <= 540,

  breakpoint_desktop_smalll: 1750,
  breakpoint_tablet: 1180,
  breakpoint_phone: 540,

  is_create_process: false,
  amount_of_pending_requests: 0
}
