<div class="view_container">
  <app-create-drive-header
    [page]="page"
    [showTemplates]="showTemplates"
    [selectedTemplate]="selectedTemplate"
    (backEmitter)="backAction()"
    (cancelEmitter)="cancelAction()"
  ></app-create-drive-header>
  <div class="view_body list_wrapper" *ngIf="showTemplates">
    <div class="trip_wrapper" *ngFor="let trip of templates" (click)="openTemplate(trip)">
      <div class="trip_wrapper_body">
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/startwhite.svg">
          <label class="lbl_regular_16 lbl_white">{{trip.start_description}}</label>
        </div>
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/endwithe.svg">
          <label class="lbl_regular_16 lbl_white">{{trip.target_description}}</label>
        </div>
        <div class="data_wrapper">
          <img class="data_icon" src="../../../../assets/detourwhite.svg">
          <label class="lbl_regular_16 lbl_white">{{trip.accept_detours ? 'Umwege sind erlaubt' : 'Keine Umwege'}}</label>
        </div>
        <div class="trip_footer">
          <label class="lbl_regular_16 lbl_white">Arbeitsweg / {{trip.passenger_capacity}} {{trip.passenger_capacity == 1 ? 'Platz frei' : 'Plätze frei'}}</label>
          <div>
            <label class="lbl_regular_16 lbl_white">je </label>
            <label class="lbl_black_28 lbl_white">€ {{(trip.passenger_fee / 100) | currency: 'EUR': '': '1.2-2'}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="view_body" *ngIf="!showTemplates && !selectedTemplate">
    <div class="dot_container" *ngIf="page != 5 && page != 6">
      <div class="dot" [ngClass]="page == 1 ? 'dot_active' : ''"></div>
      <div class="dot" [ngClass]="page == 2 ? 'dot_active' : ''"></div>
      <div class="dot" [ngClass]="page == 3 ? 'dot_active' : ''"></div>
      <div class="dot" [ngClass]="page == 4 ? 'dot_active' : ''"></div>
    </div>
    <app-page-one *ngIf="page == 1" class="page_wrapper"
      [form]="form"
      [submitted]="submitted"
      [showTemplateBtn]="templates.length > 0"
      (nextPageEmitter)="nextPage()"
      (showTemplatesEmitter)="showTemplates = true"
    ></app-page-one>
    <app-page-two *ngIf="page == 2" class="page_wrapper"
      [form]="form"
      [submitted]="submitted"
      (nextPageEmitter)="nextPage()"
    ></app-page-two>
    <app-page-three *ngIf="page == 3" class="page_wrapper"
      [form]="form"
      [submitted]="submitted"
      (nextPageEmitter)="nextPage()"
    ></app-page-three>
    <app-page-four *ngIf="page == 4" class="page_wrapper"
      [form]="form"
      [submitted]="submitted"
      (nextPageEmitter)="nextPage()"
    ></app-page-four>
    <app-page-five *ngIf="page == 5" class="page_wrapper" style="width: 100%;"
      [form]="form"
      (openDialogEmitter)="openDialog($event)"
      (nextPageEmitter)="createTrip()"
    ></app-page-five>
    <app-page-six *ngIf="page == 6" class="page_wrapper" style="width: 100%;"
      [trip]="createdTrip"
      (nextPageEmitter)="cancelAction()"
    ></app-page-six>
  </div>
  <app-edit-drive *ngIf="showTemplateEdit"
    [trip]="selectedTemplate"
    [user]="user"
    [isTemplate]="true"
    [editPage]="editPage"
    (openEditTripDetailsEmitter)="editPage = $event"
    (createDriveEmitter)="createTrip($event)"
></app-edit-drive>
</div>
<app-dialog-several-times *ngIf="showDialogSeveral && dialog == 1"
  [form]="form"
  [isReturnTrip]="isReturnTrip"
  (submitEmitter)="submitDialogServeralTimes()"
  (closeDialogEmitter)="closeDialog(1)"
></app-dialog-several-times>
<app-dialog-date-last *ngIf="showDialogSeveral && dialog == 2"
  [form]="form"
  [isReturnTrip]="isReturnTrip"
  (submitEmitter)="submitDialogDateLast()"
  (closeDialogEmitter)="closeDialog(2)"
></app-dialog-date-last>
<app-dialog-calendar *ngIf="showDialogSeveral && dialog == 3"
  [form]="form"
  [isReturnTrip]="isReturnTrip"
  (submitEmitter)="submitDialogCalendar($event)"
  (closeDialogEmitter)="closeDialog(3)"
></app-dialog-calendar>
