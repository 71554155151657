<div class="view_body" [ngStyle]="{'opacity': isLoaded ? '1' : '0'}" [ngStyle]="{'background-image': isCreatedTrip && (!isPhone && !isTablet && trip.passengers.length == 0) || (tab == 2 && trip.passengers.length == 0) ? 'url(../../../assets/emptyscreen-ohne-sun.svg)' : ''}">
  <div *ngIf="isCreatedTrip && (isPhone || isTablet)" class="tab_wrapper">
    <div class="tab_slider" [ngStyle]="{'left': tab == 1 ? '0%' : (tab == 2 ? '33.3%' : '66.6%')}"></div>
    <label class="tab_value lbl_regular_14" (click)="tab = 1">Fahrt</label>
    <label class="tab_value lbl_regular_14" (click)="tab = 2">Mitfahrer</label>
    <label class="tab_value lbl_regular_14" (click)="goToTab(3)">Karte</label>
  </div>
  <div class="scroll_wrapper">
    <div class="row_1" [ngClass]="trip.passengers.length > 0 ? 'row_1_no_edit' : ''" *ngIf="tab == 1 || (!isPhone && !isTablet)">
      <div class="trip_wrapper">
        <div class="trip_wrapper_header">
          <div class="trip_header_flex">
            <div class="avatar_container" [ngStyle]="{'cursor': !isCreatedTrip && trip.status ? 'pointer' : ''}" (click)="!isCreatedTrip && trip.status ? openProfile(trip.driver) : ''">
              <img [ngClass]="user?.avatar ? 'avatar' : 'avatar_placeholder'" [src]="user?.avatar ? ('data:image/png;base64,' + user?.avatar) : '../../../assets/userimageplaceholder.svg'">
            </div>
            <div class="trip_user_data_container_header">
              <label class="lbl_medium_28 lbl_white">{{user.first_name}} {{isCreatedTrip ? '(Du)' : ''}}</label>
              <div class="trip_review_container_header">
                <div class="star_container">
                  <div *ngFor="let start of getArray(5); let i=index" class="icon_star">{{user?.getRating() >= (i+1) ? '&#9733;' : '&#10025;'}}</div>
                </div>
                <label class="lbl_regular_14 lbl_white">{{user.rating_count}} Bewertungen</label>
              </div>
            </div>
          </div>
          <img *ngIf="!isCreatedTrip && trip.status" class="icon_chat" src="../../../../assets/contactgrey-white.svg" (click)="openChat(user)">
        </div>
        <div class="trip_wrapper_body">
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/calenderwhite.svg">
            <div class="data_time">
              <label class="lbl_white lbl_black_16">{{trip.timestamp | date: 'EEEE, dd.MM.yyyy'}}</label>
              <label class="lbl_white lbl_black_16">{{trip.timestamp | date: 'HH:mm'}} Uhr</label>
            </div>
          </div>
          <div class="grid_description">
            <div class="data_wrapper">
              <img class="data_icon" src="../../../../assets/startwhite.svg">
              <label class="lbl_regular_16 lbl_white">{{trip.start_description}}</label>
            </div>
            <div class="data_wrapper">
              <img class="data_icon" src="../../../../assets/endwithe.svg">
              <label class="lbl_regular_16 lbl_white">{{trip.target_description}}</label>
            </div>
          </div>
          <div class="data_wrapper">
            <img class="data_icon" src="../../../../assets/detourwhite.svg">
            <label class="lbl_regular_16 lbl_white">{{trip.accept_detours ? 'Umwege sind erlaubt' : 'Keine Umwege'}}</label>
          </div>
          <div class="trip_footer">
            <label class="lbl_regular_16 lbl_white">{{passenger_capicity}} {{passenger_capicity == 1 ? 'Platz frei' : 'Plätze frei'}}</label>
            <div>
              <label class="lbl_regular_16 lbl_white">je </label>
              <label class="lbl_black_28 lbl_white">€ {{(trip.passenger_fee / 100) | currency: 'EUR': '': '1.2-2'}}</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(!isPhone && !isTablet) && trip.passengers.length == 0" class="btn_wrapper_edit">
        <button class="btn_submit_edit" (click)="openEditEmitter.emit()">Fahrt bearbeiten</button>
      </div>
      <div id="map" *ngIf="!isCreatedTrip && isPhone && isTablet" style="border-radius: 17px; height: 380px;"></div>
      <div class="co2_wrapper">
        <Label class="lbl_black_80 lbl_white lbl_co2" style="font-size: 72px;">{{trip.getCo2InKg() | number: '1.2-2'}} kg</Label>
        <Label class="lbl_medium_16 lbl_white lbl_co2_2">CO<sub>2</sub>-Ersparnis auf dieser Fahrt bei Vollbesetzung</Label>
      </div>
      <div id="map" style="border-radius: 17px;" *ngIf="!isPhone && !isTablet"></div>
      <div *ngIf="(isPhone || isTablet) && trip.passengers.length == 0" class="btn_wrapper_edit">
        <button class="btn_submit_edit" (click)="openEditEmitter.emit()">Fahrt bearbeiten</button>
      </div>
    </div>
    <Label *ngIf="isCreatedTrip && !isPhone && !isTablet" class="lbl_medium_24" style="margin: 0 20px;">{{this.trip.passengers == 0 ? 'Noch fährst du alleine' : 'Mitfahrende'}}</Label>
    <div class="row_2" *ngIf="isCreatedTrip && (!isPhone && !isTablet) || ((isPhone || isTablet) && tab == 2)">
      <div *ngIf="(isPhone || isTablet) && this.trip.passengers == 0" class="no_trips">
        <Label class="lbl_regular_24">Keine Mitfahrer</Label>
      </div>
      <div *ngFor="let passenger of this.trip.passengers" class="passenger_wrapper" [ngClass]="passenger.status == 1 ? 'passenger_wrapper_accepted' : ''">
        <div class="passenger_wrapper_header">
         <div class="trip_header_flex">
          <div class="avatar_container">
            <img [ngClass]="passenger?.avatar ? 'avatar' : 'avatar_placeholder'" [src]="passenger?.avatar ? ('data:image/png;base64,' + passenger?.avatar) : '../../../assets/userimageplaceholder.svg'">
          </div>
          <div class="trip_user_data_container_header">
            <label class="lbl_medium_28 lbl_white" style="text-align: center;">{{passenger.first_name}} {{!isPhone && !isTablet ? passenger.last_name : ''}}</label>
            <div class="trip_review_container_header">
              <div class="star_container">
                <div *ngFor="let start of getArray(5); let i=index" class="icon_star">{{passenger?.getRating() >= (i+1) ? '&#9733;' : '&#10025;'}}</div>
              </div>
              <label class="lbl_regular_14 lbl_white">{{passenger.rating_count}} Bewertungen</label>
            </div>
          </div>
         </div>
         <!-- <img class="icon_chat" src="../../../../assets/contactgrey-white.svg" (click)="openChat(passenger)"> -->
        </div>
        <div class="passenger_wrapper_body">
          <div *ngIf="passenger.start_description || passenger.target_description">
            <div *ngIf="passenger.start_description">
              <Label class="lbl_regular_14 lbl_white">{{passenger.first_name}} möchte hier zusteigen:</Label>
              <div class="data_wrapper" style="align-items: center;">
                <img class="data_icon" src="../../../../assets/startnew.svg">
                <label class="lbl_regular_16 lbl_white">{{passenger.start_description}}</label>
              </div>
            </div>
            <div *ngIf="passenger.target_description">
              <Label class="lbl_regular_14 lbl_white">{{passenger.first_name}} möchte hier aussteigen:</Label>
              <div class="data_wrapper" style="align-items: center;">
                <img class="data_icon" src="../../../../assets/endwithe.svg">
                <label class="lbl_regular_16 lbl_white">{{passenger.target_description}}</label>
              </div>
            </div>
          </div>
          <!-- <button class="btn_white_transparent" (click)="openProfile(passenger)">Profil ansehen</button> -->
        </div>
        <div class="passenger_btn_container">
          <button *ngIf="!passenger.status" class="btn_gray lbl_medium_16" (click)="acceptOrRefusePassenger(passenger.user_id, false)">Ablehnen</button>
          <button *ngIf="!passenger.status" class="btn_submit lbl_black_16" (click)="acceptOrRefusePassenger(passenger.user_id, true)">Zustimmen</button>
          <button *ngIf="passenger.status" class="btn_submit lbl_black_16 btn_passenger_accepted" (click)="openProfile(passenger)">{{passenger.first_name}} schreiben</button>
        </div>
      </div>
    </div>
    <div class="tab_3" *ngIf="(isPhone || isTablet) && tab == 3">
      <div id="map"></div>
    </div>
  </div>
</div>
