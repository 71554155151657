import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnDestroy {
  public success = false;
  public invalidToken = false;
  public tokenExpired = false;
  public routeUrl: string;
  public token: string = '';
  public routeSubscription: Subscription;

  constructor(
    private router: Router,
    private lService: LoginService,
    private activateRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.routeUrl = this.activateRoute.snapshot.url[0].path;
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      if(!params.token){
        this.invalidToken = true;
      } else {
        this.token = params.token;
      }
    })
  }

  ngOnDestroy(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  onSubmit() {
    this.lService.verifyUser(this.token).subscribe((response) => {
      if(response){
        this.success = true;
      } else {
        this.tokenExpired = true;
      }
    })
  }

  toLogin() {
    this.router.navigate(['login']);
  }
}
