<div id="login_view" [ngStyle]="{'justify-content': !showRegisterForm ? 'center' : 'flex-start'}">
  <div class="header_container" *ngIf="screenWidth <= 550">
    <img *ngIf="showRegisterForm || showForgotPassword" class="icon_arrow" src="../../../assets/navarrow.svg" (click)="goBack()">
    <Label class="lbl_regular_18">{{showRegisterForm ? 'Registrieren' : (showForgotPassword ? 'Passwort zurücksetzen' : 'Login')}}</Label>
  </div>
  <form *ngIf="!showRegisterForm && !showForgotPassword" [formGroup]="form" class="form">
    <img class="logo" src="../../../assets/logo.svg">
    <div class="input_wrapper">
      <div class="input_container_one">
        <img class="input_container_one_icon" src="../../../assets/userimageplaceholder.svg">
        <div class="inputfield_container" [ngClass]="submitted && form.controls.email.invalid ? 'inputfield_container_invalid' : ''">
          <input class="inputfield" type="text" placeholder="E-Mail-Adresse"
            formControlName="email"
            (change)="submitted = false; accessDenied = false;">
          <div class="input_valid_indicator" [ngClass]="form.controls.email.invalid ? 'input_valid_indicator_invalid' : ''"></div>
        </div>
      </div>
      <div class="input_container_one">
        <img class="input_container_one_icon" src="../../../assets/lock.svg">
        <div class="inputfield_container" [ngClass]="submitted && form.controls.password.invalid ? 'inputfield_container_invalid' : ''">
          <input class="inputfield" type="{{showPassword ? 'text' : 'password'}}" placeholder="Passwort"
            formControlName="password"
            (change)="submitted = false; accessDenied = false;">
          <img class="icon_password" (click)="showPassword = !showPassword" [src]="!showPassword ? '../../../assets/inputhide.svg' : '../../../assets/inputshow.svg'">
          <div class="input_valid_indicator" [ngClass]="form.controls.password.invalid ? 'input_valid_indicator_invalid' : ''"></div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <label [ngStyle]="{'opacity': accessDenied ? '1' : '0'}" class="lbl_invalid" style="margin-bottom: 10px;">E-Mail-Adresse oder Passwort falsch.</label>
      <button class="btn_submit" [ngClass]="!checkValid() ? 'btn_submit_inactive' : ''" [disabled]="!checkValid()" (click)="onSubmit()">Login</button>
      <button class="btn_submit_secondary" (click)="resgisterAction()">Kein Konto? Jetzt registrieren</button>
      <button class="btn_label" (click)="forgotPasswordAction()">Passwort vergessen</button>
    </div>
  </form>
  <app-resgister-form *ngIf="showRegisterForm"
    (backEmitter)="showRegisterForm = false"
  ></app-resgister-form>
  <app-forgot-password-form *ngIf="showForgotPassword"
    [screenWidth]="screenWidth"
    (backEmitter)="showForgotPassword = false"
  ></app-forgot-password-form>
</div>
